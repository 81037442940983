import React from "react";
import socketIOClient from "socket.io-client";
import "./App.css";
import "../src/css/style.css";
import "../src/css/responsive.css";
import "antd/dist/antd.min.css";
import RoutesNew from "./Route/Routes";
import { useNavigate } from "react-router-dom";
import { dispatchFn, naviagetFn } from "./themes/UTILS/dispatchNavigate";
import { useDispatch } from "react-redux";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { updateOnlineGame, updateOnlineUser } from "./Store/Action/dashboardAction";
import appconstant from "./themes/appconstant";
export const socket = socketIOClient(appconstant.reduxConstant.SERVER_URL_SOCKET, { transports: ['websocket'] });

const App = () => {
  const dispatch = useDispatch();
  const naivigate = useNavigate();

  dispatchFn(dispatch);
  naviagetFn(naivigate);

  socket.on("currentOnlinePlayers", (data) => {
    dispatch(updateOnlineUser(data.data.currentOnlinePlayers, "onlineUsers"))
  });

  socket.on("totalOngoingGames", (data) => {
    dispatch(updateOnlineGame(data.count, "onlineGames"))
  });

  React.useEffect(() => {
    const a =localStorage.getItem("adminId")
    socket.on("connect", () => {
      if(a) socket.emit("adminLogin", { adminId: localStorage.getItem("adminId") })
    })
    window.addEventListener("online", () =>{
    if(a){
    socket.emit("adminLogin", { adminId: a })}})
  }, [])

  return (
    <div>
      <ToastContainer
        position="top-right"
        autoClose={2000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick={false}
        rtl={false}
        limit={5}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      <RoutesNew />
    </div>
  );
};

export default App;
