import React, { useEffect, useState } from "react";

//** Antd **/
import { Table } from "antd";

//** UI **/
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHome } from "@fortawesome/free-solid-svg-icons";

//** Store -Redux **/
import { useSelector } from "react-redux";
import {
  gameAction,
  ResetGamesAction,
} from "../../Store/Action/gameManagement";
import { LoaderAction } from "../../Store/Action/Auth";

//** common **/
import { BreadcrumbFn } from "../../common/BreadCrumbFn";
import { GameTabel } from "../../common/Tabels";

//** Themese **/
import {
  GameTabelButtonClick,
  GameTabelButtonName,
  instialData,
} from "../../themes/JsonFile";
import appconstant from "../../themes/appconstant";
import { dispatch } from "../../themes/UTILS/dispatchNavigate";
import {
  onPagenationHandelChange,
  SearchBarFn,
} from "../../themes/UTILS/commonFunc";
import { GameFilter } from "../../common/Filter";
import Button from "../../common/Button";

const GameManagement = () => {
  const [searchInput, setSearchInput] = useState("");
  const [FilterClick, SetFilterClick] = useState(false);
  const [paginationData, setpaginationData] = useState(instialData);
  const { data, paginateData, isLoading } = useSelector(
    (state) => state.GamesReducer
  );
  const [isClick, setIsClick] = useState(false);
  const [FilterValue, setFilterValue] = useState({});
  useEffect(() => {
    !isLoading &&
      window.scroll({
        top: 0,
        behavior: "smooth",
      });
  }, [isLoading]);
  useEffect(() => {
    const newPaginationData = { ...paginationData, ...FilterValue };
    dispatch(LoaderAction());
    SearchBarFn(
      newPaginationData,
      gameAction,
      paginationData,
      paginateData?.currentPage,
      searchInput
    );
    return () => {
      dispatch(ResetGamesAction());
    };
  }, [searchInput]);

  const handleChange = (pagination, filters, sorter) => {
    let newFilterValue = { ...FilterValue };
    newFilterValue = searchInput.length == 0 && { ...FilterValue, search: "" };
    const newPaginationData = { paginationData, ...newFilterValue, search: searchInput };
    onPagenationHandelChange(
      pagination,
      sorter,
      newPaginationData,
      setpaginationData,
      gameAction
    );
  };

  return (
    <div>
      <BreadcrumbFn
        path={["dashboard"]}
        pathName={[
          <FontAwesomeIcon icon={faHome} />,
          appconstant.gameManagement,
        ]}
      />
      <div
        className="site-layout-background"
        style={{ padding: 24, minHeight: 360 }}
      >
        <div className="content-e">
          <div className="bg-gradient-primary shadow-primary border-radius-lg pt-4 pb-3 system-line">
            <h6 className="text-white text-capitalize ps-3">
              {appconstant.gameManagement}
            </h6>
            <div style={{ display: "flex" }}>
              <Button onClick={() => SetFilterClick(true)} name={"Filter"} />
              <input
                type="text"
                className="iocn-search  search-bar"
                placeholder="Search"
                value={searchInput}
                onChange={(e) => {
                  setSearchInput(e.target.value.trimStart());
                }}
              />
            </div>
          </div>
          <Table
            rowKey="uid"
            dataSource={data}
            columns={GameTabel(
              paginateData?.currentPage,
              GameTabelButtonName,
              GameTabelButtonClick,
              isClick,
              setIsClick
            )}
            onChange={handleChange}
            showSorterTooltip={false}
            pagination={{
              current: paginateData?.currentPage ?? 1,
              total: paginateData?.totalCount,
            }}
            showLessItems
          />
        </div>
      </div>
      {setIsClick && (
        <GameFilter
          Visible={FilterClick}
          onOk={(e) => {
            const obj = {
              ...paginationData,
              search: searchInput,
              filter: { ...e },
            };
            setFilterValue(obj);
            obj.offset = 0;
            SetFilterClick(false);
            dispatch(gameAction(obj));
          }}
          onCancel={() => {
            let obj = {
              ...instialData,
              search: searchInput,
              filter: {},
            };
            setFilterValue(obj);
            SetFilterClick(false);
            dispatch(gameAction(obj));
          }}
        />
      )}
    </div>
  );
};
export default GameManagement;
