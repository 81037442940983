import appconstant from "../../themes/appconstant";
const { reduxConstant } = appconstant;

export const dashboardAction = (data, navigate) => {
  return { type: reduxConstant.DASHBOARD_LOAD };
};
export const RemoveLoader = () => {
  return { type: reduxConstant.REMOVE_LOADER };
};

export const updateOnlineUser = (data, key) => ({
  type: reduxConstant.UPDATE_ONLINE_USER,
  payload: data,
  key
})

export const updateOnlineGame = (data, key) => ({
  type: reduxConstant.UPDATE_ONLINE_GAME,
  payload: data,
  key
})