import appconstant from "../../themes/appconstant";
import axios from "axios";

let APIKit = axios.create({
  baseURL: appconstant.reduxConstant.SERVER_URL,
  timeout: 600000,
});

APIKit.interceptors.request.use(async (config) => {
  const token = localStorage.token;
  if (token) {
    config.headers.Authorization = `${token}`;
  }
  return config;
});
export default APIKit;
