import appconstant from "../../themes/appconstant";
const { reduxConstant } = appconstant;
const instialState = {
  isLoading: false,
  data: [],
  paginateData: {},
};
export const AccountDeleteReducer = (state = instialState, action) => {
  switch (action.type) {
    //Tabels data

    case reduxConstant.ACCOUNT_LIST_LOAD:
      return {
        ...state,
        isLoading: true,
      };
    case reduxConstant.ACCOUNT_LIST_SUCCESS:
      return {
        ...state,
        isLoading: false,
        data: action.payload.data,
        paginateData: action.payload.pagination,
      };
    case reduxConstant.ACCOUNT_LIST_FAIL:
      return {
        ...state,
        isLoading: false,
        data: [],
      };

    case reduxConstant.ACCOUNT_DELETE_ACCEPT_LOAD:
      return {
        ...state,
        isLoading: true,
      };
    case reduxConstant.ACCOUNT_DELETE_ACCEPT_SUCCESS:
      return {
        ...state,
        isLoading: false,
        // gameDetails: action.payload.data,
      };
    case reduxConstant.ACCOUNT_DELETE_ACCEPT_FAIL:
      return {
        ...state,
        isLoading: false,
      };
    default:
      return state;
  }
};
