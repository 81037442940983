import appconstant from "../../themes/appconstant";
const { reduxConstant } = appconstant;
const instialState = {
  isLoading: false,
  data: [],
  gameDetails: {},
  paginateData: {},
};
export const GamesReducer = (state = instialState, action) => {
  switch (action.type) {
    //Tabels data
    case reduxConstant.GAME_LOAD:
      return {
        ...state,
        isLoading: true,
      };
    case reduxConstant.GAME_SUCCESS:
      return {
        ...state,
        isLoading: false,
        data: action.payload.data,
        paginateData: action.payload.pagination,
      };
    case reduxConstant.GAME_FAIL:
      return {
        ...state,
        isLoading: false,
        data: [],
      };

    case reduxConstant.GAME_VIEW_LOAD:
      return {
        ...state,
        isLoading: true,
      };
    case reduxConstant.GAME_VIEW_SUCCESS:
      return {
        ...state,
        isLoading: false,
        gameDetails: action.payload.data,
      };
    case reduxConstant.GAME_VIEW_FAIL:
      return {
        ...state,
        isLoading: false,
      };
    case reduxConstant.RESET_GAMES:
      return { ...state, paginateData: {} };
    default:
      return state;
  }
};
