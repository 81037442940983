import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHome } from "@fortawesome/free-solid-svg-icons";
import appconstant from "../../themes/appconstant";
import Textfiled from "../../common/Textfiled";
import { BreadcrumbFn } from "../../common/BreadCrumbFn";
import {
  userEditAction,
  userViewDetailAction,
} from "../../Store/Action/userManagement";
import { useSelector } from "react-redux";
import { UserData } from "../../themes/JsonFile";
import { Formik } from "formik";
import { dispatch } from "../../themes/UTILS/dispatchNavigate";
import {
  blockInvalidCharStringNumber,
  CheckValue,
  ToUpperCase,
  upperCaseFirstChar,
  UserManagementValidationSchema,
} from "../../themes/UTILS/commonFunc";
import { LoaderAction } from "../../Store/Action/Auth";
import images from "../../themes/appImage";
import Button from "../../common/Button";
import moment from "moment/moment";
import { Select } from "antd";
const EditUserDetails = () => {
  const { state } = useLocation();
  const { userDetails } = useSelector((state) => state.UsersReducer);
  const [newData, SetInputsData] = useState(UserData);
  useEffect(() => {
    dispatch(LoaderAction());
    dispatch(userViewDetailAction(state));
  }, []);

  useEffect(() => {
    let name = userDetails?.name?.split(" ");
    let newData = {};
    if (name?.length > 0) {
      newData = {
        ...userDetails, fullname: name[0], lastname: name[1],
        phone: (userDetails.countryCode && userDetails.phone) ? userDetails.countryCode + " " + userDetails.phone : userDetails.phone ?? "",
        postalCode: userDetails.postalCode ?? ""
      };
      delete userDetails?.name;
    }
    SetInputsData(newData);
  }, [userDetails]);

  const onFormSubmit = (e) => {
    const newObj = e;
    newObj.userId = e?._id;
    newObj.name = e.fullname + " " + e.lastname;
    newObj.phone = (userDetails.countryCode && userDetails.phone) ? e.phone.split(" ")?.[1] : e.phone
    delete newObj._id;
    dispatch(userEditAction(newObj));
  };

  return (
    <div>
      <BreadcrumbFn
        path={["dashboard", "usermanagement"]}
        pathName={[
          <FontAwesomeIcon icon={faHome} />,
          appconstant.userManagement,
          appconstant.edituserDetails,
        ]}
      />
      <div
        className="site-layout-background"
        style={{ padding: 24, minHeight: 360 }}
      >
        <div className="content-e">
          <div className="bg-gradient-primary shadow-primary border-radius-lg pt-4 pb-3">
            <h6 className="text-white text-capitalize ps-3">
              {appconstant.edituserDetails}
            </h6>
          </div>
          <div className="wrapper_line view_modules view ">
            <Formik
              initialValues={newData}
              enableReinitialize
              onSubmit={onFormSubmit}
              validationSchema={UserManagementValidationSchema}
            >
              {({
                handleBlur,
                handleSubmit,
                errors,
                values,
                touched,
                setFieldValue,
              }) => (
                <form onSubmit={handleSubmit}>
                  <Textfiled
                    label={appconstant.userID}
                    name="UUID"
                    disabled
                    value={values?.UUID}
                  />
                  <Textfiled
                    label={appconstant.username}
                    name={"userName"}
                    disabled
                    value={values?.userName}
                  />
                  <Textfiled
                    label={appconstant.FirstName}
                    name={"fullname"}
                    value={values.fullname}
                    onChange={(e) => {
                      let data = e.target.value.trim();
                      setFieldValue("fullname", ToUpperCase(data));
                    }}
                    onBlur={handleBlur}
                    error={Boolean(errors.fullname && touched.fullname)}
                    helperText={touched.fullname && errors.fullname}
                    maxLength={30}
                  />

                  <Textfiled
                    label={appconstant.LastName}
                    name={"lastname"}
                    value={values.lastname}
                    onChange={(e) => {
                      let data = e.target.value.trim();
                      setFieldValue("lastname", ToUpperCase(data));
                    }}
                    onBlur={handleBlur}
                    error={Boolean(errors.lastname && touched.lastname)}
                    helperText={touched.lastname && errors.lastname}
                    maxLength={30}
                  />

                  <div style={{ position: "relative" }}>
                    <Textfiled
                      className={"padding"}
                      name={"coins"}
                      label={appconstant.coinCount}
                      disabled
                      value={values.coins}
                    />
                    <img className="coin" alt="" src={images.gameCoin} />
                  </div>
                  <Textfiled
                    name={"email"}
                    label={appconstant.emailAddress}
                    value={values.email}
                    onChange={(e) => {
                      let data = e.target.value.trimRight();
                      setFieldValue("email", data);
                    }}
                    onBlur={handleBlur}
                    error={Boolean(errors.email && touched.email)}
                    helperText={touched.email && errors.email}
                    maxLength={60}
                  />
                  <Textfiled
                    name={"phone"}
                    label={appconstant.phoneNumber}
                    value={values.phone}
                    onKeyDown={blockInvalidCharStringNumber}
                    onChange={(e) => {
                      let data = e.target.value;
                      if (data?.includes(newData.countryCode + " ") || !(values.countryCode && values.phone)) {
                        setFieldValue("phone", data);
                      }
                    }}
                    onBlur={handleBlur}
                    error={Boolean(errors.phone && touched.phone)}
                    helperText={touched.phone && errors.phone}
                    maxLength={13}
                  />
                  <Textfiled
                    name={"totalPlayedGames"}
                    label={appconstant.totalGamesPlayed}
                    disabled
                    value={values.totalPlayedGames}
                  />
                  <Textfiled
                    name={"gamesLose"}
                    label={appconstant.gamesLose}
                    disabled
                    value={values.gamesLose}
                  />
                  <Textfiled
                    name={"gamesWin"}
                    label={appconstant.gameswin}
                    disabled
                    value={values.gamesWin}
                  />
                  <Textfiled
                    name={"gamesWin"}
                    label={appconstant.gamesDraw}
                    disabled
                    value={values.gamesDraw}
                  />
                  <div className="form-group">
                    <label>{appconstant.country}</label>
                    <Select
                      placeholder={"Please select country."}
                      onChange={(e) => setFieldValue("country", e)}
                      value={values.country}
                    >
                      <Select.Option value={"United States"}>United States</Select.Option>
                      <Select.Option value={"Canada"}>Canada</Select.Option>
                    </Select>
                  </div>
                  <Textfiled
                    label={appconstant.state}
                    value={values.state}
                    onBlur={handleBlur}
                    name={"state"}
                    onChange={(e) => {
                      let data = e.target.value.trimStart();
                      setFieldValue("state", upperCaseFirstChar(data));
                    }}
                    maxLength={16}
                  />
                  <Textfiled
                    label={appconstant.streetAddress}
                    value={values.address}
                    onBlur={handleBlur}
                    name={"address"}
                    onChange={(e) => {
                      let data = e.target.value.trimStart();
                      setFieldValue("address", upperCaseFirstChar(data));
                    }}
                  />
                  <Textfiled
                    label={appconstant.city}
                    name="city"
                    onChange={(e) => {
                      let data = e.target.value.trimStart();
                      setFieldValue("city", upperCaseFirstChar(data));
                    }}
                    value={values.city}
                    onBlur={handleBlur}
                    maxLength={16}
                  />
                  <Textfiled
                    label={appconstant.postalCode}
                    name="postalCode"
                    onChange={(e) => {
                      let data = e.target.value.trim();
                      setFieldValue("postalCode", data.toUpperCase());
                    }}
                    onBlur={handleBlur}
                    error={Boolean(errors.postalCode && touched.postalCode)}
                    helperText={touched.postalCode && errors.postalCode}
                    value={values.postalCode}
                    maxLength={6}
                  />
                  <Textfiled
                    disabled
                    label={appconstant.joinedDateTime}
                    name="postalCode"
                    value={moment(values.createdAt).format(appconstant.DateTimeFormat)}
                    onBlur={handleBlur}
                  />
                  <Textfiled
                    disabled
                    label={appconstant.lastPlayedDateTime}
                    name="postalCode"
                    value={userDetails.lastPlayedGameTime ? moment(values.lastPlayedGameTime).format(appconstant.DateTimeFormat) : "N/A"}
                    onBlur={handleBlur}
                  />
                  <Textfiled
                    disabled
                    label={appconstant.lastLoginDateAndTime}
                    name="postalCode"
                    value={userDetails.lastLogin ? moment(values.lastLogin).format(appconstant.DateTimeFormat) : "N/A"}
                    onBlur={handleBlur}
                  />
                  {/* <Textfiled
                    name={"isFaceId"}
                    label={appconstant.faceID}
                    value={CheckValue(values?.isFaceId)}
                    disabled
                  /> */}
                  <Textfiled
                    name={"isBioMetricEnable"}
                    label={appconstant.fingerprint}
                    value={CheckValue(values.isBioMetricEnable)}
                    disabled
                  />

                  <div style={{ position: "relative" }}>
                    <Textfiled
                      className={"padding"}
                      name={"wallet"}
                      label={appconstant.totalRealAmount}
                      disabled
                      value={values.wallet&&values.wallet?.toFixed(2)}
                    />
                    <img className="dollerCoin" alt="" src={images.realmoney} />
                  </div>
                  <div className="fields">
                    <label>{appconstant.userAvgRating}</label>
                    <div className="rating">
                      {JSON.stringify(values) !== "{}" ? (
                        Object.entries(values?.rating)?.map(([key, value]) => {
                          return (
                            <div key={key} className="ratingDivs">
                              <label>{ToUpperCase(key)}</label>
                              <p className={"text"}>{CheckValue(value)}</p>
                            </div>
                          );
                        })
                      ) : (
                        <p>No rating found.</p>
                      )}
                    </div>
                  </div>
                  <div className="up-btn">
                    <Button name={appconstant.buttonupate} />
                  </div>
                </form>
              )}
            </Formik>
          </div>
        </div>
      </div>
    </div >
  );
};
export default EditUserDetails;
