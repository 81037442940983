const appconstant = {
  resetBtn: "Reset",
  accept: "Accept",
  reject: "Reject",
  disable: "Disable",
  cancelledTournament: "Cancelled Tournament",
  changepassword: "Change Password",
  Oldpassword: "Old Password",
  NewPassword: "New Password",
  ConfirmNewPassword: "Confirm New Password",
  view: "View",
  edit: "Edit",
  block: "Block",
  emailAddress: "Email Address",
  phoneNumber: "Phone Number",
  userManagement: "User Management",
  AccountDeleteManagement: "Account Delete Management",
  respass: "Reset Password",
  userdetails: "User Details",
  userName: "Abc@123",
  usemail: "Abc@123gmail.com",
  number: "+91 984115111",
  totalGamesPlayed: "Total Games Played",
  played: "5",
  gamesLose: "Games Lose",
  lose: "2",
  gameswin: "Games Win",
  gamesDraw: "Games Draw",
  win: "3",
  country: "Country",
  ind: "India",
  state: "State",
  ab: "Abc",
  streetAddress: "Street Address",
  xyz: "Xyz",
  city: "City",
  moh: "Mohali",
  postalCode: "Postal Code",
  joinedDateTime: "Joined Date / Time",
  lastPlayedDateTime: "Last Played Date / Time",
  lastLoginDateAndTime: "Last Login Date / Time",
  code: "176244",
  faceID: "Face ID",
  enabel: "Enable",
  userAvgRating: "User Avg. Rating",
  star: "5 star",
  fingerprint: "Biomatrics",
  totalRealAmount: "Total Real Amount",
  totalRealMoney: "Total Real Money",
  amount: "$200",
  coinCount: "Coin Count",
  count: "200",
  edituserDetails: "Edit User Details",
  username: "Username",
  UserName: "User Name",
  userID: "User ID",
  name: "Full Name",
  FirstName: "First Name",
  LastName: "Last Name",

  gameManagement: "Game Management",
  gametype: "Game Type",
  random: "Random",
  tournamenttype: "Tournament Type",
  knockout: "Knockout",
  firstPlayerName: "First Player Name",
  user1: "User1",
  secondPlayerName: "Second Player Name",
  user2: "User2",
  date: "Date",
  july: "10-july-2022",
  starttime: "Start Time",
  tim: "02:00 PM",
  endtime: "End Time",
  timeend: "02:20 PM",
  gamechoice: "Game Choice",
  mint: "15 minutes",
  amounttype: "Amount Type",
  realmoney: "Real money",
  buy: "Buy-In Amount",
  amo: "$100",
  totalmoves: "Total Moves",
  movi: "75 Moves",
  gamestatus: "Game Status",
  complete: "Complete",
  winnername: "Winner Name",
  winDrawCause: "Win / Draw Cause",
  winneramount: "Winner Amount",
  moves: "Moves",
  nf3: "1.Nf3 Nf6, 2.c4 c5, 3.g3 g6, 4.Bg2",
  gameDetails: "Game Details",
  tournamentMatchDetails: "Tournament Match Details",
  addtournament: "Add Tournament",
  tournamentname: "Tournament Name",
  tournamentId: "Tournament ID",
  GameId: "Game ID",
  abc123: "Abc",
  startdate: "Tournament Start Date",
  totalnum: "Total Number Of Players",
  eigh: "5",
  entryFee: "Entry Fee",
  fee: "$500",
  winner: "Winner",
  xy: "xyz@123",
  runnerUp: "Runner Up",
  amou: "$600",
  runnerUpAmount: "Runner Up Amount",
  runupam: "$300",
  editTournamentDetails: "Edit Tournament Details",
  tournamentManagement: "Tournament Management",
  tournamentDetails: "Tournament Details",
  depositsManagement: "Deposit Management",
  accountDelete: "Account Delete Management",
  deposits: "Deposit",
  withdraw: "Withdraw",
  withdrawRequest: "Withdraw Request",

  notifcationManagement: "Notification Management",
  addNotification: "Add Notification",
  restrictedStateManagement: "Restricted State Management",
  maintenanceManagement: "Maintenance Game Management",
  addRestrictedState: "Add State",
  editRestrictedState: "Edit State",
  stateName: "State Name",
  state: "State",
  tittle: "Title",
  description: "Description",
  editNotification: "Edit Notification",
  tournamentStartDate: "Tournament Start Date",
  tournamentStartTime: "Tournament Start Time",
  tournamentEndTime: "Tournament End Time",
  timeForEachMatch: "Time For Each Match",
  timeForMatch: "Time For Match",
  entryFees: "Entry Fee",
  poolPrice: "Pool Price",
  timeInterval: "Time Interval",
  nextTournamentGenerateDate: "Next Tournament Generate Date",
  nextTournamentGenerateTime: "Next Tournament Generate Time",
  totalTournaments: "Total Tournaments",
  withdrawManagement: "Withdraw Management",
  withdrawRequestManagement: "Withdraw Request Management",

  totalMoves: "Total Moves",
  move: "75 Moves",
  buttonupate: "Update",
  Battleground: "Battleground",
  TotalPlayers: "No. Of Players Registered",
  Text: "Please enter ",
  deletes: "Delete",
  add: "Add",
  addState: "Add State",
  update: "Update",
  Dashboard: "Dashboard",
  PaymentManagement: "Payment Management",
  Logout: "Logout",
  forgotpassword: "Forgot password?",
  STWW: "Something went wrong.",
  inCatch: "InCatch",
  LogoutText: "Are you sure you want to logout.",
  BlockText: "Are you sure, you want to block this user?",
  UnblockText: "Are you sure, you want to unblock this user?",
  TournamentBlockText: "Are you sure, you want to block this tournament?",
  TournamentUnblockText: "Are you sure, you want to unblock this tournament?",
  deleteText: "Are you sure, you want to delete this notification?",
  deleteRestrictedStateText: "Are you sure, you want to delete this state?",
  acceptWithdrawStateText: "Are you sure, you want to accept this request?",
  rejectWithdrawStateText: "Are you sure, you want to reject this request?",

  ResetPasswordText:
    "Are you sure, you want to reset the password for this user?",
  reset: "Reset Password",
  unBlock: "Unblock",
  Valid: "Please enter valid ",
  Alpha: "Please enter alphabetic or alphanumeric characters in ",
  Lenght: "The full name length should be 2 to 40 characters.",
  ChooseTournament: "Choose Tournament",
  Action: "Action",
  player: "Player",
  srNo: "Sr. No.",
  DateTimeFormat: "DD-MM-YYYY hh:mm A",
  DateFormat: "DD-MM-YYYY",
  TimeFormat: "hh:mm A",
  filter: "Filter",
  Remove: "Remove",
  Apply: "Apply",
  tournamentEndDate: "Tournament End Date",
  endDate: "End Date",
  startDate: "Start Date",
  nextTournamentGenerateDateTime: "Next Tournament Generate Date/Time",
  min: "min",

  //**  Routes Constants **/

  RoutesPath: {
    Dashboard: "/dashboard",
    userManagement: "/usermanagement",
    userDetails: "/userdetails",
    editUserDetails: "/edituserdetails",
    gameManagement: "/gamemanagement",
    gameDetail: "/gamedetails",
    depositsManagement: "/depositsmanagement",
    withdrawManagement: "/withdrawmanagement",
    notificationManagement: "/notificationmanagement",
    addNotification: "/addnotification",
    editNotification: "/editNotification",
    restrictedStateManagement: "/restrictedStateManagement",
    maintenanceManagement: "/maintenanceManagement",
    addRestrictedState: "/addRestrictedState",
    editRestrictedState: "/editRestrictedState",
    tournamentManagement: "/tournamentmanagement",
    editTournamentDetails: "/editTournamentDetails",
    tournamentDetails: "/tournamentDetails",
    addTournament: "/addtournament",
    accountDelete: "/accountDelete",
    Login: "/login",
    forgotpassword: "/forgotpassword",
    resetpassword: "/resetpassword",
    changePassword: "/changepassword",
    logout: "/logout",
  },

  //** REDUX CONSTANT **/

  reduxConstant: {
    //Base Url
    // SERVER_URL: "http://54.146.80.163:3000/api/v1", //local
    // SERVER_URL_SOCKET: "http://54.146.80.163:3000/", //local
    // SERVER_URL: "http://1.6.98.141:3017/api/v1", // del
    // SERVER_URL_SOCKET: "http://1.6.98.141:3017", //del
    // SERVER_URL: "http://192.168.3.174:5005/api/v1", // del
    // SERVER_URL_SOCKET: "http://192.168.3.174:5005", //del
    SERVER_URL: "https://api.chessarena.app/api/v1", // client
    SERVER_URL_SOCKET: "https://api.chessarena.app/", //client
    // SERVER_URL: "https://api.staging.chessarena.app/api/v1", // client
    // SERVER_URL_SOCKET: "https://api.staging.chessarena.app:3000/", //client


    //LOGIN//

    LOGIN_LOAD: "LOGIN_LOAD",
    LOGIN_SUCCESS: "LOGIN_SUCCESS",
    LOGIN_FAIL: "LOGIN_FAIL",

    //FORGOT PASSWORD

    FORGOTPASSWORD_LOAD: "FORGOTPASSWORD_LOAD",
    FORGOTPASSWORD_SUCCESS: "FORGOTPASSWORD_SUCCESS",
    FORGOTPASSWORD_FAIL: "FORGOTPASSWORD_FAIL",

    //RESET PASSWORD

    RESET_PASSWORD_LOAD: "RESET_PASSWORD_LOAD",
    RESET_PASSWORD_SUCCESS: "RESET_PASSWORD_SUCCESS",
    RESET_PASSWORD_FAIL: "RESET_PASSWORD_FAIL",

    //check reset password link

    CHECK_LINK_LOAD: "CHECK_LINK_LOAD",
    CHECK_LINK_SUCCESS: "CHECK_LINK_SUCCESS",
    CHECK_LINK_FAIL: "CHECK_LINK_FAIL",

    //check reset password link

    CHANGE_PASSWORD_LOAD: "CHANGE_PASSWORD_LOAD",
    CHANGE_PASSWORD_SUCCESS: "CHANGE_PASSWORD_SUCCESS",
    CHANGE_PASSWORD_FAIL: "CHANGE_PASSWORD_FAIL",

    //Logout

    LOGOUT_LOAD: "LOGOUT_LOAD",
    LOGOUT_SUCCESS: "LOGOUT_SUCCESS",
    LOGOUT_FAIL: "LOGOUT_FAIL",

    // DASHBOARD

    DASHBOARD_LOAD: "DASHBOARD_LOAD",
    DASHBOARD_SUCCESS: "DASHBOARD_SUCCESS",
    DASHBOARD_FAIL: "DASHBOARD_FAIL",
    UPDATE_ONLINE_USER: "UPDATE_ONLINE_USER",
    UPDATE_ONLINE_GAME: "UPDATE_ONLINE_GAME",

    // USERMANAGEMET

    USER_LOAD: "USER_LOAD",
    USER_FAIL: "USER_FAIL",
    USER_SUCCESS: "USER_SUCCESS",

    USER_BLOCK_LOAD: "USER_BLOCK_LOAD",
    USER_BLOCK_SUCCESS: "USER_BLOCK_SUCCESS",
    USER_BLOCK_FAIL: "USER_BLOCK_FAIL",

    USER_PASS_RESET_LOAD: "USER_PASS_RESET_LOAD",
    USER_PASS_RESET_SUCCESS: "USER_PASS_RESET_SUCCESS",
    USER_PASS_RESET_FAIL: "USER_PASS_RESET_FAIL",

    USER_EDIT_LOAD: "USER_EDIT_LOAD",
    USER_EDIT_SUCCESS: "USER_EDIT_SUCCESS",
    USER_EDIT_FAIL: "USER_EDIT_FAIL",

    USER_VIEW_LOAD: "USER_VIEW_LOAD",
    USER_VIEW_SUCCESS: "USER_VIEW_SUCCESS",
    USER_VIEW_FAIL: "USER_VIEW_FAIL",

    //** GAME MANAGEMENT **/

    GAME_LOAD: "GAME_LOAD",
    GAME_SUCCESS: "GAME_SUCCESS",
    GAME_FAIL: "GAME_FAIL",

    GAME_VIEW_LOAD: "GAME_VIEW_LOAD",
    GAME_VIEW_SUCCESS: "GAME_VIEW_SUCCESS",
    GAME_VIEW_FAIL: "GAME_VIEW_FAIL",

    //**  Delete Account **/
    ACCOUNT_LIST_LOAD: "ACCOUNT_LIST_LOAD",
    ACCOUNT_LIST_SUCCESS: "ACCOUNT_LIST_SUCCESS",
    ACCOUNT_LIST_FAIL: "ACCOUNT_LIST_FAIL",

    ACCOUNT_DELETE_ACCEPT_LOAD: "ACCOUNT_DELETE_ACCEPT_LOAD",
    ACCOUNT_DELETE_ACCEPT_SUCCESS: "ACCOUNT_DELETE_ACCEPT_SUCCESS",
    ACCOUNT_DELETE_ACCEPT_FAIL: "ACCOUNT_DELETE_ACCEPT_FAIL",

    //**  TOURNAMENT **/

    TOURNAMENT_LOAD: "TOURNAMENT_LOAD",
    TOURNAMENT_SUCCESS: "TOURNAMENT_SUCCESS",
    TOURNAMENT_FAIL: "TOURNAMENT_FAIL",

    TOURNAMENT_BLOCK_LOAD: "TOURNAMENT_BLOCK_LOAD",
    TOURNAMENT_BLOCK_SUCCESS: "TOURNAMENT_BLOCK_SUCCESS",
    TOURNAMENT_BLOCK_FAIL: "TOURNAMENT_BLOCK_FAIL",

    TOURNAMENT_ADD_LOAD: "TOURNAMENT_ADD_LOAD",
    TOURNAMENT_ADD_SUCCESS: "TOURNAMENT_ADD_SUCCESS",
    TOURNAMENT_ADD_FAIL: "TOURNAMENT_ADD_FAIL",

    TOURNAMENT_EDIT_LOAD: "TOURNAMENT_EDIT_LOAD",
    TOURNAMENT_EDIT_SUCCESS: "TOURNAMENT_EDIT_SUCCESS",
    TOURNAMENT_EDIT_FAIL: "TOURNAMENT_EDIT_FAIL",

    TOURNAMENT_VIEW_LOAD: "TOURNAMENT_VIEW_LOAD",
    TOURNAMENT_VIEW_SUCCESS: "TOURNAMENT_VIEW_SUCCESS",
    TOURNAMENT_VIEW_FAIL: "TOURNAMENT_VIEW_FAIL",

    TOURNAMENT_MATCH_LOAD: "TOURNAMENT_MATCH_LOAD",
    TOURNAMENT_MATCH_SUCCESS: "TOURNAMENT_MATCH_SUCCESS",
    TOURNAMENT_MATCH_FAIL: "TOURNAMENT_MATCH_FAIL",

    TOURNAMENT_MATCH_TYPE_LOAD: "TOURNAMENT_MATCH_TYPE_LOAD",
    TOURNAMENT_MATCH_TYPE_SUCCESS: "TOURNAMENT_MATCH_TYPE_SUCCESS",
    TOURNAMENT_MATCH_TYPE_FAIL: "TOURNAMENT_MATCH_TYPE_FAIL",

    TOURNAMENT_RESET: "TOURNAMENT_RESET",

    //

    PAYMENT_INFO_LOAD: "PAYMENT_INFO_LOAD",
    PAYMENT_INFO_SUCCESS: "PAYMENT_INFO_SUCCESS",
    PAYMENT_INFO_FAIL: "PAYMENT_INFO_FAIL",
    PAYMENT_RESET: "PAYMENT_RESET",

    // notification
    NOTIFICATION_FAIL: "NOTIFICATION_FAIL",
    RESET_NOTIFICATION: "RESET_NOTIFICATION",

    NOTIFICATION_LIST_LOAD: "NOTIFICATION_LIST_LOAD",
    NOTIFICATION_LIST_SUCCESS: "NOTIFICATION_LIST_SUCCESS",

    NOTIFICATION_DELETE_LOAD: "NOTIFICATION_DELETE_LOAD",
    NOTIFICATION_DELETE_SUCCESS: "NOTIFICATION_DELETE_SUCCESS",

    NOTIFICATION_VIEW_LOAD: "NOTIFICATION_VIEW_LOAD",
    NOTIFICATION_VIEW_SUCCESS: "NOTIFICATION_VIEW_SUCCESS",

    NOTIFICATION_ADD_LOAD: "NOTIFICATION_ADD_LOAD",
    NOTIFICATION_ADD_SUCCESS: "NOTIFICATION_ADD_SUCCESS",

    NOTIFICATION_EDIT_LOAD: "NOTIFICATION_EDIT_LOAD",
    NOTIFICATION_EDIT_SUCCESS: "NOTIFICATION_EDIT_SUCCESS",

    // Restricted State

    RESTRICTED_STATE_FAIL: "RESTRICTED_STATE_FAIL",
    RESET_RESTRICTED_STATE: "RESET_RESTRICTED_STATE",

    RESTRICTED_STATE_LIST_LOAD: "RESTRICTED_STATE_LIST_LOAD",
    RESTRICTED_STATE_LIST_SUCCESS: "RESTRICTED_STATE_LIST_SUCCESS",

    RESTRICTED_STATE_DELETE_LOAD: "RESTRICTED_STATE_DELETE_LOAD",
    RESTRICTED_STATE_DELETE_SUCCESS: "RESTRICTED_STATE_DELETE_SUCCESS",

    RESTRICTED_STATE_VIEW_LOAD: "RESTRICTED_STATE_VIEW_LOAD",
    RESTRICTED_STATE_VIEW_SUCCESS: "RESTRICTED_STATE_VIEW_SUCCESS",

    RESTRICTED_STATE_ADD_LOAD: "RESTRICTED_STATE_ADD_LOAD",
    RESTRICTED_STATE_ADD_SUCCESS: "RESTRICTED_STATE_ADD_SUCCESS",

    RESTRICTED_STATE_EDIT_LOAD: "RESTRICTED_STATE_EDIT_LOAD",
    RESTRICTED_STATE_EDIT_SUCCESS: "RESTRICTED_STATE_EDIT_SUCCESS",


    // Maintenance state
    MAINTENANCE_STATE_LIST_LOAD: "MAINTENANCE_STATE_LIST_LOAD",
    MAINTENANCE_STATE_LIST_SUCCESS: "MAINTENANCE_STATE_LIST_SUCCESS",
    MAINTENANCE_STATE_TOGGLE_LOAD: "MAINTENANCE_STATE_TOGGLE_LOAD",
    MAINTENANCE_STATE_TOGGLE_SUCCESS: "MAINTENANCE_STATE_TOGGLE_SUCCESS",
    MAINTENANCE_STATE_FAIL: "MAINTENANCE_STATE_FAIL",

    Payment_Request_Load: "Payment_Request_Load",
    Payment_Request_Success: "Payment_Request_Success",
    Payment_Request_Fail: "Payment_Request_Fail",

    //ERROR
    ERROR: "ERROR",
    FAIL: "FAIL",
    RESET: "RESET",
    RESET_GAMES: "RESET_GAMES",
    RESET_STORE: "RESET_STORE",
    ON_LOAD: "ON_LOAD",
    ON_SUCCESS: "ON_SUCCESS",
    ON_FAIL: "ON_FAIL",
    REMOVE_LOADER: "REMOVE_LOADER",
  },
};
export default appconstant;
