import React, { useState } from "react";
//**  REACT ROUTER **/
import { Link } from "react-router-dom";
//**formik  */
import * as Yup from "yup";
import { useFormik } from "formik";
//**  UI **/
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";

//COMMON COMPONENT

import { dispatch } from "../../themes/UTILS/dispatchNavigate";

//** THEMES **/

import images from "../../themes/appImage";
import appconstant from "../../themes/appconstant";
import { LoginAction } from "../../Store/Action/Auth";
import Button from "../../common/Button";

const validationSchema = Yup.object().shape({
  email: Yup.string()
    .required("Please enter email address.")
    .matches(
      /^[a-zA-Z0-9]+(\.[a-zA-Z0-9]+)*@[a-zA-Z0-9]+(\.[a-zA-z]{2,10})+$/,
      "Please enter valid email address."
    )
    .max(255),
  password: Yup.string()
    .required("Please enter password.")
    .matches(/^(\S+$)/, "Please enter valid password."),
});

//** MAIN FUNCTION **/
const Login = () => {
  const [visible, setVisible] = useState(false);
  const initialValues = {
    email: "",
    password: "",
  };

  const onSubmit = (values, { setSubmitting }) => {
    const data = {
      email: values.email,
      password: values.password,
    };
    setSubmitting(false);
    dispatch(LoginAction(data));
  };

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit,
  });

  return (
    <div className="image-bg">
      <div className="page-header">
        <div className="main-div">
          <div className="login-form">
            <div>
              <img src={images.login1} alt="" />
            </div>
            <h3>Admin Login </h3>
            <form noValidate onSubmit={formik.handleSubmit}>
              <div className="user">
                <input
                  name="email"
                  type="text"
                  placeholder="Email Address"
                  onChange={(e) => {
                    let data = e.target.value.trim();
                    formik.setFieldValue("email", data);
                  }}
                  value={formik.values.email.trim()}
                  onBlur={formik.handleBlur}
                />
                {formik.errors.email && formik.touched.password ? (
                  <div className="error">{formik.errors.email}</div>
                ) : null}
                <div style={{ position: "relative" }}>
                  <input
                    name="password"
                    type={visible ? "text" : "Password"}
                    placeholder="Password"
                    onBlur={formik.handleBlur}
                    onChange={(e) => {
                      let data = e.target.value.trim();
                      formik.setFieldValue("password", data);
                    }}
                    value={formik.values.password.trim()}
                  />
                  {formik.errors.password && formik.touched.password ? (
                    <div className="error">{formik.errors.password}</div>
                  ) : null}
                  <span
                    className={`${
                      formik.values?.password?.length > 0
                        ? "ShowPass"
                        : "HidePass"
                    }`}
                    onClick={() => setVisible(!visible)}
                  >
                    <FontAwesomeIcon
                      icon={!visible ? faEye : faEyeSlash}
                      color={"#000000"}
                    />
                  </span>
                </div>
                <div>
                  <Button className={"login-link"} name={"Login"} />
                </div>
              </div>
              <div className="forgot-link">
                <Link to={appconstant.RoutesPath.forgotpassword}>
                  {appconstant.forgotpassword}
                </Link>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Login;
