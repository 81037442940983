import React from "react";

//* REACT ROUTERS//** */
import { Routes, Route, Navigate } from "react-router-dom";

//** COMPONENTS **/
import Changepassword from "../components/changepassword";
import Dashboard from "../components/dashboard";
import ForgotPassword from "../components/forgotpassword";
import Login from "../components/login";
import MainLayout from "../layout/mainlayout";
import UserDetails from "../components/User Management/userDetails";
import GameManagement from "../components/Game Management";
import GameDetails from "../components/Game Management/gameDetails";
import DepositsManagement from "../components/Payment Manangement/depositsWithdrawManagement";
import NotificationManagement from "../components/Notification Management";
import AddNotification from "../components/Notification Management/addEditNotifcation";
import EditUserDetails from "../components/User Management/editUserDetails";
import TournamentManagement from "../components/Tournament Management/tournamentManagement";
import TournamentDetails from "../components/Tournament Management/tournamentDetails";
import AddTournament from "../components/Tournament Management/addTournament";
import UserManagement from "../components/User Management";
import AccountDelete from "../components/Account_Delete_Management";
import ResetPassword from "../components/ResetPassword";

//** themes */
import appconstant from "../themes/appconstant";
import { PublicRoute } from "./privateRoute";
import RestrictedStateManagement from "../components/RestrictedStatesManagement";
import AddState from "../components/RestrictedStatesManagement/addState";
import EditState from "../components/RestrictedStatesManagement/editState";
import MaintenanceManagement from "../components/Maintenance Management";

const RoutesNew = () => {
  const { RoutesPath } = appconstant;
  return (
    <div>
      <Routes>
        <Route path="/" element={<Navigate to={RoutesPath.Login} />} />
        <Route path="*" element={<Navigate to={RoutesPath.Login} />} />
        <Route
          path={RoutesPath.Login}
          element={
            <PublicRoute>
              <Login />
            </PublicRoute>
          }
        />
        <Route path={RoutesPath.forgotpassword} element={<ForgotPassword />} />
        <Route path={RoutesPath.resetpassword} element={<ResetPassword />} />

        <Route path="/" element={<MainLayout />}>
          <Route path={RoutesPath.Dashboard} element={<Dashboard />} />
          <Route
            path={RoutesPath.changePassword}
            element={<Changepassword />}
          />

          <Route
            path={RoutesPath.userManagement}
            element={<UserManagement />}
          />
          <Route path={RoutesPath.userDetails} element={<UserDetails />} />
          <Route
            path={RoutesPath.editUserDetails}
            element={<EditUserDetails />}
          />
          <Route
            path={RoutesPath.gameManagement}
            element={<GameManagement />}
          />
          <Route path={RoutesPath.gameDetail} element={<GameDetails />} />
          <Route
            path={RoutesPath.depositsManagement}
            element={<DepositsManagement />}
          />
          <Route
            path={RoutesPath.withdrawManagement}
            element={<DepositsManagement />}
          />
          <Route
            path={RoutesPath.notificationManagement}
            element={<NotificationManagement />}
          />
          <Route
            path={RoutesPath.addNotification}
            element={<AddNotification />}
          />
          <Route
            path={RoutesPath.editNotification}
            element={<AddNotification />}
          />
          <Route
            path={RoutesPath.restrictedStateManagement}
            element={<RestrictedStateManagement />}
          />
            <Route
            path={RoutesPath.maintenanceManagement}
            element={<MaintenanceManagement />}
          />
          <Route
            path={RoutesPath.addRestrictedState}
            element={<AddState />}
          />
          <Route
            path={RoutesPath.editRestrictedState}
            element={<EditState />}
          />
          <Route
            path={RoutesPath.tournamentManagement}
            element={<TournamentManagement />}
          />
          <Route
            path={RoutesPath.tournamentDetails}
            element={<TournamentDetails />}
          />
          <Route path={RoutesPath.addTournament} element={<AddTournament />} />
          <Route path={RoutesPath.accountDelete} element={<AccountDelete />} />
        </Route>
      </Routes>
    </div>
  );
};
export default RoutesNew;
