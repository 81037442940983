//saga redux
import { composeWithDevTools } from "redux-devtools-extension";
import createSagaMiddleware from "@redux-saga/core";
import { applyMiddleware, createStore } from "redux";

//local saga files
import rootSaga from "./Saga";

//reducer
import rootReducer from "./Reducer";

const sagaMiddleware = createSagaMiddleware();
export const Store = createStore(
  rootReducer,
  composeWithDevTools(applyMiddleware(sagaMiddleware))
);
sagaMiddleware.run(rootSaga);
