import moment from "moment";
import ModalPop from "../common/modal";
import { userAccountDeleteAcceptAction } from "../Store/Action/AccountDelete";
import { RemoveLoader } from "../Store/Action/dashboardAction";
import { NotificationDeleteAction } from "../Store/Action/NotificationAction";
import { TournamentBlockAction } from "../Store/Action/tournamentManagement";
import {
  userBlockAction,
  userPassResetAction,
} from "../Store/Action/userManagement";
import appconstant from "./appconstant";
import { dispatch, navigate } from "./UTILS/dispatchNavigate";
import { RestrictedStateDeleteAction } from "../Store/Action/RestrictedStateAction";
import { acceptWithDrawAction } from "../Store/Action/PaymentManagement";

export const instialData = {
  sortBy: "",
  order: "",
  search: "",
  offset: 0,
  limit: 10,
};

Object.seal(instialData);

const {
  accept,
  acceptWithdrawStateText,
  rejectWithdrawStateText,
  reject,
  view,
  edit,
  reset,
  block,
  ResetPasswordText,
  unBlock,
  BlockText,
  UnblockText,
  RoutesPath,
  TournamentBlockText,
  TournamentUnblockText,
  deletes,
  deleteText,
  deleteRestrictedStateText
} = appconstant;

export const UserTabelButtonName = [view, edit, block, reset];

export const UserTabelButtonClick = [
  //** View **/
  (id) => {
    navigate(RoutesPath.userDetails, { state: id });
  },

  //**Edit **/

  (id) => {
    navigate(RoutesPath.editUserDetails, { state: id });
  },

  //** Block **/

  (id, isClick, setIsClick, isBlock, paginationData) => {
    //** title ,contant ,onOk onCancel  isVisibal **/

    ModalPop(
      isBlock ? unBlock : block,
      isBlock ? UnblockText : BlockText,
      () => {
        dispatch(userBlockAction({ id, paginationData }));
        setIsClick(false);
      },
      () => {
        setIsClick(false);
        dispatch(RemoveLoader());
      },
      isClick
    );
  },

  //** Reset Password **/

  (id, isClick, setIsClick, isBlock) => {
    //** title ,contant ,onOk onCancel  isVisibal **/
    ModalPop(
      reset,
      ResetPasswordText,
      () => {
        dispatch(userPassResetAction(id));
        setIsClick(false);
      },
      () => {
        setIsClick(false);
        // dispatch(RemoveLoader());
      },
      isClick
    );
  },
];

//** user Edit Fields **/
export const UserData = {
  _id: "N/A",
  userName: "N/A",
  name: "N/A",
  email: "N/A",
  totalPlayedGames: "N/A",
  gamesLose: "N/A",
  gamesWin: "N/A",
  country: "N/A",
  state: "N/A",
  address: "N/A",
  city: "N/A",
  postalCode: "N/A",
  rating: "N/A",
  isFaceId: "N/A",
  isBioMetricEnable: "N/A",
  wallet: 0,
  coins: "N/A",
  phone: "N/A",
};

// GAME Management
export const GameTabelButtonName = [view];

export const GameTabelButtonClick = [
  //** View **/
  (id) => {
    navigate(RoutesPath.gameDetail, { state: id });
  },
];

// Tournament Management

export const TournamentTabelButtonName = [view, edit, block];

export const TouurnamentTabelButtonClick = [
  //** View **/
  (id) => {
    navigate(RoutesPath.tournamentDetails, {
      state: { id, type: appconstant.tournamentDetails },
    });
  },

  //**Edit **/

  (id) => {
    navigate(RoutesPath.tournamentDetails, {
      state: { id, type: appconstant.editTournamentDetails },
    });
  },

  //** Block **/

  (id, isClick, setIsClick, isBlock, paginationData) => {
    //** title ,contant ,onOk onCancel  isVisibal **/

    ModalPop(
      isBlock ? unBlock : block,
      isBlock ? TournamentUnblockText : TournamentBlockText,
      () => {
        dispatch(TournamentBlockAction({ id, paginationData }));
        setIsClick(false);
      },
      () => {
        setIsClick(false);
        dispatch(RemoveLoader());
      },
      isClick
    );
  },
];

// Tournament matches

export const TournamentViewTabelButtonName = [view];

export const TouurnamentViewTabelButtonClick = [
  //** View **/
  (id) => {
    window.globalThis.prevPath = "tournament";
    navigate(RoutesPath.gameDetail, { state: { id, prevPath: "asdf" } });
  },
];
export const TournamentData = {
  _id: "N/A",
  startDate: new Date(),
  startTime: new Date(),
  end: "N/A",
  name: "N/A",
  entryFee: "N/A",
  type: "N/A",
  runnerUp: "N/A",
  winner: "N/A",
  matches: "N/A",
  totalPlayers: "0",
  winnerAmount: "0",
  runnerUpAmount: "0",
  endDate: new Date(),
  endTime: new Date(),
  moneyType: "coins",
};
export const TournamentAddData = {
  name: "",
  type: "",
  startDate: new Date(),
  startTime: new Date(),
  totalPlayers: "",
  matchType: null,
  entryFee: "",
  endDate: moment(new Date()).add(1, "days"),
  endTime: new Date(),
  pricePool: "",
  timeInterval: "",
  totalTournament: "",
  moneyType: "coins",
  showPricepool: false,
};

//Notification Managemenrt

export const NotificationButtonName = [edit, deletes];

export const NotificationButtonClick = [
  //** Edit  **/
  (id) => {
    navigate(RoutesPath.editNotification, {
      state: {
        id: id,
        type: appconstant.editNotification,
      },
    });
  },
  //** Delete **/
  (id, isClick, setIsClick, isBlock, paginationData, totalCount) => {
    //** title ,contant ,onOk onCancel  isVisibal **/

    ModalPop(
      deletes,
      deleteText,
      () => {
        paginationData.offset =
          totalCount - 1 === 0
            ? 0
            : totalCount - 1 === paginationData.offset
            ? totalCount - 10
            : paginationData.offset;

        dispatch(NotificationDeleteAction({ id, paginationData }));
        setIsClick(false);
      },
      () => {
        setIsClick(false);
        dispatch(RemoveLoader());
      },
      isClick
    );
  },
];

// Restricted State management
export const RequestButtonName = [accept, reject];

export const RequestButtonClick = [
  //** Accept  **/
  (id, isClick, setIsClick, isBlock, paginationData, totalCount) => {
    
    //** title ,contant ,onOk onCancel  isVisibal **/

    ModalPop(
      accept,
      acceptWithdrawStateText,
      () => {
        paginationData.offset =
          totalCount - 1 === 0
            ? 0
            : totalCount - 1 === paginationData.offset
            ? totalCount - 10
            : paginationData.offset;

        dispatch(acceptWithDrawAction({ data: { transaction: id, status: "accepted" }, paginationData }));
        setIsClick(false);
      },
      () => {
        setIsClick(false);
        dispatch(RemoveLoader());
      },
      isClick
    );
  },
  //** Delete **/
  (id, isClick, setIsClick, isBlock, paginationData, totalCount) => {
    //** title ,contant ,onOk onCancel  isVisibal **/

    ModalPop(
      reject,
      rejectWithdrawStateText,
      () => {
        paginationData.offset =
          totalCount - 1 === 0
            ? 0
            : totalCount - 1 === paginationData.offset
            ? totalCount - 10
            : paginationData.offset;

            dispatch(acceptWithDrawAction({ data: { transaction: id, status: "rejected" }, paginationData }));;
        setIsClick(false);
      },
      () => {
        setIsClick(false);
        dispatch(RemoveLoader());
      },
      isClick
    );
  },
];

export const RestrictedStateButtonName = [edit, deletes];


export const RestrictedStateButtonClick = [
  //** Edit  **/
  (id,...rest) => {
    console.log({rest})
    navigate(RoutesPath.editRestrictedState, {
      state: {
        id: id,
        name: rest[6]?.name,
        type: appconstant.editRestrictedState,
      },
    });
  },
  //** Delete **/
  (id, isClick, setIsClick, isBlock, paginationData, totalCount) => {
    //** title ,contant ,onOk onCancel  isVisibal **/

    ModalPop(
      deletes,
      deleteRestrictedStateText,
      () => {
        paginationData.offset =
          totalCount - 1 === 0
            ? 0
            : totalCount - 1 === paginationData.offset
            ? totalCount - 10
            : paginationData.offset;

        dispatch(RestrictedStateDeleteAction({ id, paginationData }));
        setIsClick(false);
      },
      () => {
        setIsClick(false);
        dispatch(RemoveLoader());
      },
      isClick
    );
  },
];

export const Country = ["United States", "Canada"];

export const AccountDeleteTabelButtonName = ["Accept", "Reject"];

export const AccountDeleteTabelButtonClick = [
  //** Accepts **/
  (id, paginateData) => {
    dispatch(
      userAccountDeleteAcceptAction({
        data: { userId: id, status: "accepted" },
        paginateData,
      })
    );
  },
  //** Rejected **/
  (id, paginateData) => {
    dispatch(
      userAccountDeleteAcceptAction({
        data: { userId: id, status: "rejected" },
        paginateData,
      })
    );
  },
];

export const getPoolPrice = (poolPrice, amountType) => {
  if (amountType === "real") {
    if (poolPrice >= 25) {
      return (((poolPrice * 8) / 100) * 90).toFixed(2);
    } else {
      return (((poolPrice * 8) / 100) * 85).toFixed(2);
    }
  }
  return (poolPrice * 8).toFixed(2);
};

export const getAdminPrice = (poolPrice, amountType) => {
  if (amountType === "real") {
    if (poolPrice >= 25) {
      return (((poolPrice * 8) / 100) * 10).toFixed(2);
    } else {
      return (((poolPrice * 8) / 100) * 15).toFixed(2);
    }
  }
  return (poolPrice * 8).toFixed(2);
};

export const getMoves = (movesList) => {
  if (movesList?.length) {
    return movesList?.reduce(
      (acc, curr, index, arr) =>
        index % 2 == 0
          ? [
              ...acc,
              `${acc.length + 1}. ${arr[index]} ${arr[index + 1] ?? ""} `,
            ]
          : acc,
      []
    );
  } else {
    return "N/A";
  }
};
