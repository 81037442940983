import { call, put, takeLatest } from "@redux-saga/core/effects";

import {
  RestrictedStateDeleteApi,
  RestrictedStateAddApi,
  RestrictedStateApi,
  RestrictedStateEditApi,
} from "../Api";

import appconstant from "../../themes/appconstant";
import {
  onFail,
  onError,
  onUnAuth,
  onSucces,
  Loader,
} from "../../themes/UTILS/UnAuthFailFn";
import { instialData } from "../../themes/JsonFile";

const { reduxConstant } = appconstant;



//** Notification List **/
function* RestrictedStateListSaga(action) {
  try {
    console.log("TESt")
    const data = yield call(RestrictedStateApi, action.data);
    //** Success **/
    if (data.status === 0) {
      yield call(onSucces, {
        type: reduxConstant.RESTRICTED_STATE_LIST_SUCCESS,
        data,
        show: false,
      });

      yield call(Loader, appconstant.reduxConstant.ON_SUCCESS);
    }
    //** Un Authenticate **/
    else if (data.status === 2) {
      onUnAuth(data?.result?.message);
    }
    //** Fail **/
    else {
      yield call(onFail, {
        type: reduxConstant.RESTRICTED_STATE_FAIL,
        message: data?.result?.message,
        // show: false,
      });
      yield call(Loader, appconstant.reduxConstant.ON_FAIL);
    }
  } catch (e) {
    //** Un Catch error **/
    yield call(onError, reduxConstant?.RESTRICTED_STATE_FAIL);
    yield call(Loader, appconstant.reduxConstant.ON_FAIL);
  }
}
//Delete Notification
function* RestrictedStateDeleteSaga(action) {
  try {
    const data = yield call(RestrictedStateDeleteApi, action.data.id);
    //** Success **/
    if (data.status === 0) {
      yield put({
        type: reduxConstant.RESTRICTED_STATE_LIST_LOAD,
        data: action.data.paginationData,
      });
      yield call(onSucces, {
        type: reduxConstant.RESTRICTED_STATE_DELETE_SUCCESS,
        data,
        show: true,
      });
      yield call(Loader, appconstant.reduxConstant.ON_SUCCESS);
    }
    //** Un Authenticate **/
    else if (data.status === 2) {
      onUnAuth(data?.result?.message);
    }
    //** Fail **/
    else {
      const obj = {
        type: reduxConstant.RESTRICTED_STATE_FAIL,
        message: data?.result?.message,
      };
      yield call(onFail, obj);
      yield call(Loader, appconstant.reduxConstant.ON_FAIL);
    }
  } catch (e) {
    yield call(Loader, appconstant.reduxConstant.ON_FAIL);
    yield call(onError, reduxConstant?.RESTRICTED_STATE_FAIL);
  }
}

//Add Notification
function* RestrictedStateAddSaga(action) {
  try {
    const data = yield call(RestrictedStateAddApi, action.data);
    if (data.status === 0) {
      yield call(onSucces, {
        type: reduxConstant.RESTRICTED_STATE_ADD_SUCCESS,
        data,
        show: true,
        navigationLink: appconstant.RoutesPath.restrictedStateManagement,
      });

      yield put({
        type: reduxConstant.RESTRICTED_STATE_LIST_LOAD,
        data: instialData,
      });
      yield call(Loader, appconstant.reduxConstant.ON_SUCCESS);
    } else if (data.status === 2) {
      onUnAuth(data?.result?.message);
    } else {
      yield call(onFail, {
        type: reduxConstant.RESTRICTED_STATE_FAIL,
        message: data?.result?.message,
      });
      yield call(Loader, appconstant.reduxConstant.ON_FAIL);
    }
  } catch (e) {
    yield call(Loader, appconstant.reduxConstant.ON_FAIL);
    yield call(onError, reduxConstant?.RESTRICTED_STATE_FAIL);
  }
}
//Edit Notification
function* RestrictedStateEditSaga(action) {
  try {
    const data = yield call(RestrictedStateEditApi, action.data);
    if (data.status === 0) {
      yield call(onSucces, {
        type: reduxConstant.RESTRICTED_STATE_EDIT_SUCCESS,
        data,
        show: true,
        navigationLink: appconstant.RoutesPath.restrictedStateManagement,
      });

      yield put({
        type: reduxConstant.RESTRICTED_STATE_LIST_LOAD,
        data: instialData,
      });

      yield call(Loader, appconstant.reduxConstant.ON_FAIL);
    } else if (data.status === 2) {
      onUnAuth(data?.result?.message);
    } else {
      yield call(onFail, {
        type: reduxConstant.RESTRICTED_STATE_FAIL,
        message: data?.result?.message,
      });

      yield call(Loader, appconstant.reduxConstant.ON_FAIL);
    }
  } catch (e) {
    yield call(Loader, appconstant.reduxConstant.ON_FAIL);
    yield call(onError, reduxConstant?.RESTRICTED_STATE_FAIL);
  }
}

export default function* rootRestrictedStateManagementSaga() {
  yield takeLatest(reduxConstant.RESTRICTED_STATE_LIST_LOAD, RestrictedStateListSaga);
  yield takeLatest(
    reduxConstant.RESTRICTED_STATE_DELETE_LOAD,
    RestrictedStateDeleteSaga
  );
  yield takeLatest(reduxConstant.RESTRICTED_STATE_ADD_LOAD, RestrictedStateAddSaga);
  yield takeLatest(reduxConstant.RESTRICTED_STATE_EDIT_LOAD, RestrictedStateEditSaga);
}
