import appconstant from "../../themes/appconstant";
const { reduxConstant } = appconstant;
export const TournamentAction = (data) => ({
  type: reduxConstant.TOURNAMENT_LOAD,
  data,
});

//Block
export const TournamentBlockAction = (data) => ({
  type: reduxConstant.TOURNAMENT_BLOCK_LOAD,
  data,
});

// passReset
export const TournamentAddAction = (data) => ({
  type: reduxConstant.TOURNAMENT_ADD_LOAD,
  data,
});

//Edit user
export const TournamentEditAction = (data) => {
  return {
    type: reduxConstant.TOURNAMENT_EDIT_LOAD,
    data,
  };
};

//view user
export const TournamentViewDetailAction = (data) => ({
  type: reduxConstant.TOURNAMENT_VIEW_LOAD,
  data,
});
//Reset Data
export const TournamentResetAction = (data) => ({
  type: reduxConstant.TOURNAMENT_RESET,
  data,
});

//Tournament Matche Tabel List
export const TournamentMatcheAction = (data) => ({
  type: reduxConstant.TOURNAMENT_MATCH_LOAD,
  data,
});

// Tournament Matche Type dropdown
export const MatchTypeListAction = (data) => ({
  type: reduxConstant.TOURNAMENT_MATCH_TYPE_LOAD,
  data,
});
