import appconstant from "../../themes/appconstant";
const { reduxConstant } = appconstant;
export const NotificationAction = (data) => ({
  type: reduxConstant.NOTIFICATION_LIST_LOAD,
  data,
});
export const NotificationAddAction = (data) => ({
  type: reduxConstant.NOTIFICATION_ADD_LOAD,
  data,
});
export const NotificationDeleteAction = (data) => ({
  type: reduxConstant.NOTIFICATION_DELETE_LOAD,
  data,
});
export const NotificationViewAction = (data) => ({
  type: reduxConstant.NOTIFICATION_VIEW_LOAD,
  data,
});
export const NotificationEditAction = (data) => ({
  type: reduxConstant.NOTIFICATION_EDIT_LOAD,
  data,
});
export const ResetNotificationAction = () => ({
  type: reduxConstant.RESET_NOTIFICATION,
});
