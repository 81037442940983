import { put } from "@redux-saga/core/effects";
import { toast } from "react-toastify";
import appconstant from "../appconstant";
import { navigate } from "./dispatchNavigate";

const { RoutesPath } = appconstant;

export function* onError(type) {
  yield put({ type });
  toast.error(appconstant.STWW);
}

export function* onFail({ type, message, show = true }) {
  yield put({ type });
  show && toast.error(message);
}

export function* Loader(type) {
  yield put({ type });
}

export function* onSucces({ type, data, show = true, navigationLink }) {
  navigationLink && navigate(navigationLink);
  yield put({ type, payload: data.result });
  show && toast.success(data.result.message);
}

export function onUnAuth(message) {
  navigate(RoutesPath.Login);
  localStorage.clear();
  toast.error(message);
}
