import { put, call, takeLatest } from "@redux-saga/core/effects";

import {
  UsersApi,
  UserBlockApi,
  UserPassResetApi,
  UserDetailApi,
  EditUserApi,
} from "../Api";

import appconstant from "../../themes/appconstant";
import {
  onFail,
  onError,
  onUnAuth,
  onSucces,
  Loader,
} from "../../themes/UTILS/UnAuthFailFn";
import { navigate } from "../../themes/UTILS/dispatchNavigate";

const { reduxConstant } = appconstant;
const { ON_SUCCESS, ON_FAIL } = reduxConstant;
//** User detail **/
function* UsersSaga(action) {
  try {
    const data = yield call(UsersApi, action.data);
    //** Success **/
    if (data.status === 0) {
      const obj = {
        type: reduxConstant.USER_SUCCESS,
        data,
        show: false,
      };
      yield call(onSucces, obj);
      yield call(Loader, ON_SUCCESS);
    }
    //** Un Authenticate **/
    else if (data.status === 2) {
      onUnAuth(data?.result?.message);
    }
    //** Fail **/
    else {
      let obj = {
        type: reduxConstant.USER_FAIL,
        message: data?.result?.message,
      };
      yield call(onFail, obj);
      yield call(Loader, ON_FAIL);
    }
  } catch (e) {
    //** Un Catch error **/
    yield call(onError, reduxConstant?.USER_FAIL);
    yield call(Loader, ON_FAIL);
  }
}

//** Block user **/
function* UserBlockSaga(action) {
  try {
    const data = yield call(UserBlockApi, action.data.id);
    if (data.status === 0) {
      const obj = {
        type: reduxConstant.USER_BLOCK_SUCCESS,
        data,
        show: true,
      };
      yield call(onSucces, obj);
      yield put({
        type: reduxConstant.USER_LOAD,
        data: action.data.paginationData,
      });
      yield call(Loader, ON_SUCCESS);
    } else if (data.status === 2) {
      onUnAuth(data?.result?.message);
    } else {
      let obj = {
        type: reduxConstant.USER_BLOCK_FAIL,
        message: data?.result?.message,
      };
      yield call(onFail, obj);
      yield call(Loader, ON_FAIL);
      yield put({
        type: reduxConstant.USER_LOAD,
        data: action.data.paginationData,
      });
    }
  } catch (e) {
    yield call(onError, reduxConstant.USER_BLOCK_FAIL);
    yield call(Loader, ON_FAIL);
  }
}

//** RESET PASSWORD **/
function* ResetUserPasswordSaga(action) {
  try {
    const data = yield call(UserPassResetApi, action.data);
    if (data.status === 0) {
      const obj = {
        type: reduxConstant.USER_PASS_RESET_SUCCESS,
        data,
        show: true,
      };
      yield call(onSucces, obj);
      yield call(Loader, ON_SUCCESS);
    } else if (data.status === 2) {
      onUnAuth(data?.result?.message);
    } else {
      let obj = {
        type: reduxConstant.USER_PASS_RESET_FAIL,
        message: data?.result?.message,
        show: true,
      };
      yield call(onFail, obj);
      yield call(Loader, ON_FAIL);
      yield put({
        type: reduxConstant.USER_LOAD,
        data: action.data.paginationData,
      });
    }
  } catch (e) {
    yield call(onError, reduxConstant.USER_PASS_RESET_FAIL);
    yield call(Loader, ON_FAIL);
  }
}

//** view User Details **/
function* userDetailsSaga(action) {
  try {
    const data = yield call(UserDetailApi, action.data);
    if (data.status === 0) {
      const obj = {
        type: reduxConstant.USER_VIEW_SUCCESS,
        data,
        show: false,
      };
      yield call(onSucces, obj);
      yield call(Loader, ON_SUCCESS);
    } else if (data.status === 2) {
      onUnAuth(data?.result?.message);
    } else {
      let obj = {
        type: reduxConstant.USER_VIEW_FAIL,
        message: data?.result?.message,
      };
      yield call(onFail, obj);
      yield call(Loader, ON_FAIL);
    }
  } catch (e) {
    yield call(onError, reduxConstant.USER_VIEW_FAIL);
    yield call(Loader, ON_FAIL);
  }
}

//** Edit User Details **/
function* EdituserSaga(action) {
  try {
    const data = yield call(EditUserApi, action.data);
    if (data.status === 0) {
      navigate("/usermanagement");
      const obj = {
        type: reduxConstant.USER_EDIT_SUCCESS,
        data,
        show: true,
      };
      yield call(onSucces, obj);
      yield call(Loader, ON_SUCCESS);
    } else if (data.status === 2) {
      onUnAuth(data?.result?.message);
    } else {
      let obj = {
        type: reduxConstant.USER_EDIT_FAIL,
        message: data?.result?.message,
      };
      yield call(onFail, obj);
      yield call(Loader, ON_FAIL);
    }
  } catch (e) {
    yield call(onError, reduxConstant.USER_EDIT_FAIL);
    yield call(Loader, ON_FAIL);
  }
}

export default function* rootUserManagementSaga() {
  yield takeLatest(reduxConstant.USER_LOAD, UsersSaga);
  yield takeLatest(reduxConstant.USER_BLOCK_LOAD, UserBlockSaga);
  yield takeLatest(reduxConstant.USER_PASS_RESET_LOAD, ResetUserPasswordSaga);
  yield takeLatest(reduxConstant.USER_VIEW_LOAD, userDetailsSaga);
  yield takeLatest(reduxConstant.USER_EDIT_LOAD, EdituserSaga);
}
