import React, { useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHome } from "@fortawesome/free-solid-svg-icons";
import appconstant from "../../themes/appconstant";
import { BreadcrumbFn } from "../../common/BreadCrumbFn";
import DisableInputField from "../../common/DisableInputField";
import { useLocation } from "react-router-dom";
import { userViewDetailAction } from "../../Store/Action/userManagement";
import { useSelector } from "react-redux";
import { LoaderAction } from "../../Store/Action/Auth";
import { dispatch, navigate } from "../../themes/UTILS/dispatchNavigate";
import images from "../../themes/appImage";
import { CheckValue, ToUpperCase } from "../../themes/UTILS/commonFunc";
import Button from "../../common/Button";
import moment from "moment/moment";

const UserDetails = () => {
  const { state } = useLocation();
  const { RoutesPath } = appconstant;

  useEffect(() => {
    dispatch(LoaderAction());
    dispatch(userViewDetailAction(state));
  }, []);
  const { userDetails } = useSelector((state) => state.UsersReducer);
  return (
    <div>
      <BreadcrumbFn
        path={["dashboard", "usermanagement"]}
        pathName={[
          <FontAwesomeIcon icon={faHome} />,
          appconstant.userManagement,
          appconstant.userdetails,
        ]}
      />
      <div
        className="site-layout-background"
        style={{ padding: 24, minHeight: 360 }}
      >
        <div className="content-e">
          <div className="bg-gradient-primary shadow-primary border-radius-lg pt-4 pb-3">
            <h6 className="text-white text-capitalize ps-3">
              {appconstant.userdetails}
            </h6>
          </div>
          <div className="wrapper_line view_modules view">
            <DisableInputField
              label={appconstant.userID}
              value={userDetails.UUID}
            />
            <DisableInputField
              label={appconstant.username}
              value={userDetails.userName}
            />
            <DisableInputField
              label={appconstant.FirstName}
              value={userDetails?.name?.split(" ")[0]}
            />
            <DisableInputField
              label={appconstant.LastName}
              value={userDetails?.name?.split(" ")[1]}
            />
            <DisableInputField
              label={appconstant.emailAddress}
              value={userDetails.email}
            />
            <DisableInputField
              label={appconstant.phoneNumber}
              value={
                userDetails.countryCode && userDetails.phone
                  ? userDetails.countryCode + " " + userDetails.phone
                  : userDetails.phone ?? "N/A"
              }
            />
            <DisableInputField
              label={appconstant.totalGamesPlayed}
              value={userDetails.totalPlayedGames}
            />
            <DisableInputField
              label={appconstant.gamesLose}
              value={userDetails.gamesLose}
            />
            <DisableInputField
              label={appconstant.gameswin}
              value={userDetails.gamesWin}
            />
            <DisableInputField
              label={appconstant.gamesDraw}
              value={userDetails.gamesDraw}
            />
            <DisableInputField
              label={appconstant.country}
              value={userDetails.country}
            />
            <DisableInputField
              label={appconstant.state}
              value={userDetails.state}
            />
            <DisableInputField
              label={appconstant.streetAddress}
              value={userDetails.address}
            />
            <DisableInputField
              label={appconstant.city}
              value={userDetails.city}
            />
            <DisableInputField
              label={appconstant.postalCode}
              value={userDetails.postalCode}
            />
            <DisableInputField
              label={appconstant.joinedDateTime}
              value={moment(userDetails.createdAt).format(
                appconstant.DateTimeFormat
              )}
            />
            <DisableInputField
              label={appconstant.lastPlayedDateTime}
              value={
                userDetails.lastPlayedGameTime
                  ? moment(userDetails.lastPlayedGameTime).format(
                      appconstant.DateTimeFormat
                    )
                  : "N/A"
              }
            />
            <DisableInputField
              label={appconstant.lastLoginDateAndTime}
              value={
                userDetails.lastLogin
                  ? moment(userDetails.lastLogin).format(
                      appconstant.DateTimeFormat
                    )
                  : "N/A"
              }
            />
            <div className="fields">
              <label>{appconstant.userAvgRating}</label>
              <div className="rating">
                {JSON.stringify(userDetails) !== "{}" ? (
                  Object.entries(userDetails?.rating)?.map(([key, value]) => {
                    return (
                      <div className="ratingDiv" key={key}>
                        <label>{ToUpperCase(key)}</label>
                        <p className={"text"}>{CheckValue(value)}</p>
                      </div>
                    );
                  })
                ) : (
                  <p>No rating found.</p>
                )}
              </div>
            </div>

            {/* <DisableInputField
              label={appconstant.faceID}
              value={userDetails?.isFaceId}
            /> */}
            <DisableInputField
              label={appconstant.fingerprint}
              value={userDetails.isBioMetricEnable}
            />
            <div style={{ position: "relative" }}>
              <DisableInputField
                classname={"newpadding"}
                label={appconstant.totalRealAmount}
                value={userDetails.wallet?.toFixed(2)}
              />
              <img className="dollerCoin" alt="" src={images.realmoney} />
            </div>
            <div style={{ position: "relative" }}>
              <DisableInputField
                classname={"newpadding"}
                label={appconstant.coinCount}
                value={userDetails.coins}
              />
              <img className="coin" alt="" src={images.gameCoin} />
            </div>

            <div className="up-btn">
              <Button
                style={{
                  backgroundColor:
                    userDetails?.isDelete && "rgba(134, 165, 107, 0.46)",
                  cursor: userDetails?.isDelete ? "no-drop" : "pointer",
                }}
                disabel={userDetails?.isDelete}
                onClick={() =>
                  navigate(RoutesPath.editUserDetails, { state: state })
                }
                name={appconstant.edit}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default UserDetails;
