import React, { useEffect, useState } from "react";

//** UI **/

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHome } from "@fortawesome/free-solid-svg-icons";
import { Button, Table } from "antd";

//** STORE **/

import { ResetUserAction } from "../../Store/Action/userManagement";
import { userAccountAction } from "../../Store/Action/AccountDelete";
//** Themes **/

import appconstant from "../../themes/appconstant";

//** COMMON **/

import { BreadcrumbFn } from "../../common/BreadCrumbFn";
import { AccountDeleteTabel } from "../../common/Tabels";
import {
  AccountDeleteTabelButtonClick,
  AccountDeleteTabelButtonName,
  instialData,
} from "../../themes/JsonFile";

import { useSelector } from "react-redux";
import {
  onPagenationHandelChange,
  SearchBarFn,
} from "../../themes/UTILS/commonFunc";
import { dispatch } from "../../themes/UTILS/dispatchNavigate";
import { LoaderAction } from "../../Store/Action/Auth";

const AccountDelete = () => {
  const [searchInput, setSearchInput] = useState("");
  const [paginationData, setpaginationData] = useState(instialData);
  const { data, paginateData, isLoading } = useSelector(
    (state) => state.AccountDeleteReducer
  );
  const [isClick, setIsClick] = useState(false);
  useEffect(() => {
    !isLoading &&
      window.scroll({
        top: 0,
        behavior: "smooth",
      });
  }, [isLoading]);

  useEffect(() => {
    const newPaginationData = {
      ...paginationData,
    };
    dispatch(LoaderAction());
    SearchBarFn(
      newPaginationData,
      userAccountAction,
      setpaginationData,
      paginateData?.currentPage,
      searchInput
    );
    return () => {
      dispatch(ResetUserAction());
    };
  }, [searchInput]);

  const handleChange = (pagination, filters, sorter) => {
    const newPaginationData = { ...paginationData };

    if (sorter.field == "userName") {
      let a = { ...newPaginationData, sortBy: "lowerCaseUserName" };
      onPagenationHandelChange(
        pagination,
        sorter,
        a,
        setpaginationData,
        userAccountAction
      );
    } else {
      onPagenationHandelChange(
        pagination,
        sorter,
        newPaginationData,
        setpaginationData,
        userAccountAction
      );
    }
  };

  return (
    <div>
      <BreadcrumbFn
        path={["dashboard"]}
        pathName={[
          <FontAwesomeIcon icon={faHome} />,
          appconstant.AccountDeleteManagement,
        ]}
      />
      <div
        className="site-layout-background"
        style={{ padding: 24, minHeight: 360 }}
      >
        <div className="content-e">
          <div className="bg-gradient-primary shadow-primary border-radius-lg pt-4 pb-3 system-line">
            <h6 className="text-white text-capitalize ps-3">
              {appconstant.AccountDeleteManagement}
            </h6>
            <div style={{ display: "flex" }}>
              <input
                type="text"
                className="iocn-search  search-bar"
                placeholder="Search"
                value={searchInput}
                onChange={(e) => {
                  setSearchInput(e.target.value.trimStart());
                }}
              />
            </div>
          </div>

          <Table
            rowKey="uid"
            dataSource={data}
            columns={AccountDeleteTabel(
              paginateData?.currentPage,
              AccountDeleteTabelButtonName,
              AccountDeleteTabelButtonClick,
              isClick,
              setIsClick,
              paginationData
            )}
            onChange={handleChange}
            showSorterTooltip={false}
            pagination={{
              current: paginateData?.currentPage ?? 1,
              total: paginateData?.totalCount,
            }}
          />
        </div>
      </div>
    </div>
  );
};
export default AccountDelete;
