import { Modal } from "antd";
import appconstant from "../themes/appconstant";
import Button from "./Button";

//** PopUp model **/
const ModalPop = (
  title,
  content,
  handleOk,
  handleCancel,
  isVisible,
  okText = "Ok",
  cancelText = "Cancel"
) => {
  Modal.confirm({
    className: "new-button",
    title: title,
    visible: isVisible,
    onOk: handleOk,
    onCancel: handleCancel,
    centered: true,
    content: content,
    okText,
    cancelText,
  });
};
export default ModalPop;

export const AddTournamentModal = ({ Visible, onOk, onCancel }) => {
  return (
    <Modal
      maskClosable={true}
      onCancel={onCancel}
      closable={false}
      centered={true}
      open={Visible}
      footer={[
        <Button
          className="ant-btn"
          onClick={() => onOk(appconstant.Battleground)}
          name={appconstant.Battleground}
        />,
        <Button
          className="ant-btn ant-btn-primary rigth-btn-modal"
          onClick={() => onOk(appconstant.knockout)}
          name={appconstant.knockout}
        />,
      ]}
    >
      <h3>{appconstant.ChooseTournament}</h3>
    </Modal>
  );
};
