import React, { useEffect, useRef, useState } from "react";

//** UI **/

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHome } from "@fortawesome/free-solid-svg-icons";
import { DatePicker, Radio, Table, TimePicker } from "antd";
//** Routers **/

import { useLocation } from "react-router-dom";

//**REDUX **/

import { useSelector } from "react-redux";
import {
  TournamentEditAction,
  TournamentMatcheAction,
  TournamentViewDetailAction,
} from "../../Store/Action/tournamentManagement";

//** THEMES **/

import appconstant from "../../themes/appconstant";
import {
  instialData,
  TournamentViewTabelButtonName,
  TouurnamentViewTabelButtonClick,
  TournamentData,
} from "../../themes/JsonFile";
import {
  imgFn,
  onPagenationHandelChange,
  time,
  ToUpperCase,
  TournamentEditvalidationSchema,
  upperCaseFirstChar,
} from "../../themes/UTILS/commonFunc";

//** COMMON **/

import { BreadcrumbFn } from "../../common/BreadCrumbFn";
import { TournamentMatchesTabel } from "../../common/Tabels";
import Textfiled from "../../common/Textfiled";
import { Formik } from "formik";
import moment from "moment";
import { dispatch } from "../../themes/UTILS/dispatchNavigate";
import { LoaderAction } from "../../Store/Action/Auth";
import Button from "../../common/Button";

const TournamentDetails = () => {
  const { state } = useLocation();
  const newdata = { ...instialData };
  const formRef = useRef();
  newdata.tournamentId = state?.id;

  const [paginationData, setpaginationData] = useState(newdata);
  const [IsDisabel, setdisabel] = useState(false);
  const [isClick, setIsClick] = useState(false);
  const [newData, SetInputsData] = useState(TournamentData);
  const { MatchData, MatchpaginateData, TournamentDetails } = useSelector(
    (state) => state.TournamentReducer
  );

  const setDisabel = () => {
    return state.type === appconstant.editTournamentDetails
      ? setdisabel(false)
      : setdisabel(true);
  };

  useEffect(() => {
    setDisabel();
    dispatch(LoaderAction());
    dispatch(TournamentViewDetailAction(state?.id));
    dispatch(TournamentMatcheAction(paginationData));
  }, []);

  const PagehandleChange = (pagination, filters, sorter) => {
    const newdata = paginationData;
    newdata.tournamentId = state.id;
    onPagenationHandelChange(
      pagination,
      sorter,
      newdata,
      setpaginationData,
      TournamentMatcheAction,
      false
    );
  };

  useEffect(() => {
    let newDate = TournamentDetails;
    newDate.startDate = time(TournamentDetails?.start, "MM-DD-YYYY");
    newDate.startTime = time(TournamentDetails?.start, "MM-DD-YYYY HH:mm:ss");
    newDate.endDate = time(TournamentDetails?.end, "MM-DD-YYYY");
    newDate.endTime = time(TournamentDetails?.end, "MM-DD-YYYY HH:mm:ss");
    SetInputsData(newDate);
  }, [TournamentDetails]);

  const onHandelChange = (e) => {
    const data = e.target?.value?.trimStart();
    formRef.current?.setFieldValue([e.target.name], ToUpperCase(data));
  };

  const onFormSubmit = (e) => {
    let newData = {};
    if (state.type === appconstant.editTournamentDetails) {
      newData.tournamentId = e._id;
      if (e.startDate && e.startTime) {
        let date = `${time(e?.startDate, "MM-DD-YYYY")} ${time(
          e?.startTime,
          "HH:mm:ss"
        )}`;
        newData.start = new Date(date);
      }
      if (e?.endTime && e?.endDate) {
        let date = `${time(e?.endDate, "MM-DD-YYYY")} ${time(
          e?.endTime,
          "HH:mm:ss"
        )}`;
        newData.end = new Date(date);
      }
      newData.name = e.name;
    }
    dispatch(TournamentEditAction(newData));
  };

  const checkPage = () => (!IsDisabel ? true : false);
  return (
    <div>
      <BreadcrumbFn
        path={["dashboard", "tournamentmanagement"]}
        pathName={[
          <FontAwesomeIcon icon={faHome} />,
          appconstant.tournamentManagement,
          state.type,
        ]}
      />
      <div
        className="site-layout-background"
        style={{ padding: 24, minHeight: 360 }}
      >
        <div className="content-e">
          <div className="bg-gradient-primary shadow-primary border-radius-lg pt-4 pb-3">
            <h6 className="text-white text-capitalize ps-3">{state.type}</h6>
          </div>
          <div className="wrapper_line view_modules view">
            <Formik
              initialValues={newData}
              enableReinitialize
              onSubmit={onFormSubmit}
              innerRef={formRef}
              validationSchema={TournamentEditvalidationSchema}
            >
              {({
                handleBlur,
                handleSubmit,
                errors,
                values,
                touched,
                setFieldValue,
              }) =>
              (
                <form onSubmit={handleSubmit}>
                  <Textfiled
                    disabled={checkPage()}
                    readonly={IsDisabel}
                    label={appconstant.tournamentId}
                    value={values.UUID}
                  />
                  <Textfiled
                    disabled={checkPage()}
                    readonly={IsDisabel}
                    onChange={onHandelChange}
                    label={appconstant.tournamenttype}
                    value={upperCaseFirstChar(values.type)}
                  />
                  <Textfiled
                    readonly={IsDisabel}
                    label={appconstant.tournamentname}
                    value={upperCaseFirstChar(values.name)}
                    name={"name"}
                    onChange={onHandelChange}
                    onBlur={handleBlur}
                    error={Boolean(errors.name && touched.name)}
                    helperText={touched.name && errors.name}
                  />
                  <div className="form-group">
                    <label>{appconstant.tournamentStartDate}</label>
                    <DatePicker
                      disabledDate={(c) => {
                        let date = new Date(c._d);
                        date.setDate(date.getDate() + 1);
                        return date < moment().endOf("day");
                      }}
                      className={IsDisabel ? "iconsDisplay" : ""}
                      showToday={false}
                      disabled={IsDisabel}
                      name={"startDate"}
                      format={appconstant.DateFormat}
                      placeholder="Tournament Start Date"
                      onChange={(e, i) => {
                        setFieldValue("startDate", e?._d);
                        setFieldValue("endDate", moment(e?._d).add(1, "days"));
                      }}
                      value={moment(values.startDate)}
                      onBlur={handleBlur}
                    />
                  </div>
                  <div className="form-group">
                    <label>{appconstant.tournamentStartTime}</label>
                    <TimePicker
                      className={IsDisabel ? "iconsDisplay" : ""}
                      showNow={false}
                      name="startTime"
                      disabled={IsDisabel}
                      use12Hours
                      format={appconstant.TimeFormat}
                      placeholder="Tournament Start Time"
                      onChange={(e) => {
                        setFieldValue("startTime", e?._d);
                      }}
                      value={moment(values.startTime)}
                      onBlur={handleBlur}
                    />
                  </div>
                  {TournamentDetails?.type !== "knockout" && (
                    <div>
                      <div className="form-group">
                        <label>{appconstant.tournamentEndDate}</label>
                        <DatePicker
                          className={IsDisabel ? "iconsDisplay" : ""}
                          disabledDate={(c) => {
                            let date = new Date(c._d);
                            date.setDate(date.getDate() + 1);
                            return date < moment().endOf("day");
                          }}
                          showToday={false}
                          disabled={IsDisabel}
                          name={"endDate"}
                          format={appconstant.DateFormat}
                          placeholder="Tournament End Date"
                          onChange={(e, i) => {
                            setFieldValue("endDate", e?._d);
                          }}
                          value={moment(values.endDate)}
                          onBlur={handleBlur}
                        />
                      </div>

                      <div className="form-group">
                        <label>{appconstant.tournamentEndTime}</label>
                        <div>
                          <TimePicker
                            className={IsDisabel ? "iconsDisplay" : ""}
                            showNow={false}
                            defaultValue={new Date().getTime()}
                            name="endTime"
                            disabled={IsDisabel}
                            use12Hours
                            format={appconstant.TimeFormat}
                            onChange={(e) => {
                              setFieldValue("endTime", e._d);
                            }}
                            value={moment(values.endTime)}
                            onBlur={handleBlur}
                            placeholder="Tournament End Time"
                          />
                        </div>
                      </div>
                    </div>
                  )}
                  {TournamentDetails?.type === "knockout" && (
                    <Textfiled
                      disabled={checkPage()}
                      readonly={IsDisabel}
                      label={appconstant.poolPrice}
                      value={values.pricePool}
                    />
                  )}

{TournamentDetails?.type === "knockout" && (TournamentDetails?.moneyType == "real") && (
                   
                    <Textfiled
                    disabled={checkPage()}
                    readonly={IsDisabel}
                    label={"Admin Commission"}
                    value={values.adminCommission}
                  />

                  )}
                  <label>{appconstant.nextTournamentGenerateDateTime}</label>
                  <Textfiled
                    disabled={checkPage()}
                    readonly={IsDisabel}
                    use12Hours
                    value={values.NTGD ? time(values.NTGD, appconstant.DateTimeFormat) : "N/A"}
                    className={"NTGD"}
                  />
                  <Textfiled
                    disabled={checkPage()}
                    readonly={IsDisabel}
                    label={appconstant.TotalPlayers}
                    value={values.totalPlayers}
                  />
                  <div style={{ marginBottom: "15px" }}>
                    <label>{appconstant.amounttype}</label>
                    <Radio.Group
                      style={{
                        display: "flex",
                        justifyContent: "flex-start",
                        margin: "10px 0",
                      }}
                      value={values.moneyType}
                    >
                      <Radio
                        disabled={values.moneyType !== "coins" || checkPage()}
                        readonly={IsDisabel}
                        value={"coins"}
                      >
                        Coins
                      </Radio>
                      <Radio
                        disabled={values.moneyType !== "real" || checkPage()}
                        readonly={IsDisabel}
                        value={"real"}
                      >
                        Real
                      </Radio>
                    </Radio.Group>
                  </div>
                  <div style={{ position: "relative" }}>
                    <Textfiled
                      className={"padding"}
                      disabled={checkPage()}
                      readonly={IsDisabel}
                      label={appconstant.entryFee}
                      value={values.entryFee}
                    />
                    {imgFn(values.moneyType)}
                  </div>
                  <Textfiled
                    disabled={checkPage()}
                    readonly={IsDisabel}
                    label={appconstant.winner}
                    value={values.winner?values.winner:"N/A"}
                  />
                  <div style={{ position: "relative" }}>
                    <Textfiled
                      className={"padding"}
                      disabled={checkPage()}
                      readonly={IsDisabel}
                      label={appconstant.winneramount}
                      value={values.winnerAmount}
                    />
                    {imgFn(values.moneyType)}
                  </div>
                  <Textfiled
                    disabled={checkPage()}
                    readonly={IsDisabel}
                    label={appconstant.runnerUp}
                    value={values.runnerUp?values.runnerUp:"N/A "}
                  />
                  <div style={{ position: "relative" }}>
                    <Textfiled
                      className={"padding"}
                      disabled={checkPage()}
                      readonly={IsDisabel}
                      label={appconstant.runnerUpAmount}
                      value={values.runnerUpAmount}
                    />
                    {imgFn(values.moneyType)}
                  </div>
                  <div className="up-btn">
                    {!IsDisabel && <Button type="submit" name={appconstant.buttonupate} />}
                  </div>
                </form>
              )}
            </Formik>
          </div>
          {
            <div
              className="mt-topp"
              style={{ display: MatchData?.data?.length ? "" : "none" }}
            >
              <Table
                rowKey="uid"
                dataSource={MatchData?.data}
                columns={TournamentMatchesTabel(
                  MatchData?.pagination?.currentPage,
                  TournamentViewTabelButtonName,
                  TouurnamentViewTabelButtonClick,
                  isClick,
                  setIsClick,
                  paginationData
                )}
                onChange={PagehandleChange}
                showSorterTooltip={false}
                pagination={{
                  current: MatchData?.pagination?.currentPage ?? 1,
                  total: MatchData?.pagination?.totalCount,
                }}
              />
            </div>
          }
        </div>
      </div>
    </div>
  );
};
export default TournamentDetails;
