import moment from "moment";
import * as Yup from "yup";
import appconstant from "../appconstant";
import images from "../appImage";
import { dispatch } from "./dispatchNavigate";

export const CheckValue = (value, isDraw, label) => {
  let val = value;
  if (value) {
    if (value?.toString() === "true") {
      val = "Yes";
    } else {
    }
  } else {
    if (isDraw === "Draw" && value === null) {
      val = 0;
    } else if (value?.toString() === "false") {
      val = "No";
    } else if (value?.toString() === "0") {
      val = 0;
    } else {
      val = "N/A";
    }
  }
  return val;
};

//this function is use to dispatch action when user type in search bar .
export const SearchBarFn = (
  instialData,
  action,
  SetState,
  currentPage,
  searchInput
) => {
  try {
    let newData = instialData;
    newData.search = searchInput;
    newData.offset =
      searchInput?.length === 0
        ? currentPage
          ? (currentPage - 1) * 10
          : 0
        : 0;
    dispatch(action(newData));
    SetState(newData);
  } catch (e) { }
};

// this function calls when the user click on the sorting icon or the change the page
export const onPagenationHandelChange = (
  pagination,
  sorter,
  data,
  setData,
  action,
  scroll = true
) => {
  try {
    let order =
      sorter.order === "ascend" ? 1 : sorter.order === "descend" ? -1 : null;
    const newData = {
      search: data.search,
      offset: (pagination.current - 1) * 10,
      limit: 10,
      filter: data?.filter,
    };
    newData.tournamentId = data.tournamentId;
    if (order) {
      newData.sortBy = sorter.field === "userName" ? data.sortBy : sorter.field;
      newData.order = order;
    }
    if (data.type) {
      newData.type = data.type;
    }
    dispatch(action(newData));
    setData(newData);
  } catch (e) { }
};

//this function will check any special or number in phone number
export const numberVadiation = (i) => {
  if (i == "Backspace") {
    return true;
  }
  const regx = /^\d+$/; //only allows numbers
  return regx.test(i);
};

export const blockInvalidCharStringNumber = (e) => {
  if (e.which == 8 || e.which === 38 || e.which === 40 || e.which === 13) {
    return;
  }

  if (!numberVadiation(e.key)) {
    return e.preventDefault();
  }
};

// VALIDATION SCHEMA
export const UserManagementValidationSchema = Yup.object().shape({
  fullname: Yup.string()
    .required(`${appconstant.Text} ${appconstant.FirstName?.toLowerCase()}.`)
    .min(2, "First name length should be 2 to 30 characters.")
    .matches(
      /^[a-zA-Z0-9][a-zA-Z.\s]+$/,
      `${appconstant.Valid}${appconstant.FirstName?.toLowerCase()}.`
    ),
  lastname: Yup.string()
    .required(`${appconstant.Text} ${appconstant.LastName?.toLowerCase()}.`)
    .min(2, "Last name length should be 2 to 30 characters.")
    .matches(
      /^[a-zA-Z0-9][a-zA-Z.\s]+$/,
      `${appconstant.Valid}${appconstant.LastName?.toLowerCase()}.`
    ),
  email: Yup.string()
    .required(`${appconstant.Text} ${appconstant.emailAddress?.toLowerCase()}.`)
    .matches(
      /^[a-zA-Z0-9]+(\.[a-zA-Z0-9]+)*@[a-zA-Z0-9]+(\.[a-zA-z]{2,10})+$/,
      `${appconstant.Valid}${appconstant.emailAddress?.toLowerCase()}.`
    )
    .max(255),
  phone: Yup.string()
    .min(8, `Phone number length should be 8 to 13 characters.`)
    .max(13, "Phone number should not be greater then 13 characters."),
  postalCode: Yup.string()
    .matches(/^[a-zA-Z0-9]*$/, "Special characters are not allowed.")
    .max(6, "Postal code should not be greater then 6 characters."),
});

export const validationSchema = Yup.object().shape({
  fullname: Yup.string()
    .required(`${appconstant.Text} ${appconstant.FirstName?.toLowerCase()}.`)
    .min(2, "First name length should be 2 to 30 characters.")
    .matches(
      /^[a-zA-Z0-9][a-zA-Z.\s]+$/,
      `${appconstant.Valid}${appconstant.FirstName?.toLowerCase()}.`
    ),
  lastname: Yup.string()
    .required(`${appconstant.Text} ${appconstant.LastName?.toLowerCase()}.`)
    .min(2, "Last name length should be 2 to 30 characters.")
    .matches(
      /^[a-zA-Z0-9][a-zA-Z.\s]+$/,
      `${appconstant.Valid}${appconstant.LastName?.toLowerCase()}.`
    ),
  email: Yup.string()
    .required(`${appconstant.Text} ${appconstant.emailAddress?.toLowerCase()}.`)
    .matches(
      /^[a-zA-Z0-9]+(\.[a-zA-Z0-9]+)*@[a-zA-Z0-9]+(\.[a-zA-z]{2,10})+$/,
      `${appconstant.Valid}${appconstant.emailAddress?.toLowerCase()}.`
    )
    .max(255),
});

export const TournamentEditvalidationSchema = Yup.object().shape({
  name: Yup.string()
    .required(
      `${appconstant.Text} ${appconstant.tournamentname?.toLowerCase()}.`
    )
    .min(2, "The tournament name length should be 2 to 40 characters.")
    .matches(
      /^(?![0-9]*$)[a-zA-Z0-9 ]+$/,
      `${appconstant.Alpha} ${appconstant.tournamentname?.toLowerCase()}.`
    ),
});
export const TournamentAddvalidationSchema = Yup.object().shape({
  name: Yup.string()
    .required(
      `${appconstant.Text} ${appconstant.tournamentname?.toLowerCase()}.`
    )
    .min(2, "The tournament name length should be 2 to 40 characters.")
    .matches(
      /^(?![0-9]*$)[a-zA-Z0-9 ]+$/,
      `${appconstant.Alpha}${appconstant.tournamentname?.toLowerCase()}.`
    ),
  showPricepool: Yup.boolean(),

  timeInterval: Yup.number()
    .required(`${appconstant.Text} ${appconstant.timeInterval?.toLowerCase()}.`)
    .max(120, "The time interval should not be greater then 120 mins."),

  matchType: Yup.string()
    .required(`Please select  ${appconstant.timeForMatch?.toLowerCase()}.`)
    .nullable(),

  entryFee: Yup.number()
    .required(`${appconstant.Text} ${appconstant.entryFees?.toLowerCase()}.`)
    .min(1, `The entry fees amount should be at least 1.`)
    .max(100000, "The entry fee amount should not be greater then 100000."),

  totalTournament: Yup.number()
    .required(
      `${appconstant.Text} ${appconstant.totalTournaments?.toLowerCase()}.`
    )
    .max(20, "The total tournament should not be greater then 20."),
});

export const validationEditSchema = Yup.object().shape({
  title: Yup.string()
    .required(`${appconstant.Text} ${appconstant.tittle?.toLowerCase()}.`)
    .min(2, "The title length should be 2 to 40 characters.")
    .matches(
      /[a-zA-z0-9\s\$]*/,
      `${appconstant.Valid}${appconstant.tittle?.toLowerCase()}.`
    ),
  description: Yup.string()
    .required(`${appconstant.Text} ${appconstant.description?.toLowerCase()}.`)
    .min(2, "The description length should be 2 to 200 characters."),
});

export const ToUpperCase = (string) => {
  return string?.charAt(0)?.toUpperCase() + string?.slice(1).toLowerCase();
};

export const upperCaseFirstChar = (string) => {
  return string?.charAt(0)?.toUpperCase() + string?.slice(1);
};

export const time = (time, require) => {
  return moment(time)?.format(require);
};

//To check wether the value present in the object or not

export const CheckFn = (e) => {
  let obj = {};
  for (let i in e) {
    if (e[i] === 0 || e[i]) {
      obj[i] = e[i];
    }
  }
  return obj;
};

//Error validation checker " this made to remove the Complexity of the function "

export const IsError = (error, touched, name) => {
  return Boolean(error[name] && touched[name]);
};
export const helperText = (error, touched, name) => {
  return touched[name] && error[name];
};

export const blockInvalidChar = (e) => {
  //*********************{ 38 & 39 for up and down arrow key and 13 is for enter   }***************************/
  if (e.which === 38 || e.which === 40 || e.which === 13) {
    e.preventDefault();
  }
  return ["e", "E", "+", "-", "."].includes(e.key) && e.preventDefault();
};

export const imgFn = (value) => {
  return (
    <img
      className={value === "coins" ? " coin " : "dollerCoin"}
      src={value === "coins" ? images.gameCoin : images.realmoney}
      alt=""
    />
  );
};

export const blockUpperCaseName = (e) => {
  if (e.which === 20) {
    e.preventDefault();
  }
};

//***************** Provide range for disabling spesific date and time *********************/
export function range(start = 12, end = 24, currentMoment) {
  const result = [];
  for (let i = start; i < end; i++) {
    result.push(i);
  }
  return currentMoment ? result.splice(0, currentMoment) : result;
}

export const addStateValidationSchema = Yup.object().shape({
  name: Yup.string()
    .required(`${appconstant.Text} state name.`)
    .matches(/^[a-zA-Z0-9]*$/, "Special characters are not allowed.")
    .test("test", "Please enter alphabetic or alphanumeric characters.", value => {
      if (isNaN(value)) return true
      else return false
    })
    .min(2, "The name length should be 2 to 40 characters.")

});
export const removeEmojis = (string) => {

  let regex = /(?:[\u00A9\u00AE\u203C\u2049\u2122\u2139\u2194-\u2199\u21A9-\u21AA\u231A-\u231B\u2328\u23CF\u23E9-\u23F3\u23F8-\u23FA\u24C2\u25AA-\u25AB\u25B6\u25C0\u25FB-\u25FE\u2600-\u2604\u260E\u2611\u2614-\u2615\u2618\u261D\u2620\u2622-\u2623\u2626\u262A\u262E-\u262F\u2638-\u263A\u2640\u2642\u2648-\u2653\u2660\u2663\u2665-\u2666\u2668\u267B\u267F\u2692-\u2697\u2699\u269B-\u269C\u26A0-\u26A1\u26AA-\u26AB\u26B0-\u26B1\u26BD-\u26BE\u26C4-\u26C5\u26C8\u26CE-\u26CF\u26D1\u26D3-\u26D4\u26E9-\u26EA\u26F0-\u26F5\u26F7-\u26FA\u26FD\u2702\u2705\u2708-\u270D\u270F\u2712\u2714\u2716\u271D\u2721\u2728\u2733-\u2734\u2744\u2747\u274C\u274E\u2753-\u2755\u2757\u2763-\u2764\u2795-\u2797\u27A1\u27B0\u27BF\u2934-\u2935\u2B05-\u2B07\u2B1B-\u2B1C\u2B50\u2B55\u3030\u303D\u3297\u3299]|(?:\uD83C[\uDC04\uDCCF\uDD70-\uDD71\uDD7E-\uDD7F\uDD8E\uDD91-\uDD9A\uDDE6-\uDDFF\uDE01-\uDE02\uDE1A\uDE2F\uDE32-\uDE3A\uDE50-\uDE51\uDF00-\uDF21\uDF24-\uDF93\uDF96-\uDF97\uDF99-\uDF9B\uDF9E-\uDFF0\uDFF3-\uDFF5\uDFF7-\uDFFF]|\uD83D[\uDC00-\uDCFD\uDCFF-\uDD3D\uDD49-\uDD4E\uDD50-\uDD67\uDD6F-\uDD70\uDD73-\uDD7A\uDD87\uDD8A-\uDD8D\uDD90\uDD95-\uDD96\uDDA4-\uDDA5\uDDA8\uDDB1-\uDDB2\uDDBC\uDDC2-\uDDC4\uDDD1-\uDDD3\uDDDC-\uDDDE\uDDE1\uDDE3\uDDE8\uDDEF\uDDF3\uDDFA-\uDE4F\uDE80-\uDEC5\uDECB-\uDED2\uDEE0-\uDEE5\uDEE9\uDEEB-\uDEEC\uDEF0\uDEF3-\uDEF6]|\uD83E[\uDD10-\uDD1E\uDD20-\uDD27\uDD30\uDD33-\uDD3A\uDD3C-\uDD3E\uDD40-\uDD45\uDD47-\uDD4B\uDD50-\uDD5E\uDD80-\uDD91\uDDC0]))/g;
  let result = string.replace(regex, '')

  return result
}
