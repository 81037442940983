import { combineReducers } from "redux";
import { AuthReducer } from "./Auth";
import { dashboardReducer } from "./dashboardReducer";
import { UsersReducer } from "./UserManagement";
import { GamesReducer } from "./GameManagement";
import { TournamentReducer } from "./TournamentReducer";
import PaymentReducer from "./PaymentReducer";
import { NotificationReducer } from "./NotificationManagement";
import { AccountDeleteReducer } from "./AccountDelete";
import { RestrictedStateReducer } from "./RestrictedStateReducer";
import {MaintenanceStateReducer} from  "./MaintenanceReducer";
import appconstant from "../../themes/appconstant";
const appReducer = combineReducers({
  AuthReducer,
  dashboardReducer,
  UsersReducer,
  GamesReducer,
  TournamentReducer,
  PaymentReducer,
  NotificationReducer,
  AccountDeleteReducer,
  RestrictedStateReducer,
  MaintenanceStateReducer
});

const rootReducer = (state, action) => {
  if (action.type === appconstant.reduxConstant.RESET_STORE) state = undefined;
  return appReducer(state, action);
};
export default rootReducer;
