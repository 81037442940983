import appconstant from "../../themes/appconstant";

const { reduxConstant } = appconstant;
const instialState = {
  isLoading: false,
  data: [],
  paginateData: {},
};
const PaymentReducer = (state = instialState, action) => {
  switch (action.type) {
    case reduxConstant.PAYMENT_INFO_LOAD:
      return {
        ...state,
        isLoading: true,
      };
    case reduxConstant.PAYMENT_INFO_SUCCESS:
      return {
        ...state,
        isLoading: false,
        data: action.payload.data,
        paginateData: action.payload.pagination,
      };
    case reduxConstant.PAYMENT_INFO_FAIL:
      return {
        ...state,
        isLoading: false,
        data: [],
      };
    case reduxConstant.PAYMENT_RESET:
      return {
        ...state,
        paginateData: {},
      };

    case reduxConstant.Payment_Request_Load:
      return {
        ...state,
        isLoading: true,
      };
    case reduxConstant.Payment_Request_Success:
      return {
        ...state,
        isLoading: false,
      };
    case reduxConstant.Payment_Request_Fail:
      return {
        ...state,
        isLoading: false,
      };
    default:
      return { ...state };
  }
};
export default PaymentReducer;
