import { call, put, takeLatest } from "@redux-saga/core/effects";

import {
  NotificationDeleteApi,
  NotificationAddApi,
  NotificationApi,
  NotificationEditApi,
  NotificationViewApi,
} from "../Api";

import appconstant from "../../themes/appconstant";
import {
  onFail,
  onError,
  onUnAuth,
  onSucces,
  Loader,
} from "../../themes/UTILS/UnAuthFailFn";
import { instialData } from "../../themes/JsonFile";

const { reduxConstant } = appconstant;



//** Notification List **/
function* NotificationListSaga(action) {
  try {
    const data = yield call(NotificationApi, action.data);
    //** Success **/
    if (data.status === 0) {
      yield call(onSucces, {
        type: reduxConstant.NOTIFICATION_LIST_SUCCESS,
        data,
        show: false,
      });

      yield call(Loader, appconstant.reduxConstant.ON_SUCCESS);
    }
    //** Un Authenticate **/
    else if (data.status === 2) {
      onUnAuth(data?.result?.message);
    }
    //** Fail **/
    else {
      yield call(onFail, {
        type: reduxConstant.NOTIFICATION_FAIL,
        message: data?.result?.message,
        // show: false,
      });
      yield call(Loader, appconstant.reduxConstant.ON_FAIL);
    }
  } catch (e) {
    //** Un Catch error **/
    yield call(onError, reduxConstant?.NOTIFICATION_FAIL);
    yield call(Loader, appconstant.reduxConstant.ON_FAIL);
  }
}
//Delete Notification
function* NotificationDeleteSaga(action) {
  try {
    const data = yield call(NotificationDeleteApi, action.data.id);
    //** Success **/
    if (data.status === 0) {
      yield put({
        type: reduxConstant.NOTIFICATION_LIST_LOAD,
        data: action.data.paginationData,
      });
      yield call(onSucces, {
        type: reduxConstant.NOTIFICATION_DELETE_SUCCESS,
        data,
        show: true,
      });
      yield call(Loader, appconstant.reduxConstant.ON_SUCCESS);
    }
    //** Un Authenticate **/
    else if (data.status === 2) {
      onUnAuth(data?.result?.message);
    }
    //** Fail **/
    else {
      const obj = {
        type: reduxConstant.NOTIFICATION_FAIL,
        message: data?.result?.message,
      };
      yield call(onFail, obj);
      yield call(Loader, appconstant.reduxConstant.ON_FAIL);
    }
  } catch (e) {
    yield call(Loader, appconstant.reduxConstant.ON_FAIL);
    yield call(onError, reduxConstant?.NOTIFICATION_FAIL);
  }
}
//View Notification
function* NotificationViewSaga(action) {
  try {
    const data = yield call(NotificationViewApi, action.data);
    if (data.status === 0) {
      yield call(onSucces, {
        type: reduxConstant.NOTIFICATION_VIEW_SUCCESS,
        data,
        show: false,
      });
      yield call(Loader, appconstant.reduxConstant.ON_SUCCESS);
    } else if (data.status === 2) {
      onUnAuth(data?.result?.message);
    } else {
      yield call(onFail, {
        type: reduxConstant.NOTIFICATION_FAIL,
        message: data?.result?.message,
      });
      yield call(Loader, appconstant.reduxConstant.ON_FAIL);
    }
  } catch (e) {
    yield call(Loader, appconstant.reduxConstant.ON_FAIL);
    yield call(onError, reduxConstant?.NOTIFICATION_FAIL);
  }
}
//Add Notification
function* NotificationAddSaga(action) {
  try {
    const data = yield call(NotificationAddApi, action.data);
    if (data.status === 0) {
      yield call(onSucces, {
        type: reduxConstant.NOTIFICATION_ADD_SUCCESS,
        data,
        show: true,
        navigationLink: appconstant.RoutesPath.notificationManagement,
      });

      yield put({
        type: reduxConstant.NOTIFICATION_LIST_LOAD,
        data: instialData,
      });
      yield call(Loader, appconstant.reduxConstant.ON_SUCCESS);
    } else if (data.status === 2) {
      onUnAuth(data?.result?.message);
    } else {
      yield call(onFail, {
        type: reduxConstant.NOTIFICATION_FAIL,
        message: data?.result?.message,
      });
      yield call(Loader, appconstant.reduxConstant.ON_FAIL);
    }
  } catch (e) {
    yield call(Loader, appconstant.reduxConstant.ON_FAIL);
    yield call(onError, reduxConstant?.NOTIFICATION_FAIL);
  }
}
//Edit Notification
function* NotificationEditSaga(action) {
  try {
    const data = yield call(NotificationEditApi, action.data);
    if (data.status === 0) {
      yield call(onSucces, {
        type: reduxConstant.NOTIFICATION_EDIT_SUCCESS,
        data,
        show: true,
        navigationLink: appconstant.RoutesPath.notificationManagement,
      });

      yield put({
        type: reduxConstant.NOTIFICATION_LIST_LOAD,
        data: instialData,
      });

      yield call(Loader, appconstant.reduxConstant.ON_FAIL);
    } else if (data.status === 2) {
      onUnAuth(data?.result?.message);
    } else {
      yield call(onFail, {
        type: reduxConstant.NOTIFICATION_FAIL,
        message: data?.result?.message,
      });

      yield call(Loader, appconstant.reduxConstant.ON_FAIL);
    }
  } catch (e) {
    yield call(Loader, appconstant.reduxConstant.ON_FAIL);
    yield call(onError, reduxConstant?.NOTIFICATION_FAIL);
  }
}

export default function* rootNotificationManagementSaga() {
  yield takeLatest(reduxConstant.NOTIFICATION_LIST_LOAD, NotificationListSaga);
  yield takeLatest(
    reduxConstant.NOTIFICATION_DELETE_LOAD,
    NotificationDeleteSaga
  );
  yield takeLatest(reduxConstant.NOTIFICATION_VIEW_LOAD, NotificationViewSaga);
  yield takeLatest(reduxConstant.NOTIFICATION_ADD_LOAD, NotificationAddSaga);
  yield takeLatest(reduxConstant.NOTIFICATION_EDIT_LOAD, NotificationEditSaga);
}
