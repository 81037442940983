import React from "react";
import { Navigate } from "react-router-dom";
import appconstant from "../themes/appconstant";
import { DataManager } from "../themes/UTILS/dataManager"

export const PrivateRoute = ({ children }) => {
    let tokenCheck = DataManager.getAccessToken()
    return tokenCheck ? children : <Navigate to={appconstant.RoutesPath.Login} />
}

export const PublicRoute = ({ children }) => {
    let tokenCheck = DataManager.getAccessToken()
    return !tokenCheck ? children : <Navigate to={appconstant.RoutesPath.Dashboard} />
}
