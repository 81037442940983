import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHome } from "@fortawesome/free-solid-svg-icons";

import appconstant from "../../themes/appconstant";

import Textfiled from "../../common/Textfiled";
import { BreadcrumbFn } from "../../common/BreadCrumbFn";
import { dispatch } from "../../themes/UTILS/dispatchNavigate";
import { LoaderAction } from "../../Store/Action/Auth";
import { Formik } from "formik";
import {

  addStateValidationSchema,
  removeEmojis,
} from "../../themes/UTILS/commonFunc";
import { useLocation } from "react-router-dom";
import {
  RestrictedStateAddAction,
  RestrictedStateEditAction
} from "../../Store/Action/RestrictedStateAction";
import Button from "../../common/Button";
const AddState = () => {
  const { state } = useLocation();

  const RestrictedStateData = {
    name: ""
  }


  const formSubmit = (e) => {

    dispatch(RestrictedStateAddAction(e));
    dispatch(LoaderAction());
  };
  return (
    <div>
      <BreadcrumbFn
        path={["dashboard", "restrictedStateManagement"]}
        pathName={[
          <FontAwesomeIcon icon={faHome} />,
          appconstant.restrictedStateManagement,
          state?.type,
        ]}
      />
      <div
        className="site-layout-background"
        style={{ padding: 24, minHeight: 360 }}
      >
        <div className="content-e butoo-pa">
          <div className="bg-gradient-primary shadow-primary border-radius-lg pt-4 pb-3">
            <h6 className="text-white text-capitalize ps-3">{state?.type}</h6>
          </div>
          <Formik
            enableReinitialize
            initialValues={RestrictedStateData}
            onSubmit={formSubmit}
            validationSchema={addStateValidationSchema}
          >
            {({
              handleBlur,
              handleSubmit,
              values,
              errors,
              touched,
              setFieldValue,
            }) => (
              <form onSubmit={handleSubmit}>
                <div className="key">
                  <Textfiled
                    label={appconstant.stateName}
                    name={"name"}
                    onInput={(e) => {
                      let value = removeEmojis(e.target.value)
                      e.target.value = value.startsWith(" ") ? value.trimLeft() : value
                    }}
                    onChange={(e) => {
                      let data = e.target.value.trimStart();
                      data = removeEmojis(data)
                      setFieldValue("name", (data));
                    }}

                    onBlur={handleBlur}
                    error={Boolean(errors.name && touched.name)}
                    helperText={touched.name && errors.name}
                  />
                </div>


                <div className="up-btn" style={{ paddingBottom: "30px" }}>
                  <Button
                    name={appconstant.addState}
                  />
                </div>
              </form>
            )}
          </Formik>
        </div>
      </div>
    </div>
  );
};
export default AddState;
