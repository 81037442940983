import { APIMethod } from "./ApiMethods";

//***********************************************  AUTH ***************************************************************/

//login
export const LoginApi = (data) => {
  return APIMethod.POST("admin/login", JSON.stringify(data));
};

//forgot password
export const ForgotPasswordApi = (data) => {
  let body = JSON.stringify({
    email: data,
  });
  return APIMethod.POST("admin/forgotPassword", body);
};

//reset password
export const ResetPasswordApi = (data, id) => {
  let body = JSON.stringify({
    token: id,
    password: data,
  });
  return APIMethod.POST("admin/resetPassword", body);
};

//check link forgot password
export const checkLinkApi = (data) => {
  let body = JSON.stringify({
    token: data,
  });
  return APIMethod.POST("admin/forgotLink", body);
};

//change password
export const changePasswordApi = (oldPassword, newPassword) => {
  let body = JSON.stringify({
    oldPassword: oldPassword,
    newPassword: newPassword,
  });
  return APIMethod.PUT("admin/change/password", body);
};

//***********************************************  DASHBOARD ***************************************************************/

//dashboard
export const dashboardApi = () => {
  return APIMethod.GET("admin/dashboard");
};

//***********************************************  USER ***************************************************************/

//User management
export const UsersApi = (data) => {
  return APIMethod.POST("admin/userList", JSON.stringify(data));
};
//user block
export const UserBlockApi = (data) => {
  return APIMethod.PUT(`admin/user/block/${data}`);
};
//user block
export const UserPassResetApi = (data) => {
  return APIMethod.PUT(`admin/user/reset/${data}`);
};
//user Details
export const UserDetailApi = (data) => {
  return APIMethod.GET(`admin/user/detail/${data}`);
};
//Edit user Details
export const EditUserApi = (data) => {
  return APIMethod.PUT(`admin/user/update`, JSON.stringify(data));
};

//***********************************************  GAME ***************************************************************/

//Game Management

export const GamesApi = (data) => {
  return APIMethod.POST(`admin/game/list`, JSON.stringify(data));
};
//Game view

export const GameDetailApi = (data) => {
  return APIMethod.GET(`admin/game/detail/${data}`);
};
//***********************************************  Account Delete  ***************************************************************/

//Game Management

export const AccountDeleteList = (data) => {
  return APIMethod.POST(`admin/accountDelete/requests`, JSON.stringify(data));
};
//Game view

export const AccountDeleteAccept = (data) => {
  return APIMethod.POST(`admin/acceptReject/request`, JSON.stringify(data));
};

//***********************************************  TOURNAMENT ***************************************************************/

//Tournament management
export const TournamentApi = (data) => {
  return APIMethod.POST("admin/tournament/list", JSON.stringify(data));
};
//Tournament block
export const TournamentBlockApi = (data) => {
  return APIMethod.PUT(`admin/tournament/block/${data}`);
};
//Tournament Add
export const TournamentAddApi = (data) => {
  return APIMethod.POST(`admin/tournament/add/`, JSON.stringify(data));
};
//Tournament Details
export const TournamentDetailApi = (data) => {
  return APIMethod.GET(`admin/tournament/detail/${data}`);
};
//Edit Tournament Details
export const TournamentEditApi = (data) => {
  return APIMethod.PUT(`admin/tournament/update`, JSON.stringify(data));
};

//Matchs Tournament Details
export const TournamentMatchesDetailApi = (data) => {
  return APIMethod.POST(`admin/tournament/matches`, JSON.stringify(data));
};

//Matchs Tournament Details
export const DepositApi = (data) => {
  return APIMethod.POST(`admin/payment/list`, JSON.stringify(data));
};

export const acceptRejectApi=(data)=>{
  return APIMethod.POST("admin/transaction-accept-reject",JSON.stringify(data.data))
}

//***********************************************  NOTIFICATION ***************************************************************/

//NOTIFICATION LIST
export const NotificationApi = (data) => {
  return APIMethod.POST(`admin/notification/list`, JSON.stringify(data));
};
//NOTIFICATION DELETE
export const NotificationDeleteApi = (data) => {
  return APIMethod.DELETE(`admin/notification/delete/${data}`);
};
//NOTIFICATION VIEW
export const NotificationViewApi = (data) => {
  return APIMethod.GET(`admin/notification/detail/${data}`);
};
//NOTIFICATION ADD
export const NotificationAddApi = (data) => {
  return APIMethod.POST(`admin/notification/add`, JSON.stringify(data));
};
//NOTIFICATION UPDATE
export const NotificationEditApi = (data) => {
  return APIMethod.PUT(`admin/notification/update`, JSON.stringify(data));
};
//NOTIFICATION UPDATE
export const MatchTypeListApi = () => {
  return APIMethod.GET(`admin/matchType/list`);
};

//***********************************************  RESTRICTED STATES ***************************************************************/

//RESTRICTED STATE LIST
export const RestrictedStateApi = (data) => {
  return APIMethod.POST(`admin/state-list`, JSON.stringify(data));
};
//RESTRICTED STATE DELETE
export const RestrictedStateDeleteApi = (data) => {
  return APIMethod.DELETE(`admin/state-delete/${data}`);
};
//RESTRICTED STATE ADD
export const RestrictedStateAddApi = (data) => {
  return APIMethod.POST(`admin/add-state`, JSON.stringify(data));
};
//RESTRICTED STATE UPDATE
export const RestrictedStateEditApi = (data) => {
  return APIMethod.PUT(`admin/edit-state`, JSON.stringify(data));
};
//***********************************************  Maintenance STATES ***************************************************************/



//Maintance STATE LIST
export const MaintenanceListApi = (data) => {
  return APIMethod.POST(`admin/maintenance-history`, JSON.stringify(data));
};

export const MaintenanceToggleApi = (data) => {
  return APIMethod.PUT(`admin/maintenance-switch`);
};