import { call, takeLatest, put } from "@redux-saga/core/effects";

import { AccountDeleteList, AccountDeleteAccept } from "../Api";

import {
  onFail,
  onError,
  onUnAuth,
  onSucces,
  Loader,
} from "../../themes/UTILS/UnAuthFailFn";

import appconstant from "../../themes/appconstant";
const { reduxConstant } = appconstant;

//** Games detail **/
function* AccountDeleteSaga(action) {
  try {
    const data = yield call(AccountDeleteList, action.data);

    //** Success **/
    if (data.status === 0) {
      const obj = {
        type: reduxConstant.ACCOUNT_LIST_SUCCESS,
        data,
        show: false,
      };
      yield call(onSucces, obj);
      yield call(Loader, appconstant.reduxConstant.ON_SUCCESS);
    }
    //** Un Authenticate **/
    else if (data.status === 2) {
      onUnAuth(data?.result?.message);
    }
    //** Fail **/
    else {
      let obj = {
        type: reduxConstant.ACCOUNT_LIST_FAIL,
        message: data?.result?.message,
      };

      yield call(onFail, obj);
      yield call(Loader, appconstant.reduxConstant.ON_SUCCESS);
    }
  } catch (e) {
    //** Un Catch error **/
    yield call(onError, reduxConstant?.ACCOUNT_LIST_FAIL);
    yield call(Loader, appconstant.reduxConstant.ON_FAIL);
  }
}

function* AccountAcceptDeleteSaga(action) {
  try {
    const data = yield call(AccountDeleteAccept, action.data.data);
    //** Success **/
    if (data.status === 0) {
      const obj = {
        type: reduxConstant.ACCOUNT_DELETE_ACCEPT_SUCCESS,
        data,
        show: true,
      };
      yield put({
        type: reduxConstant.ACCOUNT_LIST_LOAD,
        data: action.data.paginationData,
      });
      yield call(onSucces, obj);
      yield call(Loader, appconstant.reduxConstant.ON_SUCCESS);
    }
    //** Un Authenticate **/
    else if (data.status === 2) {
      onUnAuth(data?.result?.message);
    }
    //** Fail **/
    else {
      const obj = {
        type: reduxConstant.ACCOUNT_DELETE_ACCEPT_FAIL,
        message: data?.result?.message,
      };
      yield call(onFail, obj);
      yield call(Loader, appconstant.reduxConstant.ON_FAIL);
    }
  } catch (e) {
    //** Un Catch error **/
    yield call(Loader, appconstant.reduxConstant.ON_FAIL);
    yield call(onError, reduxConstant?.ACCOUNT_DELETE_ACCEPT_FAIL);
  }
}
export default function* rootAccountDeleteSaga() {
  yield takeLatest(reduxConstant.ACCOUNT_LIST_LOAD, AccountDeleteSaga);
  yield takeLatest(
    reduxConstant.ACCOUNT_DELETE_ACCEPT_LOAD,
    AccountAcceptDeleteSaga
  );
}
