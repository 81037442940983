import React, { useEffect, useRef, useState } from "react";
import { DatePicker, Modal, Select } from "antd";
import Textfiled from "./Textfiled";
import { Formik } from "formik";
import moment from "moment";
import appconstant from "../themes/appconstant";
import { blockInvalidChar, CheckFn } from "../themes/UTILS/commonFunc";
import Button from "./Button";
const { Option } = Select;

//on submit the filter or remove the filter
const onFilter = (e, value, onCancel, onOk, buttonClick) => {
  let obj = CheckFn(e);
  if (obj.userName) {
    obj.userName = obj.userName.split(",");
  }
  if (buttonClick === appconstant.Apply) {
    onOk(obj);
  } else {
    value.resetForm();
    onCancel();
  }
};

const commonButtons = (buttonType, state) => {
  return <Button name={buttonType} onClick={() => state(buttonType)} />;
};

export const UserFilter = ({ Visible, onOk, onCancel, setVisibal }) => {
  const [buttonClick, setButtonClick] = useState("");
  const submitFilter = (e, a) => {
    onFilter(e, a, onCancel, onOk, buttonClick);
  };
  return (
    <Modal
      closable={false}
      open={Visible}
      onCancel={onCancel}
      maskClosable={false}
      footer={false}
    >
      <div>
        <h3>Filters</h3>
        <Formik
          initialValues={{
            totalPlayedGames: "",
          }}
          onSubmit={submitFilter}
        >
          {({ values, handleChange, handleSubmit }) => (
            <form
              onSubmit={(e) => {
                e.preventDefault();
                handleSubmit(e);
              }}
              noValidate
            >
              <Textfiled
                type={"number"}
                label={appconstant.totalGamesPlayed}
                name={"totalPlayedGames"}
                value={values.totalPlayedGames}
                onChange={handleChange}
                onKeyDown={blockInvalidChar}
              />
              {/* <Textfiled
                type={"number"}
                label={appconstant.gameswin}
                name={"gamesWin"}
                value={values.gamesWin}
                onChange={handleChange}
                onKeyDown={blockInvalidChar}
              />
              <Textfiled
                type={"number"}
                label={appconstant.gamesLose}
                name={"gamesLose"}
                onKeyDown={blockInvalidChar}
                value={values.gamesLose}
                onChange={handleChange}
              />
              <Textfiled
                type={"number"}
                label={appconstant.gamesDraw}
                name={"gamesDraw"}
                onKeyDown={blockInvalidChar}
                value={values.gamesDraw}
                onChange={handleChange}
              /> */}  
              <div className="ModelFilter">
                {commonButtons(appconstant.Remove, setButtonClick)}
                {commonButtons(appconstant.Apply, setButtonClick)}
              </div>
            </form>
          )}
        </Formik>
      </div>
    </Modal>
  );
};
export const GameFilter = ({ Visible, onOk, onCancel }) => {
  const [buttonClick, setButtonClick] = useState("");
  const submitFilter = (e, a) => {
    onFilter(e, a, onCancel, onOk, buttonClick);
  };
  return (
    <Modal
      closable={false}
      open={Visible}
      onOk={onOk}
      onCancel={onCancel}
      footer={false}
      maskClosable={false}
    >
      <div>
        <h3>Filters</h3>
        <Formik
          initialValues={{
            type: null,
            gameStatus: null,
          }}
          onSubmit={submitFilter}
        >
          {({ handleSubmit, values, setFieldValue }) => (
            <form onSubmit={handleSubmit}>
              <div className="form-group">
                <label>{appconstant.gametype}</label>
                <Select
                  placeholder="Please select game type "
                  style={{
                    width: 120,
                  }}
                  name={"type"}
                  value={values.type}
                  onChange={(e) => setFieldValue("type", e)}
                >
                  <Option title="" value="single">
                    Single
                  </Option>
                  <Option title="" value="tournament">
                    Tournament
                  </Option>
                </Select>
              </div>
              <div className="form-group">
                <label>{appconstant.gamestatus}</label>
                <Select
                  placeholder="Please select game status "
                  style={{
                    width: 120,
                  }}
                  value={values.gameStatus}
                  name={"gameStatus"}
                  onChange={(e) => setFieldValue("gameStatus", e)}
                >
                  <Option title="" value="Draw">
                    Draw
                  </Option>
                  <Option title="" value="Completed">
                    Completed
                  </Option>
                </Select>
              </div>
              <div className="ModelFilter">
                {commonButtons(appconstant.Remove, setButtonClick)}
                {commonButtons(appconstant.Apply, setButtonClick)}
              </div>
            </form>
          )}
        </Formik>
      </div>
    </Modal>
  );
};
export const TournamentFilter = ({ Visible, onOk, onCancel }) => {
  const [buttonClick, setButtonClick] = useState("");
  const submitFilter = (e, a) => {
    onFilter(e, a, onCancel, onOk, buttonClick);
  };
  return (
    <Modal
      closable={false}
      open={Visible}
      onOk={onOk}
      onCancel={onCancel}
      footer={false}
      maskClosable={false}
    >
      <div>
        <h3>Filters</h3>
        <Formik
          initialValues={{
            start: null,
            type: null,
          }}
          onSubmit={submitFilter}
        >
          {({ values, handleChange, handleSubmit, setFieldValue }) => (
            <form onSubmit={handleSubmit}>
              <div className="form-group">
                <label>{appconstant.tournamenttype}</label>
                <Select
                  placeholder="Please select tournament type "
                  style={{
                    width: 120,
                  }}
                  value={values.type}
                  onChange={(e) => setFieldValue("type", e)}
                >
                  <Option title="" value="knockout">
                    Knockout
                  </Option>
                  <Option title="" value="battleground">
                    Battleground
                  </Option>
                </Select>
              </div>
              <div className="form-group">
                <label>Tournament Date</label>
                <DatePicker
                  defaultPickerValue={null}
                  allowClear={false}
                  defaultValue={null}
                  placement={"topLeft"}
                  name={"start"}
                  showToday={false}
                  format={appconstant.DateFormat}
                  value={
                    values.start
                      ? moment(values.start, "YYYY-MM-DDTHH:mm:ss")
                      : null
                  }
                  onChange={(e) =>
                    setFieldValue(
                      "start",
                      moment.utc(e).format("YYYY-MM-DDTHH:mm:ss")
                    )
                  }
                />
              </div>
              <div className="ModelFilter">
                {commonButtons(appconstant.Remove, setButtonClick)}
                {commonButtons(appconstant.Apply, setButtonClick)}
              </div>
            </form>
          )}
        </Formik>
      </div>
    </Modal>
  );
};
export const PayementFilter = ({ Visible, onOk, onCancel, clickButton }) => {
  const [buttonClick, setButtonClick] = useState("");
  const formRef = useRef();
  const submitFilter = (e, a) => {
    const newData = { ...e };
    newData.userName = newData?.userName.toLowerCase();
    onFilter(newData, a, onCancel, onOk, buttonClick);
  };
  useEffect(() => {
    formRef.current?.setFieldValue("userName", "");
    formRef.current?.setFieldValue("createdAt", null);
  }, [clickButton]);
  return (
    <Modal
      closable={false}
      open={Visible}
      onOk={onOk}
      onCancel={onCancel}
      footer={false}
      maskClosable={false}
    >
      <div>
        <h3>Filter</h3>
        <Formik
          initialValues={{
            userName: "",
            createdAt: null,
          }}
          innerRef={formRef}
          onSubmit={submitFilter}
        >
          {({ values, handleChange, handleSubmit, setFieldValue }) => (
            <form onSubmit={handleSubmit}>
              <Textfiled
                type={"text"}
                label={"Username"}
                name={"userName"}
                value={values.userName}
                onChange={(e) => {
                  let data = e.target.value.trimStart();
                  setFieldValue("userName", data);
                }}
              />
              <div className="form-group">
                <label>Transaction Date</label>
                <DatePicker
                  allowClear={false}
                  defaultValue={""}
                  showToday={false}
                  placement={"topLeft"}
                  name={"createdAt"}
                  disabledDate={(current) => {
                    let customDate = moment().add(1,'day').format("YYYY-MM-DD");
                    return current && current > moment(customDate, "YYYY-MM-DD");
                  }} 
                  format={appconstant.DateFormat}
                  value={
                    values.createdAt
                      ? moment(values.createdAt, "YYYY-MM-DDTHH:mm:ss")
                      : null
                  }
                  onChange={(e) =>
                    setFieldValue(
                      "createdAt",
                      moment.utc(e).format("YYYY-MM-DDTHH:mm:ss")
                    )
                  }
                />
              </div>
              <div
                style={{ color: "red", fontSize: "14px", marginBottom: "20px" }}
              >
                * Multiple username can be added by using comma-separated values
                (abc@1,Abc@2).
              </div>
              <div className="ModelFilter">
                {commonButtons(appconstant.Remove, setButtonClick)}
                {commonButtons(appconstant.Apply, setButtonClick)}
              </div>
            </form>
          )}
        </Formik>
      </div>
    </Modal>
  );
};
