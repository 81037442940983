import { put, call, takeLatest } from "@redux-saga/core/effects";
import { toast } from "react-toastify";
import appconstant from "../../themes/appconstant";
import { navigate } from "../../themes/UTILS/dispatchNavigate";
import { Loader } from "../../themes/UTILS/UnAuthFailFn";
import { dashboardApi } from "../Api";

const { reduxConstant, RoutesPath } = appconstant;
const { ON_SUCCESS, ON_FAIL } = reduxConstant;
function* dashboardSaga(action) {
  try {
    let data = yield call(dashboardApi);
    // for success
    if (data.status === 0) {
      yield put({
        type: reduxConstant.DASHBOARD_SUCCESS,
        payload: data.result,
      });
      yield call(Loader, ON_SUCCESS);
    }
    // for unAuth.
    else if (data.status === 2) {
      navigate(RoutesPath.Login);
      localStorage.clear();
      toast.error(data?.result?.message, { toastId: data?.status || "est" });
    }
    // for fail
    else {
      yield put({
        type: reduxConstant.DASHBOARD_FAIL,
      });
      yield call(Loader, ON_FAIL);
      toast.error(data?.result?.message, { toastId: data?.status || "est" });
    }
  } catch (e) {
    yield call(Loader, ON_FAIL);
  }
}

export default function* rootDashboardSaga() {
  yield takeLatest(reduxConstant.DASHBOARD_LOAD, dashboardSaga);
}
