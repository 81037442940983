import React from "react";
import {
  faHome,
  faUser,
  faBell,
  faUnlock,
  faSignOutAlt,
  faGamepad,
  faCreditCard,
  faTrophy,
  faTrash,
  faMap,
  faPlay,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import appconstant from "../themes/appconstant";

const { RoutesPath } = appconstant;

export const ActiveSlide = () => {
  switch (window.location.pathname) {
    case RoutesPath.Dashboard:
      return RoutesPath.Dashboard;
    case RoutesPath.userManagement:
      return RoutesPath.userManagement;
    case RoutesPath.userDetails:
      return RoutesPath.userManagement;
    case RoutesPath.editUserDetails:
      return RoutesPath.userManagement;
    case RoutesPath.gameManagement:
      return RoutesPath.gameManagement;
    case RoutesPath.gameDetail:
      return RoutesPath.gameManagement;
    case RoutesPath.tournamentManagement:
      return RoutesPath.tournamentManagement;
    case RoutesPath.tournamentDetails:
      return RoutesPath.tournamentManagement;
    case RoutesPath.editTournamentDetails:
      return RoutesPath.tournamentManagement;
    case RoutesPath.addTournament:
      return RoutesPath.tournamentManagement;
    case RoutesPath.notificationManagement:
      return RoutesPath.notificationManagement;
    case RoutesPath.addNotification:
      return RoutesPath.notificationManagement;
    case RoutesPath.editNotification:
      return RoutesPath.notificationManagement;
    case RoutesPath.restrictedStateManagement:
      return RoutesPath.restrictedStateManagement;
    case RoutesPath.depositsManagement:
      return RoutesPath.depositsManagement;
    case RoutesPath.withdrawManagement:
      return RoutesPath.depositsManagement;
    case RoutesPath.changePassword:
      return RoutesPath.changePassword;
    case RoutesPath.accountDelete:
      return RoutesPath.accountDelete;
      case RoutesPath.maintenanceManagement:
        return RoutesPath.maintenanceManagement;
    default:
      return RoutesPath.Dashboard;
  }
};

function getItem(label, key, icon, children, type) {
  return {
    icon: <FontAwesomeIcon icon={icon} />,
    key,
    children,
    label: <span className="line_dash">{label}</span>,
    type,
  };
}

///** (label,key,icon,type,children) **/
export const items = [
  getItem(
    appconstant.Dashboard,
    appconstant.RoutesPath.Dashboard,
    faHome,
    "",
    ""
  ),
  getItem(
    appconstant.userManagement,
    appconstant.RoutesPath.userManagement,
    faUser,
    "",
    ""
  ),
  getItem(
    appconstant.maintenanceManagement,
    appconstant.RoutesPath.maintenanceManagement,
    faPlay,
    "",
    ""
  ),
  getItem(
    appconstant.gameManagement,
    appconstant.RoutesPath.gameManagement,
    faGamepad,
    "",
    ""
  ),
  getItem(
    appconstant.accountDelete,
    appconstant.RoutesPath.accountDelete,
    faTrash,
    "",
    ""
  ),
  getItem(
    appconstant.tournamentManagement,
    appconstant.RoutesPath.tournamentManagement,
    faTrophy,
    "",
    ""
  ),
  getItem(
    appconstant.PaymentManagement,
    appconstant.RoutesPath.depositsManagement,
    faCreditCard,
    "",
    ""
  ),
  getItem(
    appconstant.notifcationManagement,
    appconstant.RoutesPath.notificationManagement,
    faBell,
    "",
    ""
  ),
  getItem(
    appconstant.restrictedStateManagement,
    appconstant.RoutesPath.restrictedStateManagement,
    faMap,
    "",
    ""
  ),
  getItem(
    appconstant.changepassword,
    appconstant.RoutesPath.changePassword,
    faUnlock,
    "",
    ""
  ),
  getItem(
    appconstant.Logout,
    appconstant.RoutesPath.Login,
    faSignOutAlt,
    "",
    ""
  ),
];
