import { put, call, takeLatest } from "@redux-saga/core/effects";

import {
  TournamentApi,
  TournamentBlockApi,
  TournamentAddApi,
  TournamentDetailApi,
  TournamentEditApi,
  TournamentMatchesDetailApi,
  MatchTypeListApi,
} from "../Api";

import appconstant from "../../themes/appconstant";
import {
  onFail,
  onError,
  onUnAuth,
  onSucces,
  Loader,
} from "../../themes/UTILS/UnAuthFailFn";
const { reduxConstant } = appconstant;

//** tournament detail **/
function* TournamentSaga(action) {
  try {
    const data = yield call(TournamentApi, action.data);
    //** Success **/
    if (data.status === 0) {
      const obj = {
        type: reduxConstant.TOURNAMENT_SUCCESS,
        data,
        show: false,
      };
      yield call(onSucces, obj);
      yield call(Loader, appconstant.reduxConstant.ON_SUCCESS);
    }
    //** Un Authenticate **/
    else if (data.status === 2) {
      onUnAuth(data?.result?.message);
    }
    //** Fail **/
    else {
      let obj = {
        type: reduxConstant.TOURNAMENT_FAIL,
        message: data?.result?.message,
      };
      yield call(onFail, obj);
      yield call(Loader, appconstant.reduxConstant.ON_FAIL);
    }
  } catch (e) {
    //** Un Catch error **/
    yield call(onError, reduxConstant?.TOURNAMENT_FAIL);
    yield call(Loader, appconstant.reduxConstant.ON_FAIL);
  }
}

//** Block Tournament **/
function* TournamentBlockSaga(action) {
  try {
    const data = yield call(TournamentBlockApi, action.data.id);
    if (data.status === 0) {
      const obj = {
        type: reduxConstant.TOURNAMENT_BLOCK_SUCCESS,
        data,
        show: true,
      };
      yield call(onSucces, obj);
      yield call(Loader, appconstant.reduxConstant.ON_SUCCESS);
      yield put({
        type: reduxConstant.TOURNAMENT_LOAD,
        data: action.data.paginationData,
      });
    } else if (data.status === 2) {
      onUnAuth(data?.result?.message);
    } else {
      let obj = {
        type: reduxConstant.TOURNAMENT_BLOCK_FAIL,
        message: data?.result?.message,
      };
      yield call(onFail, obj);
      yield call(Loader, appconstant.reduxConstant.ON_FAIL);
    }
  } catch (e) {
    yield call(onError, reduxConstant.TOURNAMENT_BLOCK_FAIL);
    yield call(Loader, appconstant.reduxConstant.ON_FAIL);
  }
}

//** Add Tournament **/
function* TournamentAddSaga(action) {
  try {
    const data = yield call(TournamentAddApi, action.data);
    if (data.status === 0) {
      const obj = {
        type: reduxConstant.TOURNAMENT_ADD_SUCCESS,
        data,
        show: true,
        navigationLink: appconstant.RoutesPath.tournamentManagement,
      };
      yield call(onSucces, obj);
      yield call(Loader, appconstant.reduxConstant.ON_SUCCESS);
    } else if (data.status === 2) {
      onUnAuth(data?.result?.message);
    } else {
      let obj = {
        type: reduxConstant.TOURNAMENT_ADD_FAIL,
        message: data?.result?.message,
        show: true,
      };
      yield call(onFail, obj);
      yield call(Loader, appconstant.reduxConstant.ON_FAIL);
    }
  } catch (e) {
    yield call(onError, reduxConstant.TOURNAMENT_ADD_FAIL);
    yield call(Loader, appconstant.reduxConstant.ON_FAIL);
  }
}

//** view  Details **/
function* TournamentDetailsSaga(action) {
  try {
    const data = yield call(TournamentDetailApi, action.data);
    if (data.status === 0) {
      const obj = {
        type: reduxConstant.TOURNAMENT_VIEW_SUCCESS,
        data,
        show: false,
      };
      yield call(onSucces, obj);
      yield call(Loader, appconstant.reduxConstant.ON_SUCCESS);
    } else if (data.status === 2) {
      onUnAuth(data?.result?.message);
    } else {
      let obj = {
        type: reduxConstant.TOURNAMENT_VIEW_FAIL,
        message: data?.result?.message,
      };
      yield call(onFail, obj);
      yield call(Loader, appconstant.reduxConstant.ON_FAIL);
    }
  } catch (e) {
    yield call(onError, reduxConstant.TOURNAMENT_VIEW_FAIL);
    yield call(Loader, appconstant.reduxConstant.ON_FAIL);
  }
}
///Matches
function* TournamentMatchesDetailsSaga(action) {
  try {
    const data = yield call(TournamentMatchesDetailApi, action.data);
    if (data.status === 0) {
      const obj = {
        type: reduxConstant.TOURNAMENT_MATCH_SUCCESS,
        data,
        show: false,
};
      yield call(onSucces, obj);
      yield call(Loader, appconstant.reduxConstant.ON_SUCCESS);
    } else if (data.status === 2) {
      onUnAuth(data?.result?.message);
    } else {
      let obj = {
        type: reduxConstant.TOURNAMENT_MATCH_FAIL,
        message: data?.result?.message,
      };
      yield call(onFail, obj);
      yield call(Loader, appconstant.reduxConstant.ON_FAIL);
    }
  } catch (e) {
    yield call(onError, reduxConstant.TOURNAMENT_MATCH_FAIL);
    yield call(Loader, appconstant.reduxConstant.ON_FAIL);
  }
}

//** Edit  Details **/
function* TournamentEditSaga(action) {
  try {
    const data = yield call(TournamentEditApi, action.data);
    if (data.status === 0) {
      yield call(onSucces, {
        type: reduxConstant.TOURNAMENT_EDIT_SUCCESS,
        data,
        show: true,
        navigationLink: appconstant.RoutesPath.tournamentManagement,
      });
      yield call(Loader, appconstant.reduxConstant.ON_SUCCESS);
    } else if (data.status === 2) {
      onUnAuth(data?.result?.message);
    } else {
      let obj = {
        type: reduxConstant.TOURNAMENT_EDIT_FAIL,
        message: data?.result?.message,
      };
      yield call(onFail, obj);
      yield call(Loader, appconstant.reduxConstant.ON_FAIL);
    }
  } catch (e) {
    yield call(onError, reduxConstant.TOURNAMENT_EDIT_FAIL);
    yield call(Loader, appconstant.reduxConstant.ON_FAIL);
  }
}

//** MATCHES  TYPE LIST **/
function* MatchTypeListSaga(action) {
  try {
    const data = yield call(MatchTypeListApi, action.data);
    if (data.status === 0) {
      yield call(onSucces, {
        type: reduxConstant.TOURNAMENT_MATCH_TYPE_SUCCESS,
        data,
        show:false
      });
    } else if (data.status === 2) {
      onUnAuth(data?.result?.message);
    } else {
      let obj = {
        type: reduxConstant.TOURNAMENT_MATCH_TYPE_FAIL,
        message: data?.result?.message,
      };
      yield call(onFail, obj);
    }
  } catch (e) {
    yield call(onError, reduxConstant.TOURNAMENT_MATCH_TYPE_FAIL);
  }
}
export default function* rootTournamentManagementSaga() {
  yield takeLatest(reduxConstant.TOURNAMENT_LOAD, TournamentSaga);
  yield takeLatest(reduxConstant.TOURNAMENT_BLOCK_LOAD, TournamentBlockSaga);
  yield takeLatest(reduxConstant.TOURNAMENT_ADD_LOAD, TournamentAddSaga);
  yield takeLatest(reduxConstant.TOURNAMENT_VIEW_LOAD, TournamentDetailsSaga);
  yield takeLatest(reduxConstant.TOURNAMENT_EDIT_LOAD, TournamentEditSaga);
  yield takeLatest(reduxConstant.TOURNAMENT_MATCH_TYPE_LOAD, MatchTypeListSaga);
  yield takeLatest(
    reduxConstant.TOURNAMENT_MATCH_LOAD,
    TournamentMatchesDetailsSaga
  );
}
