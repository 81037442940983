import React, { useEffect } from "react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHome } from "@fortawesome/free-solid-svg-icons";

import appconstant from "../../themes/appconstant";
import moment from "moment";
import DisableInputField from "../../common/DisableInputField";
import { BreadcrumbFn } from "../../common/BreadCrumbFn";
import { useSelector } from "react-redux";
import { GameViewDetailAction } from "../../Store/Action/gameManagement";
import { useLocation } from "react-router-dom";
import { dispatch } from "../../themes/UTILS/dispatchNavigate";
import { LoaderAction } from "../../Store/Action/Auth";
import { imgFn, ToUpperCase } from "../../themes/UTILS/commonFunc";
import { getMoves } from "../../themes/JsonFile";

const GameDetails = (props) => {
  const { state } = useLocation();
  const { id, prevPath } = state;
  const { gameDetails } = useSelector((state) => state.GamesReducer);
  const route = prevPath ? "tournamentmanagement" : "gamemanagement"
  const routeName = [
    prevPath ? appconstant.tournamentManagement : appconstant.gameManagement,
    prevPath ? appconstant.tournamentMatchDetails : appconstant.gameDetails,
  ]

  useEffect(() => {
    dispatch(LoaderAction());
    dispatch(GameViewDetailAction(id ?? state));
  }, []);

  return (
    <div>
      <BreadcrumbFn
        path={["dashboard", route]}
        pathName={[
          <FontAwesomeIcon icon={faHome} />,
          ...routeName
        ]}
      />
      <div
        className="site-layout-background"
        style={{ padding: 24, minHeight: 360 }}
      >
        <div className="content-e">
          <div className="bg-gradient-primary shadow-primary border-radius-lg pt-4 pb-3">
            <h6 className="text-white text-capitalize ps-3">
              {appconstant.gameDetails}
            </h6>
          </div>
          <div className="wrapper_line view_modules view">
            <DisableInputField
              label={appconstant.GameId}
              value={gameDetails?.UUID}
            />
            <DisableInputField
              label={appconstant.gametype}
              value={ToUpperCase(gameDetails?.type)}
            />
            {gameDetails?.type === "tournament" && (
              <DisableInputField
                label={appconstant.tournamenttype}
                value={ToUpperCase(gameDetails?.tournamentType)}
              />
            )}
            <DisableInputField
              label={appconstant.firstPlayerName}
              value={ToUpperCase(gameDetails?.firstPLayer)}
            />
            <DisableInputField
              label={appconstant.secondPlayerName}
              value={ToUpperCase(gameDetails?.secondPLayer)}
            />
            <DisableInputField
              label={appconstant.startDate}
              value={moment(gameDetails?.createdAt).format(
                appconstant.DateFormat
              )}
            />
            <DisableInputField
              label={appconstant.starttime}
              value={moment(gameDetails?.startTime).format("h:mm A")}
            />
              <DisableInputField
              label={appconstant.endDate}
              value={moment(gameDetails?.endTime).format(
                appconstant.DateFormat
              )}
            />
            <DisableInputField
              label={appconstant.endtime}
              value={moment(gameDetails?.endTime).format("h:mm A")}
            />
            <DisableInputField
              label={appconstant.gamechoice}
              value={`${gameDetails?.gameChoice / 60} ${appconstant.min}`}
            />
            <DisableInputField
              label={appconstant.amounttype}
              value={ToUpperCase(gameDetails?.moneyType)}
            />
            {gameDetails?.type == "single" &&
              <div style={{ position: "relative" }}>
                <DisableInputField
                  classname={"newpadding"}
                  label={appconstant.buy}
                  value={gameDetails?.betAmount}
                />
                {imgFn(gameDetails?.moneyType)}
              </div>}
            <DisableInputField
              label={appconstant.totalMoves}
              value={gameDetails?.totalMoves}
            />
            <DisableInputField
              label={appconstant.gamestatus}
              value={ToUpperCase(gameDetails?.gameStatus)}
            />
            <DisableInputField
              label={appconstant.winnername}
              value={ToUpperCase(gameDetails?.winner)}
            />
            <div style={{ position: "relative" }}>
              {gameDetails?.type == "single" &&
                <>
                  < DisableInputField
                    classname={"newpadding"}
                    label={appconstant.winneramount}
                    value={gameDetails?.winnerAmount}
                    isDraw={gameDetails?.gameStatus}
                  />
                  {imgFn(gameDetails?.moneyType)}
                </>
              }
              <DisableInputField
                label={appconstant.winDrawCause}
                value={gameDetails?.resultMessage}
              />
            </div>
            <DisableInputField
              label={appconstant.moves}
              value={getMoves(gameDetails?.moves)}
              classname={"TextField"}
            />
          </div>
        </div>
      </div>
    </div>
  );
};
export default GameDetails;
