import React, { useEffect, useState } from "react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHome } from "@fortawesome/free-solid-svg-icons";
import { Table } from "antd";

import appconstant from "../../themes/appconstant";
import { BreadcrumbFn } from "../../common/BreadCrumbFn";
import {
  onPagenationHandelChange,
  SearchBarFn,
} from "../../themes/UTILS/commonFunc";

import { useSelector } from "react-redux";
import { instialData, RequestButtonClick, RequestButtonName } from "../../themes/JsonFile";
import { AcountsTabel, RequestTable } from "../../common/Tabels";
import {
  PaymentAction,
  ResetPaymentAction,
} from "../../Store/Action/PaymentManagement";
import { dispatch } from "../../themes/UTILS/dispatchNavigate";
import { LoaderAction } from "../../Store/Action/Auth";
import { PayementFilter } from "../../common/Filter";
import Button from "../../common/Button";

const DepositsManagement = () => {
  const [IsClick, setIsClick] = useState(false);
  const newdata = { ...instialData };
  const [searchInput, setSearchInput] = useState("");
  const [clickButton, setClick] = useState(appconstant.deposits);
  const requestButtonName = []

  newdata.type = clickButton == appconstant.withdrawRequest ? "Requests":clickButton;

  const [paginationData, setpaginationData] = useState(newdata);
  const { data, paginateData, isLoading } = useSelector(
    (state) => state.PaymentReducer
  );
  const [isDepositClick, setDepositClick] = useState(false);
  const [isWithdrawClick, setWithdrawClick] = useState(false);
  const [isRequestClick,setRequestClick] = useState(false)
  const [FilterValue, setFilterValue] = useState({});
  const newPaginationData = { ...FilterValue, ...paginationData };

  useEffect(() => {
    !isLoading &&
      window.scroll({
        top: 0,
        behavior: "smooth",
      });
  }, [isLoading]);

  useEffect(() => {
    dispatch(LoaderAction());
    let data = paginationData;
    data.type = clickButton == appconstant.withdrawRequest ? "Requests":clickButton;
    if (isDepositClick || isWithdrawClick || isRequestClick) {
      dispatch(PaymentAction(data));
    }
    newdata && setpaginationData(data);
  }, [isDepositClick, isWithdrawClick,isRequestClick]);

  useEffect(() => {
    const newPaginationData = { ...paginationData, ...FilterValue };
    SearchBarFn(
      newPaginationData,
      PaymentAction,
      setpaginationData,
      paginateData?.currentPage,
      searchInput
    );
    return () => {
      dispatch(ResetPaymentAction());
    };
  }, [searchInput]);

  const handleChange = (pagination, filters, sorter) => {
    onPagenationHandelChange(
      pagination,
      sorter,
      newPaginationData,
      setpaginationData,
      PaymentAction
    );
  };

  const SetButtonValue = (a, b,c) => {
    setDepositClick(a);
    setWithdrawClick(b);
    setRequestClick(c)
  };

  const Buttons = (name, className2, classNamediv) => {
    return (
      <div className={classNamediv ? `${classNamediv}` : ""}>
        <Button
          className={className2 ? `${className2} button-list` : name == appconstant.withdrawRequest ? "button-list button-Width" : "button-list"}
          onClick={() => {
            name === appconstant.deposits
              ? SetButtonValue(true, false, false)
              : name === appconstant.withdraw ? SetButtonValue(false, true, false) : SetButtonValue(false, false, true);
            setClick(name);
          }}
          name={name}
        />
      </div>
    );
  };

  return (
    <div>
      <BreadcrumbFn
        path={["dashboard"]}
        pathName={[
          <FontAwesomeIcon icon={faHome} />,
          clickButton === appconstant.deposits
            ? appconstant.depositsManagement
            :clickButton === appconstant.withdrawRequest ?appconstant.withdrawRequestManagement: appconstant.withdrawManagement,
        ]}
      />
      <div
        className="site-layout-background"
        style={{ padding: 24, minHeight: 360 }}
      >
        <div className="content-e">
          <div className="bg-gradient-primary shadow-primary border-radius-lg pt-4 pb-3 system-line">
            <h6 className="text-white text-capitalize ps-3">
              {clickButton === appconstant.deposits
                ? appconstant.depositsManagement
                :clickButton === appconstant.withdrawRequest ?appconstant.withdrawRequestManagement: appconstant.withdrawManagement
                
                }
            </h6>

            <div style={{ display: "flex" }}>
              <Button onClick={() => setIsClick(true)} name={"Filter"} />
              <input
                type="text"
                className="iocn-search  search-bar"
                placeholder="Search"
                value={searchInput}
                onChange={(e) => {
                  setSearchInput(e.target.value.trim());
                }}
              />
            </div>
          </div>

          <div className="flex">
            {Buttons(
              appconstant.deposits,
              clickButton === appconstant.deposits && "btn-col"
            )}
            {Buttons(
              appconstant.withdraw,
              clickButton === appconstant.withdraw && "btn-col",
              "ml-10",
            )}
            {Buttons(
              appconstant.withdrawRequest,
              clickButton === appconstant.withdrawRequest && "btn-col button-Width",
              "ml-5",
            )}
          </div>
          <Table
            rowKey="uid"
            dataSource={data}
            columns={clickButton === appconstant.withdrawRequest ? RequestTable(paginateData?.currentPage,
              RequestButtonName,
              RequestButtonClick,
              IsClick,
              setIsClick,
              paginationData,
              paginateData?.totalCount) : AcountsTabel(paginateData?.currentPage, clickButton,)}
            onChange={handleChange}
            showSorterTooltip={false}
            pagination={{
              current: paginateData?.currentPage ?? 1,
              total: paginateData?.totalCount,
            }}
          />
        </div>
      </div>

      {setIsClick && (
        <PayementFilter
          Visible={IsClick}
          onOk={(e) => {
            const obj = {
              ...paginationData,
              search: searchInput,
              filter: { ...e },
            };
            setFilterValue(obj);
            obj.offset = 0;
            setIsClick(false);
            dispatch(PaymentAction(obj));
          }}
          onCancel={() => {
            let obj = {
              ...instialData,
              search: searchInput,
              filter: {},
              type: newdata.type
            };
            setFilterValue(obj);
            setIsClick(false);
            dispatch(PaymentAction(obj));
          }}
          clickButton={clickButton}
        />
      )}

    </div>
  );
};
export default DepositsManagement;
