import React, { useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHome } from "@fortawesome/free-solid-svg-icons";
import images from "../../themes/appImage";
import { BreadcrumbFn } from "../../common/BreadCrumbFn";
import { useSelector } from "react-redux";
import { dashboardAction } from "../../Store/Action/dashboardAction";
import { LoaderAction } from "../../Store/Action/Auth";
import { dispatch } from "../../themes/UTILS/dispatchNavigate";

const Dashboard = () => {
  const dashboardData = useSelector((state) => state.dashboardReducer);

  const {
    lastMonth,
    lastSixMonth,
    lastThreeMonth,
    lastYear,
    thisWeek,
    totalAdminAmount1Month,
    totalAdminAmount7Days,
    totalAdminAmount24Hrs,
    totalAdminAmountAllTime,
    totalWinAmountAllTime,
    totalWinAmount1Month,
    totalWinAmount7Days,
    totalWinAmount24Hrs,
    last24HoursOnlineUsers,
    onlineUsers,
    onlineGames
  } = dashboardData;

  useEffect(() => {
    dispatch(LoaderAction());
    dispatch(dashboardAction());
  }, []);

  const cardsData = [
    [
      {
        image: images.oUser,
        text: "Current online users.",
        data: onlineUsers,
      },
      {
        image: images.oUser1,
        text: "Online users in last 24 hours.",
        data: last24HoursOnlineUsers,
      },
      {
        image: images.play,
        text: "Current active games.",
        data: onlineGames,
      },
    ],
    
    [
      {
        image: images.dollar,
        text: "Total win money all time.",
        data: `$${totalAdminAmountAllTime}`,
      },
      {
        image: images.dollar1,
        text: "Total win money in 24 hours.",
        data: `$${totalAdminAmount24Hrs}`,
      },
      {
        image: images.dollar2,
        text: "Total win money in 7 days.",
        data: `$${totalAdminAmount7Days}`,
      },
      {
        image: images.dollar3,
        text: "Total win money in 1 month.",
        data: `$${totalAdminAmount1Month}`,
      },
    ],
    [
      {
        image: images.dollar,
        text: "Total win money all time.",
        data: `$${totalWinAmountAllTime}`,
      },
      {
        image: images.dollar1,
        text: "Total win money in 24 hours.",
        data: `$${totalWinAmount24Hrs}`,
      },
      {
        image: images.dollar2,
        text: "Total win money in 7 days.",
        data: `$${totalWinAmount7Days}`,
      },
      {
        image: images.dollar3,
        text: "Total win money in 1 month.",
        data: `$${totalWinAmount1Month}`,
      },
    ],
    [
      {
        image: images.download,
        text: "Total number of users registered this week.",
        data: thisWeek,
      },
      {
        image: images.down,
        text: "Total number of users registered in last month.",
        data: lastMonth,
      },
      {
        image: images.user5,
        text: "Total number of users registered in last 3 months.",
        data: lastThreeMonth,
      },
      {
        image: images.user6,
        text: "Total number of users registered in last 6 months.",
        data: lastSixMonth,
      },
      {
        image: images.user7,
        text: "Total number of users registered in last year.",
        data: lastYear,
      },
    ],
  ];
  const cardsHeader = [
    "Online Users",
    "Total Win Money (Admin)",
    "Total Win Money (User)",
    "Total Number Of Users Registered",
  ];

  return (
    <div>
      <BreadcrumbFn
        className={"dash-crumb"}
        path={[]}
        pathName={[<FontAwesomeIcon icon={faHome} />]}
      />
      {cardsData?.map((item, index) => {
        return (
          <>
            <div className="header_wrap">
              <h1 className="header">{cardsHeader[index]}</h1>
            </div>
            <div className="main-div-2 dashboard ">
              {item.map((data, i) => {
                return (
                  <div className="datacenter user-valid-text">
                    <div className="image-fle">
                      <div className="total-num">
                        <h1>{data.data}</h1>
                      </div>
                      <div>
                        <img
                          className={`img${index * 2 + (i + index * 2)}`}
                          src={data.image}
                          style={{ width: "84px" }}
                          alt=""
                        />
                      </div>
                    </div>
                    <div className="user-text">
                      <p>{data.text}</p>
                    </div>
                  </div>
                );
              })}
            </div>
          </>
        );
      })}
    </div>
  );
};
export default Dashboard;
