import React from "react";
import { CheckValue } from "../themes/UTILS/commonFunc";

//***This input field is use to show data in the view */

const DisableInputField = ({ label, value, classname, isDraw }) => {
  return (
    <div className="fields">
      <label>{label}</label>
      <p className={classname ? `${classname} text` : "text"}>
        {CheckValue(value, isDraw,label)}
      </p>
    </div>
  );
};

export default DisableInputField;
