import React from "react";
import { LoaderAction } from "../Store/Action/Auth";
import appconstant from "../themes/appconstant";
import images from "../themes/appImage";
import {
  time,
  upperCaseFirstChar,
  ToUpperCase,
} from "../themes/UTILS/commonFunc";
import { dispatch } from "../themes/UTILS/dispatchNavigate";
import moment from "moment";

//***********************    THIS FN IS USE TO CREATE THE ACTION BUTTONS IN THE TABLES    *****************************/

const disabelFn = (data, item) =>
  (data.block && item === appconstant.reset ? true : false) ||
    (data.isDelete && item !== appconstant.view)
    ? true
    : false;

const ButtonsFn = (
  ButtonName,
  onButtonClick,
  isClick,
  setIsClick,
  paginationData,
  width = 250,
  totalCount = 0
) => {
  let obj = {
    title: appconstant.Action,
    width: width,
    render: (value, data) => {
      return ButtonName?.map((item, index) => {
        return (
          <button
            style={{
              backgroundColor: disabelFn(data, item) && "#86a56b75",
              cursor: disabelFn(data, item) ? "no-drop" : "pointer",
            }}
            disabled={disabelFn(data, item)}
            type="button"
            className={item === appconstant.reset ? "pass-gen" : ""}
            onClick={() => {
              onButtonClick[index](
                data?._id,
                isClick,
                setIsClick,
                data.block,
                paginationData,
                totalCount,
                item,
                data
              );
            }}>
            {item === appconstant.block
              ? data.block
                ? appconstant.unBlock
                : appconstant.block
              : item}
          </button>
        );
      });
    },
  };
  return obj;
};
const ButtonsFnTournament = (
  ButtonName,
  onButtonClick,
  isClick,
  setIsClick,
  paginationData,
  width = 250,
  totalCount = 0
) => {
  let obj = {
    title: appconstant.Action,
    width: width,
    render: (value, data) => {
      return ButtonName?.map((item, index) => {
        return (
          <button
            style={{
              background:
                (new Date(data.start) === new Date() ||
                  new Date(data.start) < new Date()) &&
                item == "Edit" &&
                "#gray!important",
            }}
            disabled={
              (new Date(data.start) === new Date() ||
                new Date(data.start) < new Date()) &&
                (item == "Edit" || item == "Block")
                ? true
                : false
            }
            type="button"
            className={
              new Date(data.start) === new Date() ||
                new Date(data.start) < new Date()
                ? "disabled-button"
                : ""
            }
            onClick={() => {
              onButtonClick[index](
                data?._id,
                isClick,
                setIsClick,
                data.block,
                paginationData,
                totalCount
              );
            }}>
            {item === appconstant.block
              ? data.block
                ? appconstant.unBlock
                : appconstant.block
              : item}
          </button>
        );
      });
    },
  };
  return obj;
};

//** Sr No function **/

const SrNoFn = (indexNo) => {
  return {
    title: appconstant.srNo,
    dataIndex: "srno",
    key: "srno",
    width: 70,
    sorter: false,
    render: (value, item, index) =>
      indexNo > 1 ? (indexNo - 1) * 10 + (index + 1) : index + 1,
  };
};

//***********************    USER MANAGEMENT TABEL START     *****************************/

export const userTabel = (
  indexNo,
  ButtonName,
  onButtonClick,
  isClick,
  setIsClick,
  paginationData
) => [
    SrNoFn(indexNo),
    {
      title: appconstant.userID,
      dataIndex: "UUID",
      key: "UUID",
      sorter: true,
      width: 220,
    },
    {
      title: appconstant.name,
      dataIndex: "name",
      key: "name",
      sorter: true,
      width: 190,
      ellipsis: true,
    },
    {
      title: appconstant.emailAddress,
      dataIndex: "email",
      key: "email",
      sorter: true,
      width: 190,
      ellipsis: true,
    },
    {
      title: appconstant.username,
      dataIndex: "userName",
      key: "userName",
      sorter: true,
      width: 130,
    },
    {
      title: appconstant.totalGamesPlayed,
      dataIndex: "totalPlayedGames",
      key: "totalPlayedGames",
      sorter: true,
      width: 190,
    },
    {
      title: appconstant.totalRealMoney,
      dataIndex: "totalRealMoney",
      key: "totalRealMoney",
      width: 160,
      sorter: true,
      render: (e) => `$${e.toFixed(2)}`,
    },
    ButtonsFn(
      ButtonName,
      onButtonClick,
      isClick,
      setIsClick,
      paginationData,
      400
    ),
  ];

//***********************    GAME MANAGEMENT TABEL START     *****************************/

export const GameTabel = (
  indexNo,
  ButtonName,
  onButtonClick,
  isClick,
  setIsClick,
  paginationData
) => [
    SrNoFn(indexNo),
    {
      title: appconstant.GameId,
      dataIndex: "UUID",
      key: "_id",
      sorter: true,
      width: 220,
    },
    {
      title: appconstant.player,
      dataIndex: "players",
      key: "players",
      sorter: true,
      width: 190,
      ellipsis: true,
      render: (e) => upperCaseFirstChar(e) || "N/A",
    },
    {
      title: appconstant.gametype,
      dataIndex: "type",
      key: "type",
      sorter: true,
      width: 130,
      render: (a, b) => (
        <span style={{ textTransform: "capitalize" }}>{b?.type}</span>
      ),
    },
    {
      title: appconstant.starttime,
      dataIndex: "startTime",
      key: "startTime",
      width: 130,
      sorter: true,
      render: (item, value) => time(value.startTime, appconstant.TimeFormat),
    },
    {
      title: appconstant.endtime,
      dataIndex: "endTime",
      key: "endTime",
      width: 130,
      sorter: true,
      render: (item, value) => time(value.endTime, "h:mm A"),
    },
    {
      title: appconstant.gamestatus,
      dataIndex: "gameStatus",
      key: "gameStatus",
      sorter: true,
      width: 130,
    },
    ButtonsFn(
      ButtonName,
      onButtonClick,
      isClick,
      setIsClick,
      paginationData,
      150
    ),
  ];

//***********************    TOURNAMENT  MANAGEMENT TABEL START     *****************************/

export const TournamentTabel = (
  indexNo,
  ButtonName,
  onButtonClick,
  isClick,
  setIsClick,
  paginationData
) => [
    SrNoFn(indexNo),
    {
      title: appconstant.tournamentId,
      dataIndex: "UUID",
      key: "_id",
      sorter: true,
      width: 220,
    },
    {
      title: appconstant.tournamentname,
      dataIndex: "name",
      key: "name",
      sorter: true,
      width: 220,
      ellipsis: true,
      render: (e) => upperCaseFirstChar(e),
    },
    {
      title: appconstant.tournamenttype,
      dataIndex: "type",
      key: "type",
      sorter: true,
      width: 160,
      render: (a, b) => (
        <span style={{ textTransform: "capitalize" }}>{b?.type}</span>
      ),
    },
    {
      title: appconstant.tournamentStartDate,
      dataIndex: "start",
      key: "start",
      sorter: true,
      width: 190,
      render: (item, value) => time(value.start, "DD-MM-YYYY"),
    },
    {
      title: appconstant.totalnum,
      dataIndex: "totalPlayers",
      key: "player",
      sorter: true,
      width: 200,
    },
    {
      title: appconstant.cancelledTournament,
      dataIndex: "isCancelled",
      key: "isCancelled",
      sorter: true,
      width: 200,
      render: (a, b) => (<div>{b.isCancelled == true ? "Yes" : "No"}</div>)
    },
    {
      title: appconstant.entryFee,
      dataIndex: "entryFee",
      key: "fee",
      sorter: true,
      width: 200,
      render: (a, b) => (
        <div
          style={{ position: "relative", display: "flex", alignItems: "center" }}>
          <img
            className={b.moneyType === "coins" ? "tabelIcon" : "dollerTabelCoin"}
            src={b.moneyType === "coins" ? images.gameCoin : images.realmoney}
            alt=""
          />
          {b.entryFee}
        </div>
      ),
    },

    ButtonsFnTournament(
      ButtonName,
      onButtonClick,
      isClick,
      setIsClick,
      paginationData
    ),
  ];

//***********************    TOURNAMENT MATCHES MANAGEMENT TABEL START     *****************************/

export const TournamentMatchesTabel = (
  indexNo,
  ButtonName,
  onButtonClick,
  isClick,
  setIsClick,
  paginationData
) => [
    SrNoFn(indexNo),
    {
      title: "Game ID ",
      dataIndex: "UUID",
      key: "type",
      sorter: true,
      width: 220,
    },
    {
      title: "Players",
      dataIndex: "players",
      key: "start",
      sorter: true,
      width: 160,
      ellipsis: true,
      render: (e) => e ?? "N/A",
    },
    {
      title: "Match Status",
      dataIndex: "matchStatus",
      key: "player",
      sorter: true,
      width: 135,
    },
    {
      title: "Winner",
      dataIndex: "winner",
      key: "fee",
      sorter: true,
      width: 110,
      render: (e) => e ?? "N/A",
    },
    ButtonsFn(ButtonName, onButtonClick, isClick, setIsClick, paginationData),
  ];

//***********************    ACCOUNT MANAGEMENT TABEL START     *****************************/

export const AcountsTabel = (indexNo, Acctype) => [
  SrNoFn(indexNo),
  {
    title: "User ID",
    dataIndex: "UUID",
    key: "_id",
    sorter: true,
    width: 220,
  },
  {
    title: "Username",
    dataIndex: "userName",
    key: "userName",
    sorter: true,
    width: 160,
  },
  {
    title: "Date/Time",
    dataIndex: "date",
    key: "date",
    sorter: true,
    width: 160,
    render: (item, value) => time(value.createdAt, appconstant.DateTimeFormat),
  },
  {
    title: `${Acctype === appconstant.deposits ? "Deposit Amount" : "Withdraw Amount"
      }`,
    dataIndex: "amount",
    key: "amount",
    sorter: true,
    width: 160,
    render: (a, b) => (
      <div
        style={{ position: "relative", display: "flex", alignItems: "center" }}>
        <img className="tabelIcon" src={images.realmoney} alt="" />
        {b?.amount}
      </div>
    ),
  },
  {
    title: `${Acctype === appconstant.deposits ? " Transaction ID" : "Email Address"}`,
    dataIndex:`${Acctype === appconstant.deposits ? "transactionId" : "email"}`,
    key:`${Acctype === appconstant.deposits ? "transactionId" : "email"}`,
    sorter: true,
    width: 250,
  },
  {
    title: "  Status",
    dataIndex: "status",
    key: "status",
    sorter: true,
    width: 200,
    render: (e) => ToUpperCase(e),
  },

];


export const RequestTable = (indexNo,
  ButtonName,
  onButtonClick,
  isClick,
  setIsClick,
  paginationData,
  totalCount) => [
    SrNoFn(indexNo),
    {
      title: "User ID",
      dataIndex: "UUID",
      key: "_id",
      sorter: true,
      width: 220,
    },
    {
      title: "Username",
      dataIndex: "userName",
      key: "userName",
      sorter: true,
      width: 160,
    },
    {
      title: "Date/Time",
      dataIndex: "date",
      key: "date",
      sorter: true,
      width: 160,
      render: (item, value) => time(value.createdAt, appconstant.DateTimeFormat),
    },
    {
      title: "Withdraw Amount",
      dataIndex: "amount",
      key: "amount",
      sorter: true,
      width: 160,
      render: (a, b) => (
        <div
          style={{ position: "relative", display: "flex", alignItems: "center" }}>
          <img className="tabelIcon" src={images.realmoney} alt="" />
          {b?.amount}
        </div>
      ),
    },
    {
      title: "Email Address",
      dataIndex: "email",
      key: "email",
      sorter: true,
      width: 250,
    },
    {
      title: "  Status",
      dataIndex: "status",
      key: "status",
      sorter: true,
      width: 200,
      render: (e) => ToUpperCase(e),
    },
    ButtonsFn(
      ButtonName,
      onButtonClick,
      isClick,
      setIsClick,
      paginationData,
      100,
      totalCount
    ),

  ];

export const NotificationTabel = (
  indexNo,
  ButtonName,
  onButtonClick,
  isClick,
  setIsClick,
  paginationData,
  totalCount
) => [
    SrNoFn(indexNo),
    {
      title: "Title  ",
      dataIndex: "title",
      key: "title",
      sorter: true,
      width: 160,
    },
    {
      title: "Description",
      dataIndex: "description",
      key: "description",
      width: 160,
      sorter: true,
    },
    ButtonsFn(
      ButtonName,
      onButtonClick,
      isClick,
      setIsClick,
      paginationData,
      100,
      totalCount
    ),
  ];


export const RestrictedStateTable = (
  indexNo,
  ButtonName,
  onButtonClick,
  isClick,
  setIsClick,
  paginationData,
  totalCount
) => [
    SrNoFn(indexNo),

    {
      title: "State Name",
      dataIndex: "name",
      key: "name",
      sorter: true,
    },
    {
      title: "Date & Time",
      dataIndex: "updatedAt",
      key: "updatedAt",
      width: 200,
      render: (value, item, index) => <div>{moment(item?.updatedAt).format("YYYY-MM-DD HH:mm A")}</div>,
      sorter: true,
    },
    ButtonsFn(
      ButtonName,
      onButtonClick,
      isClick,
      setIsClick,
      paginationData,
      100,
      totalCount
    ),
  ];


export const AccountDeleteTabel = (
  indexNo,
  ButtonName,
  onButtonClick,
  isClick,
  setIsClick,
  paginationData
) => [
    SrNoFn(indexNo),
    {
      title: appconstant.userID,
      dataIndex: "UUID",
      key: "UUID",
      sorter: true,
      width: 220,
    },
    {
      title: appconstant.username,
      dataIndex: "userName",
      key: "userName",
      sorter: true,
      width: 130,
    },
    ButtonsFn(
      ButtonName,
      onButtonClick,
      isClick,
      setIsClick,
      paginationData,
      200
    ),
  ];

export const MaintenanceTable = (
  indexNo,
) => [
    SrNoFn(indexNo),
    {
      title: "Status",
      dataIndex: "isMaintenanceOn",
      key: "isMaintenanceOn",
      sorter: true,
      render: (value, item, index) => <div>{value ? "Maintenance Start" : "Maintenance Stop"}</div>,
    },
    {
      title: "Date & Time",
      dataIndex: "createdAt",
      key: "createdAt",
      width: 200,
      render: (value, item, index) => <div>{moment(item?.createdAt).format("YYYY-MM-DD HH:mm A")}</div>,
      sorter: true,
    },

  ];
