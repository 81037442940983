import { call, put, takeLatest } from "@redux-saga/core/effects";
import {
  MaintenanceListApi, MaintenanceToggleApi,
} from "../Api";

import appconstant from "../../themes/appconstant";
import {
  onFail,
  onError,
  onUnAuth,
  onSucces,
  Loader,
} from "../../themes/UTILS/UnAuthFailFn";
const { reduxConstant } = appconstant;
//** Notification List **/
function* MaintenanceStateSaga(action) {
  try {
    const data = yield call(MaintenanceListApi, action.data);
    //** Success **/
    if (data.status === 0) {
      yield call(onSucces, {
        type: reduxConstant.MAINTENANCE_STATE_LIST_SUCCESS,
        data,
        show: false,
      });

      yield call(Loader, appconstant.reduxConstant.ON_SUCCESS);
    }
    //** Un Authenticate **/
    else if (data.status === 2) {
      onUnAuth(data?.result?.message);
    }
    //** Fail **/
    else {
      yield call(onFail, {
        type: reduxConstant.MAINTENANCE_STATE_FAIL,
        message: data?.result?.message,
        // show: false,
      });
      yield call(Loader, appconstant.reduxConstant.ON_FAIL);
    }
  } catch (e) {
    //** Un Catch error **/
    yield call(onError, reduxConstant?.MAINTENANCE_STATE_FAIL);
    yield call(Loader, appconstant.reduxConstant.ON_FAIL);
  }
}


function* MaintenanceToggleSaga(action) {
  try {
    const data = yield call(MaintenanceToggleApi, action.data);
    console.log(data,"Ca;;ed");

    //** Success **/
    if (data.status === 0) {
      yield call(onSucces, {
        type: reduxConstant.MAINTENANCE_STATE_TOGGLE_SUCCESS,
        data,
        show: false,
      });

      yield call(Loader, appconstant.reduxConstant.ON_SUCCESS);
    }
    //** Un Authenticate **/
    else if (data.status === 2) {
      onUnAuth(data?.result?.message);
    }
    //** Fail **/
    else {
      yield call(onFail, {
        type: reduxConstant.MAINTENANCE_STATE_FAIL,
        message: data?.result?.message,
        // show: false,
      });
      yield call(Loader, appconstant.reduxConstant.ON_FAIL);
    }
  } catch (e) {
    //** Un Catch error **/
    yield call(onError, reduxConstant?.MAINTENANCE_STATE_FAIL);
    yield call(Loader, appconstant.reduxConstant.ON_FAIL);
  }
}


export default function* rootMaintenanceStateSaga() {
  yield takeLatest(reduxConstant.MAINTENANCE_STATE_LIST_LOAD, MaintenanceStateSaga);
  yield takeLatest(reduxConstant.MAINTENANCE_STATE_TOGGLE_LOAD, MaintenanceToggleSaga);

 
}
