import React, { useEffect, useState } from "react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHome } from "@fortawesome/free-solid-svg-icons";
import { Table } from "antd";
import { BreadcrumbFn } from "../../common/BreadCrumbFn";
import appconstant from "../../themes/appconstant";
import { TournamentTabel } from "../../common/Tabels";
import {
  instialData,
  TournamentTabelButtonName,
  TouurnamentTabelButtonClick,
} from "../../themes/JsonFile";
import {
  onPagenationHandelChange,
  SearchBarFn,
} from "../../themes/UTILS/commonFunc";
import { useSelector } from "react-redux";
import {
  TournamentAction,
  TournamentResetAction,
} from "../../Store/Action/tournamentManagement";
import { dispatch, navigate } from "../../themes/UTILS/dispatchNavigate";
import ModalPop, { AddTournamentModal } from "../../common/modal";
import { LoaderAction } from "../../Store/Action/Auth";

import { TournamentFilter } from "../../common/Filter";
import Button from "../../common/Button";
const TournamentManagement = () => {
  const [searchInput, setSearchInput] = useState("");
  const [FilterClick, SetFilterClick] = useState(false);
  const [addTournamentClick, setAddTournamentClick] = useState(false);

  const [isVisible, setisVisible] = useState(false);
  const [paginationData, setpaginationData] = useState(instialData);
  const { paginateData, data, isLoading } = useSelector(
    (state) => state.TournamentReducer
  );
  const [isClick, setIsClick] = useState(false);
  const [FilterValue, setFilterValue] = useState({});
  useEffect(() => {
    !isLoading &&
      window.scroll({
        top: 0,
        behavior: "smooth",
      });
  }, [isLoading]);
  useEffect(() => {
    const newPaginationData = { ...paginationData, ...FilterValue };
    dispatch(LoaderAction());
    SearchBarFn(
      newPaginationData,
      TournamentAction,
      setpaginationData,
      paginateData?.currentPage,
      searchInput
    );
    return () => {
      dispatch(TournamentResetAction());
    };
  }, [searchInput]);

  const handleChange = (pagination, filters, sorter) => {
    const newPaginationData = { ...paginationData, ...FilterValue };
    onPagenationHandelChange(
      pagination,
      sorter,
      newPaginationData,
      setpaginationData,
      TournamentAction
    );
  };

  const HandelClick = (type) => {
  
    setisVisible(false);
    navigate(appconstant.RoutesPath.addTournament, {
      state: type,
    });
    window.location.reload()
  };

  return (
    <div>
      <BreadcrumbFn
        path={["dashboard"]}
        pathName={[
          <FontAwesomeIcon icon={faHome} />,
          appconstant.tournamentManagement,
        ]}
      />
      <div
        className="site-layout-background"
        style={{ padding: 24, minHeight: 360 }}
      >
        <div className="content-e">
          <div className="bg-gradient-primary shadow-primary border-radius-lg pt-4 pb-3 system-line">
            <h6 className="text-white text-capitalize ps-3">
              {appconstant.tournamentManagement}
            </h6>
            <div style={{ display: "flex" }}>
              <Button name={"Filter"} onClick={() => SetFilterClick(true)} />
              <input
                type="text"
                className="iocn-search  search-bar"
                placeholder="Search"
                value={searchInput}
                onChange={(e) => {
                  setSearchInput(e.target.value.trimStart());
                }}
              />
            </div>
          </div>
          <div>
            <Button
              className="button-list addturna"
              onClick={() =>
                setAddTournamentClick(true)
              }
              name={appconstant.addtournament}
            />
          </div>
          <Table
            rowKey="uid"
            dataSource={data}
            columns={TournamentTabel(
              paginateData?.currentPage,
              TournamentTabelButtonName,
              TouurnamentTabelButtonClick,
              isClick,
              setIsClick,
              paginationData
            )}
            onChange={handleChange}
            showSorterTooltip={false}
            pagination={{
              current: paginateData?.currentPage ?? 1,
              total: paginateData?.totalCount,
            }}
          />
        </div>
        {setIsClick && (
          <TournamentFilter
            Visible={FilterClick}
            onOk={(e) => {
              const obj = {
                ...paginationData,
                search: searchInput,
                filter: { ...e },
              };
              setFilterValue(obj);
              obj.offset = 0;
              dispatch(TournamentAction(obj));
              SetFilterClick(false);
            }}
            onCancel={() => {
              let obj = {
                ...instialData,
                search: searchInput,
                filter: {},
              };
              setFilterValue(obj);
              dispatch(TournamentAction(obj));
              SetFilterClick(false);
            }}
          />
        )}
        {
          addTournamentClick && (
            <AddTournamentModal
              Visible={addTournamentClick}
              onCancel={() => setAddTournamentClick(false)}
              onOk={HandelClick}
            />)
        }
      </div>
    </div>
  );
};
export default TournamentManagement;
