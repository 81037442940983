import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHome } from "@fortawesome/free-solid-svg-icons";
import { Table } from "antd";

import appconstant from "../../themes/appconstant";
import { BreadcrumbFn } from "../../common/BreadCrumbFn";
import { dispatch, navigate } from "../../themes/UTILS/dispatchNavigate";
import { LoaderAction } from "../../Store/Action/Auth";
import { MaintenanceTable, RestrictedStateTable } from "../../common/Tabels";
import { useSelector } from "react-redux";
import {
  onPagenationHandelChange,
  SearchBarFn,
} from "../../themes/UTILS/commonFunc";
import {
  instialData,
  RestrictedStateButtonClick,
  RestrictedStateButtonName,
} from "../../themes/JsonFile";
import {
  ResetRestrictedStateAction,
} from "../../Store/Action/RestrictedStateAction";
import { MaintenanceAction, enableGame } from "../../Store/Action/maintenanceManagement";
import { ToggleButton } from "@mui/material";
import Button from "../../common/Button";

const MaintenanceManagement = () => {
  const [searchInput, setSearchInput] = useState("");
  const [paginationData, setpaginationData] = useState(instialData);
  const { data, paginateData, isLoading, isMaintenanceOn } = useSelector(
    (state) => state.MaintenanceStateReducer
  );

  useEffect(() => {
    !isLoading &&
      window.scroll({
        top: 0,
        behavior: "smooth",
      });

  }, [isLoading]);




  useEffect(() => {
    dispatch(MaintenanceAction({
      "offset": 0,
      "limit": 10
    }))

  }, [isMaintenanceOn]);

  const handleChange = (pagination, filters, sorter) => {
    onPagenationHandelChange(
      pagination,
      sorter,
      paginationData,
      setpaginationData,
      MaintenanceAction
    );
  };
  return (
    <div>
      <BreadcrumbFn
        path={["dashboard"]}
        pathName={[
          <FontAwesomeIcon icon={faHome} />,
          appconstant.maintenanceManagement,
        ]}
      />
      <div
        className="site-layout-background"
        style={{ padding: 24, minHeight: 360 }}
      >
        <div className="content-e">
          <div className="bg-gradient-primary shadow-primary border-radius-lg pt-4 pb-3 system-line">
            <h6 className="text-white text-capitalize ps-3">
              {appconstant.maintenanceManagement}
            </h6>
            <Button
              onClick={() => dispatch(enableGame())}
              name={!isMaintenanceOn ? appconstant.enabel : appconstant.disable}
            />
          </div>

          <Table
            rowKey="uid"
            dataSource={data}
            columns={MaintenanceTable(
              paginateData?.currentPage,
              RestrictedStateButtonName,
              RestrictedStateButtonClick,
              paginationData,
              paginateData?.totalCount
            )}
            onChange={handleChange}
            showSorterTooltip={false}
            pagination={{
              current: paginateData?.currentPage ?? 1,
              total: paginateData?.totalCount,
            }}
          />
        </div>
      </div>
    </div>
  );
};
export default MaintenanceManagement;
