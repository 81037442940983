import appconstant from "../../themes/appconstant";
const { reduxConstant } = appconstant;
const instialState = {
  isLoading: false,
  data: [],
  userDetails: {},
  paginateData: {},
  MainLoader: false,
};
export const UsersReducer = (state = instialState, action) => {
  switch (action.type) {
    //Tabels data
    case reduxConstant.ON_LOAD:
      return {
        ...state,
        MainLoader: true,
      };
    case reduxConstant.ON_SUCCESS:
      return {
        ...state,
        MainLoader: false,
      };
    case reduxConstant.ON_FAIL:
      return {
        ...state,
        MainLoader: false,
      };
    case reduxConstant.REMOVE_LOADER:
      return {
        ...state,
        MainLoader: false,
      };

    case reduxConstant.USER_LOAD:
      return {
        ...state,
        isLoading: true,
      };
    case reduxConstant.USER_SUCCESS:
      return {
        ...state,
        isLoading: false,
        data: action.payload.data,
        paginateData: action.payload.pagination,
      };
    case reduxConstant.USER_FAIL:
      return {
        ...state,
        isLoading: false,
        data: [],
      };
    case reduxConstant.USER_BLOCK_LOAD:
      return {
        ...state,
        isLoading: true,
      };
    case reduxConstant.USER_BLOCK_SUCCESS:
      return {
        ...state,
        isLoading: false,
      };
    case reduxConstant.USER_BLOCK_FAIL:
      return {
        ...state,
        isLoading: false,
      };
    case reduxConstant.USER_VIEW_LOAD:
      return {
        ...state,
        isLoading: true,
      };
    case reduxConstant.USER_VIEW_SUCCESS:
      return {
        ...state,
        isLoading: false,
        userDetails: action.payload.data,
      };
    case reduxConstant.USER_VIEW_FAIL:
      return {
        ...state,
        isLoading: false,
      };
    case reduxConstant.USER_EDIT_LOAD:
      return {
        ...state,
        isLoading: true,
      };
    case reduxConstant.USER_EDIT_SUCCESS:
      return {
        ...state,
        isLoading: false,
      };
    case reduxConstant.USER_EDIT_FAIL:
      return {
        ...state,
        isLoading: false,
      };
    case reduxConstant.RESET:
      return { ...state, paginateData: {} };
    default:
      return state;
  }
};
