import appconstant from "../../themes/appconstant";
const { reduxConstant } = appconstant;

export const PaymentAction = (data) => ({
  type: reduxConstant.PAYMENT_INFO_LOAD,
  data,
});
export const ResetPaymentAction = (data) => ({
  type: reduxConstant.PAYMENT_RESET
});


export const acceptWithDrawAction= (data)=>({
  type:reduxConstant.Payment_Request_Load,
  data
})