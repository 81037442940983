import appconstant from "../../themes/appconstant";
const { reduxConstant } = appconstant;
export const MaintenanceAction = (data) => ({
  type: reduxConstant.MAINTENANCE_STATE_LIST_LOAD,
  data,
});
export const enableGame = (data) => ({
  type: reduxConstant.MAINTENANCE_STATE_TOGGLE_LOAD,
  data,
});

