import React, { useEffect, useState } from "react";
import { TimePicker, DatePicker, Select, Radio } from "antd";
import { useLocation } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHome } from "@fortawesome/free-solid-svg-icons";
import appconstant from "../../themes/appconstant";
import Textfiled from "../../common/Textfiled";
import { BreadcrumbFn } from "../../common/BreadCrumbFn";
import { dispatch } from "../../themes/UTILS/dispatchNavigate";
import { Formik } from "formik";
import {
  MatchTypeListAction,
  TournamentAddAction,
} from "../../Store/Action/tournamentManagement";
import { useSelector } from "react-redux";
import {
  getAdminPrice,
  getPoolPrice,
  TournamentAddData,
} from "../../themes/JsonFile";
import moment from "moment";
import {
  blockInvalidChar,
  CheckFn,
  helperText,
  imgFn,
  IsError,
  TournamentAddvalidationSchema,
} from "../../themes/UTILS/commonFunc";
import Button from "../../common/Button";
import { toast } from "react-toastify";

const { Option } = Select;

const AddTournament = () => {
  const { state } = useLocation();
  const { MatchType } = useSelector((state) => state.TournamentReducer);

  const [RadioValue, setRadioValue] = useState("bullet");

  const arr = MatchType[RadioValue]?.sort(function (a, b) { return a.duration - b.duration });
  const matchType = arr?.filter(
    (e, i) => e?.duration !== arr[i + 1]?.duration
  )


  useEffect(() => {
    if (state === appconstant.knockout) {
      TournamentAddData.showPricepool = true;
    }
    dispatch(MatchTypeListAction());
  }, []);

  const onFormSubmit = (e) => {
    let newData = CheckFn(e);
    let date;
    if (e.startDate && e.startTime) {
      date = `${moment(e?.startDate).format("YYYY-MM-DD")}T${moment(
        e?.startTime
      ).format("HH:mm:ss")}`;

      newData.start = moment(date)._d;
    }
    if (e.endDate && e.endTime) {
      date = `${moment(e?.endDate).format("YYYY-MM-DD")}T${moment(
        e?.endTime
      ).format("HH:mm:ss")}`;

      newData.end = moment(date)._d;
    }
    newData.type = state.toLowerCase();
    console.log(state, e.moneyType, newData.entryFee, "e.moneyType");
    if (state === appconstant.knockout) {
      if (e.moneyType === "real") {
        if (newData.entryFee  >= 25) {
          newData.pricePool = (((newData.entryFee * 8) / 100) * 90).toFixed(2);
        } else {
          newData.pricePool = (((newData.entryFee * 8) / 100) * 85).toFixed(2);
        }
      } else {
        newData.pricePool = (newData.entryFee * 8).toFixed(2);
      }
    }

    if (e.moneyType === "real" && state === appconstant.knockout) {
      if (newData.entryFee  >= 25) {
        newData.adminCommission = (((newData.entryFee * 8) / 100) * 10).toFixed(
          2
        );
      } else {
        newData.adminCommission = (((newData.entryFee * 8) / 100) * 15).toFixed(
          2
        );
      }
    }

    if (
      (+e.timeInterval == 0 && +e.totalTournament != 0) ||
      (+e.timeInterval != 0 && +e.totalTournament == 0)
    ) {
      return +e.timeInterval
        ? toast.error("The total tournament should be at least 1.")
        : toast.error("The time interval should be at least 1.");
    } else {
      dispatch(TournamentAddAction(newData));
    }
  };

  return (
    <div>
      <BreadcrumbFn
        path={["dashboard", "tournamentmanagement"]}
        pathName={[
          <FontAwesomeIcon icon={faHome} />,
          appconstant.tournamentManagement,
          appconstant.addtournament,
        ]}
      />

      <div
        className="site-layout-background"
        style={{ padding: 24, minHeight: 360 }}>
        <div className="content-e">
          <div className="bg-gradient-primary shadow-primary border-radius-lg pt-4 pb-3">
            <h6 className="text-white text-capitalize ps-3">
              {appconstant.addtournament}
            </h6>
          </div>

          <Formik
            initialValues={TournamentAddData}
            onSubmit={onFormSubmit}
            validationSchema={TournamentAddvalidationSchema}>
            {({
              handleBlur,
              handleSubmit,
              errors,
              values,
              touched,
              setFieldValue,
            }) => (
              <form onSubmit={handleSubmit}>
                <div className="wrapper_line view_modules view">
                  <Textfiled
                    label={appconstant.tournamentname}
                    onChange={(e) => {
                      let data = e.target.value.trimStart();
                      setFieldValue("name", data);
                    }}
                    onBlur={handleBlur}
                    error={IsError(errors, touched, "name")}
                    helperText={helperText(errors, touched, "name")}
                  />
                  <Textfiled
                    label={appconstant.tournamenttype}
                    disabled={true}
                    value={state}
                  />
                  <Textfiled
                    label={appconstant.totalnum}
                    disabled={true}
                    value={8}
                  />
                  <div className="form-group">
                    <label>{appconstant.tournamentStartDate}</label>
                    <DatePicker
                      disabledDate={(c) => {
                        let date = new Date(c._d);
                        date.setDate(date.getDate() + 1);
                        return date < moment().endOf("day");
                      }}
                      showToday={false}
                      name={"startDate"}
                      format={appconstant.DateFormat}
                      placeholder="Tournament Start Date"
                      onChange={(e, i) => {
                        setFieldValue("startDate", e._d);
                        setFieldValue("endDate", moment(e._d).add(1, "days"));
                      }}
                      value={moment(values.startDate)}
                      onBlur={handleBlur}
                    />
                  </div>
                  <div className="form-group">
                    <label>{appconstant.tournamentStartTime}</label>
                    <TimePicker
                      showNow={false}
                      name="startTime"
                      use12Hours
                      format={appconstant.TimeFormat}
                      placeholder="Tournament Start Time"
                      onChange={(e) => {
                        setFieldValue("startTime", e._d);
                      }}
                      value={moment(values.startTime)}
                      onBlur={handleBlur}
                    />
                  </div>
                  {state === appconstant.Battleground && (
                    <div>
                      <div className="form-group">
                        <label>{appconstant.tournamentEndDate}</label>
                        <DatePicker
                          disabledDate={(c) => {
                            let date = new Date(c._d);
                            date.setDate(date.getDate() + 1);
                            return date < moment().endOf("day");
                          }}
                          showToday={false}
                          name={"endDate"}
                          format={appconstant.DateFormat}
                          placeholder="Tournament End Date"
                          onChange={(e, i) => {
                            setFieldValue("endDate", e._d);
                          }}
                          value={moment(values.endDate)}
                          onBlur={handleBlur}
                        />
                      </div>

                      <div className="form-group">
                        <label>{appconstant.tournamentEndTime}</label>
                        <div>
                          <TimePicker
                            showNow={false}
                            defaultValue={new Date().getTime()}
                            name="endTime"
                            use12Hours
                            format={appconstant.TimeFormat}
                            onChange={(e) => {
                              setFieldValue("endTime", e._d);
                            }}
                            value={moment(values.endTime)}
                            onBlur={handleBlur}
                            placeholder="Tournament End Time"
                          />
                        </div>
                      </div>
                    </div>
                  )}

                  <div className="form-group">
                    <label>{appconstant.timeForEachMatch}</label>
                    <Radio.Group
                      style={{
                        display: "flex",
                        justifyContent: "flex-start",
                        margin: "10px 0",
                      }}
                      defaultValue={0}>
                      {Object.entries(MatchType)?.map(([key, value], index) => {
                        return (
                          <div>
                            <Radio
                              className="DropDown"
                              style={{
                                textTransform: "capitalize",
                                width: "auto",
                                marginRight: "20px",
                              }}
                              onClick={() => {
                                setRadioValue(key);
                                setFieldValue("matchType", null);
                              }}
                              value={index}>
                              {key}
                            </Radio>
                          </div>
                        );
                      })}
                    </Radio.Group>
                    {imgFn(values.moneyType)}
                    <Select
                      defaultActiveFirstOption={true}
                      status={errors.matchType && touched.matchType && "error"}
                      placeholder={"Please select Time"}
                      className={"selectBox"}
                      style={{
                        margin: "10px 0",
                      }}
                      name={"matchType"}
                      onChange={(e) => {
                        setFieldValue("matchType", e);
                      }}
                      value={values.matchType}
                      onBlur={handleBlur}>
                      {matchType &&
                        matchType?.map((item, index) => (
                          <Option title="" value={item?._id}>
                            <div>
                              {/* <span className="coins">
                                {imgFn(values.moneyType)}
                              </span> */}
                              <span style={{ marginLeft: "5px" }}>
                                {/* {`${Math.abs(item.coins)} `} */}
                                {`${item?.duration / 60} min`}
                              </span>
                            </div>
                          </Option>
                        ))}
                    </Select>
                    <span style={{ color: "red", fontSize: "15px" }}>
                      {touched.matchType && errors.matchType}
                    </span>
                  </div>
                  <div style={{ marginBottom: "15px" }}>
                    <label>{appconstant.amounttype}</label>
                    <Radio.Group
                      style={{
                        display: "flex",
                        justifyContent: "flex-start",
                        margin: "10px 0",
                      }}
                      value={values.moneyType}
                      onChange={(e) =>
                        setFieldValue("moneyType", e.target.value)
                      }>
                      <Radio title="" value={"coins"}>
                        {"Coins"}
                      </Radio>
                      <Radio title="" value={"real"}>
                        {"Real"}
                      </Radio>
                    </Radio.Group>
                  </div>
                  <div style={{ position: "relative" }}>
                    {imgFn(values.moneyType)}
                    <Textfiled
                      className={"padding"}
                      onKeyDown={blockInvalidChar}
                      label={appconstant.entryFee}
                      value={values.entryFee}
                      type={"number"}
                      onChange={(e) => {
                        let data = e.target.value.trimStart();
                        setFieldValue("entryFee", data);
                      }}
                      onBlur={handleBlur}
                      error={IsError(errors, touched, "entryFee")}
                      helperText={helperText(errors, touched, "entryFee")}
                    />
                  </div>

                  {state === appconstant.knockout && (
                    <Textfiled
                      onKeyDown={blockInvalidChar}
                      label={appconstant.poolPrice}
                      type={"number"}
                      disabled={true}
                      value={getPoolPrice(values.entryFee, values.moneyType)}
                    />
                  )}

                  {state === appconstant.knockout &&
                    values?.moneyType === "real" && (
                      <Textfiled
                        onKeyDown={blockInvalidChar}
                        label={"Admin commision"}
                        type={"number"}
                        disabled={true}
                        value={getAdminPrice(values.entryFee, values.moneyType)}
                      />
                    )}
                  <Textfiled
                    label={appconstant.timeInterval + " (Min)"}
                    onKeyDown={blockInvalidChar}
                    type={"number"}
                    value={values.timeInterval}
                    onChange={(e) => {
                      let data = e.target.value.trimStart();
                      setFieldValue("timeInterval", data);
                    }}
                    onBlur={handleBlur}
                    error={IsError(errors, touched, "timeInterval")}
                    helperText={helperText(errors, touched, "timeInterval")}
                  />
                  <Textfiled
                    label={appconstant.totalTournaments}
                    onKeyDown={blockInvalidChar}
                    type={"number"}
                    value={values.totalTournament}
                    onChange={(e) => {
                      let data = e.target.value.trimStart();
                      setFieldValue("totalTournament", data);
                    }}
                    onBlur={handleBlur}
                    error={IsError(errors, touched, "totalTournament")}
                    helperText={helperText(errors, touched, "totalTournament")}
                  />
                </div>
                <div className="up-btn" style={{ paddingBottom: "30px" }}>
                  <Button type="submit" name={appconstant.add} />
                </div>
              </form>
            )}
          </Formik>
        </div>
      </div>
    </div>
  );
};
export default AddTournament;
