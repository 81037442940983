import login from "../images/Frame 4.png";
import login1 from "../images/logo-login.png";
import download from "../images/user1.png";
import down from "../images/user5.png";
import login2 from "../images/slider.png";
import user5 from "../images/user2.png";
import user6 from "../images/user3.png";
import user7 from "../images/user4.png";
import gameCoin from "../images/gameCoin.png";
import realmoney from "../images/realmoney.png";
import dollar from "../images/$.png"
import dollar1 from "../images/$1.png"
import dollar2 from "../images/$2.png"
import dollar3 from "../images/$3.png"
import oUser from "../images/oUser.png"
import oUser1 from "../images/oUser1.png"
import play from "../images/play.png"


const images = {
  login: login,
  login1: login1,
  download: download,
  down: down,
  login2: login2,
  user5: user5,
  user6: user6,
  user7: user7,
  realmoney: realmoney,
  gameCoin: gameCoin,
  dollar: dollar,
  dollar1: dollar1,
  dollar2: dollar2,
  dollar3: dollar3,
  oUser: oUser,
  oUser1: oUser1,
  play:play
};

export default images;
