import appconstant from "../../themes/appconstant";
const { reduxConstant } = appconstant;
const instialState = {
  isLoading: false,
  data: [],
  paginateData: {},
  isMaintenanceOn:false
};

export const MaintenanceStateReducer = (state = instialState, action) => {
  switch (action.type) {
   
    case reduxConstant.MAINTENANCE_STATE_LIST_LOAD:
      return {...state};
    case reduxConstant.MAINTENANCE_STATE_LIST_SUCCESS:
      return {
        ...state,
        isLoading: false,
        data: action.payload.data.history,
        paginateData: action.payload.pagination,
        isMaintenanceOn:action.payload.data.isMaintenanceOn
      };

      case reduxConstant.MAINTENANCE_STATE_TOGGLE_LOAD:
        return {...state};
      case reduxConstant.MAINTENANCE_STATE_TOGGLE_SUCCESS:
       let temp = state.isMaintenanceOn ? false :true
        return {
          ...state,
          isLoading: false,
          isMaintenanceOn: temp
        };
   
    default:
      return state;
  }
};
