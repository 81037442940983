import React, { useEffect, useState } from "react";
import { Layout, Menu } from "antd";
import images from "../themes/appImage";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleLeft, faAngleRight } from "@fortawesome/free-solid-svg-icons";
import appconstant from "../themes/appconstant";
import { ActiveSlide, items } from "../common/SidebarFn";
import { dispatch, navigate } from "../themes/UTILS/dispatchNavigate";
import { resetStore } from "../Store/Action/Auth";
import { toast } from "react-toastify";
import { useLocation } from "react-router-dom";

const { Sider } = Layout;

const Sidebar = (props) => {
  const [activeKey, setActiveKey] = useState("");
  const { state } = useLocation();

  useEffect(() => {
    setActiveSlied();
  }, [window.location.pathname]);

  const onMenuClick = (e) => {
    navigate(e.key);
    setActiveSlied();
    if (e.key === "/login") {
      dispatch(resetStore());
      localStorage.clear();
      toast.success("Logged out successfully.", { toastId: "1" });
    }
  };

  function setActiveSlied() {
    let a = ActiveSlide();
    state?.prevPath ?
      setActiveKey(appconstant.RoutesPath.tournamentManagement) :
      setActiveKey(a);
  }

  return (
    <Sider
      className={props.isVisible ? "sider_left new closedd" : "sider_left new"}
      collapsedWidth="0"
      width={props.isVisible ? "94" : "300"}
    >
      <div className="logo">
        <img
          src={images.login}
          onClick={() => {
            navigate(appconstant.RoutesPath.Dashboard);
          }}
          alt=""
        />
      </div>
      <div className="admin-text">
        <h2>Admin Panel</h2>
      </div>
      <Menu
        theme="dark"
        mode="inline"
        defaultOpenKeys={[activeKey]}
        selectedKeys={[activeKey]}
        onClick={(e) => onMenuClick(e)}
        items={items}
      />
      <div className="angle-btn">
        <FontAwesomeIcon
          onClick={props.handlewClick}
          className="icon-angle-left btn"
          icon={props.isVisible ? faAngleRight : faAngleLeft}
        />
      </div>
    </Sider>
  );
};
export default Sidebar;
