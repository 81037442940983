import { put, call, takeLatest } from "@redux-saga/core/effects";
import { toast } from "react-toastify";
import { socket } from "../../App";
import appconstant from "../../themes/appconstant";
import { navigate } from "../../themes/UTILS/dispatchNavigate";

import {
  onFail,
  onError,
  Loader,
  onSucces,
  onUnAuth,
} from "../../themes/UTILS/UnAuthFailFn";

import {
  LoginApi,
  ForgotPasswordApi,
  checkLinkApi,
  ResetPasswordApi,
  changePasswordApi,
} from "../Api";

const { reduxConstant, RoutesPath } = appconstant;

function* LoginSaga(action) {
  try {
    const data = yield call(LoginApi, action.data);
    // for success
    if (data.status === 0) {
      navigate(RoutesPath.Dashboard);
      socket.emit("adminLogin", { adminId: data.result.data._id });
      localStorage.setItem("adminId", data.result.data._id);
      yield put({
        type: reduxConstant.LOGIN_SUCCESS,
        payload: data.result,
      });
      toast.success(data.result.message, { toastId: "1" });
    }
    // for fail
    else {
      let obj = {
        type: reduxConstant.LOGIN_FAIL,
        message: data?.result?.message,
      };
      yield call(onFail, obj);
    }
  } catch (e) {
    //if some thing went wrong from api side
    yield call(onError);
  }
}

function* forgotPasswordSaga(action) {
  try {
    const data = yield call(ForgotPasswordApi, action.data);
    // for success
    if (data.status === 0) {
      yield put({
        type: reduxConstant.FORGOTPASSWORD_SUCCESS,
        payload: data.result,
      });
      navigate(RoutesPath.Login);
      toast.success(data.result.message, { toastId: data?.status || "abc" });
    }

    // for fail
    else {
      yield put({
        type: reduxConstant.FORGOTPASSWORD_FAIL,
      });
      toast.error(data?.result?.message, { toastId: data?.status || "est" });
    }
  } catch (e) {
    //if some thing went wrong from api side
  }
}

//check link saga
function* checkLinkSaga(action) {
  try {
    const data = yield call(checkLinkApi, action.data);
    // for success

    if (data.status === 0) {
      yield put({
        type: reduxConstant.CHECK_LINK_SUCCESS,
        payload: data.result,
      });
    }
    // for fail
    else {
      yield put({
        type: reduxConstant.CHECK_LINK_FAIL,
      });
    }
  } catch (e) { }
}

//reset password
function* resetPasswordSaga(action) {
  try {
    const data = yield call(ResetPasswordApi, action.data, action.id);
    // for success
    if (data.status === 0) {
      yield put({
        type: reduxConstant.RESET_PASSWORD_SUCCESS,
        payload: data.result,
      });
      navigate(RoutesPath.Login);
      toast.success(data.result.message, { toastId: data?.status || "abc" });
    }

    // for fail
    else {
      yield put({
        type: reduxConstant.RESET_PASSWORD_FAIL,
      });
      toast.error(data?.result?.message, { toastId: data?.status || "est" });
    }
  } catch (e) { }
}

// Change password
function* changePasswordSaga(action) {
  const { oldPassword, newPassword } = action;
  try {
    const data = yield call(changePasswordApi, oldPassword, newPassword);
    // for success
    if (data.status === 0) {
      yield call(onSucces, {
        type: reduxConstant.CHANGE_PASSWORD_SUCCESS,
        data,
        show: true,
        navigationLink: appconstant.RoutesPath.Login,
      });
      localStorage.clear();
      yield call(Loader, appconstant.reduxConstant.ON_SUCCESS);
    }
    // for unAuth.
    else if (data.status === 2) {
      onUnAuth(data?.result?.message);
    }
    // for fail
    else {
      yield call(Loader, appconstant.reduxConstant.ON_FAIL);
      yield call(onFail, {
        type: reduxConstant.CHANGE_PASSWORD_FAIL,
        message: data?.result?.message,
        show: true,
      });
    }
  } catch (e) {
    yield call(Loader, appconstant.reduxConstant.ON_FAIL);
  }
}

export default function* rootAuthSaga() {
  yield takeLatest(reduxConstant.LOGIN_LOAD, LoginSaga);
  yield takeLatest(reduxConstant.FORGOTPASSWORD_LOAD, forgotPasswordSaga);
  yield takeLatest(reduxConstant.CHECK_LINK_LOAD, checkLinkSaga);
  yield takeLatest(reduxConstant.RESET_PASSWORD_LOAD, resetPasswordSaga);
  yield takeLatest(reduxConstant.CHANGE_PASSWORD_LOAD, changePasswordSaga);
  // yield takeLatest(reduxConstant.LOGOUT_LOAD, logoutSaga);
}
