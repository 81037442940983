import appconstant from "../../themes/appconstant";
const { reduxConstant } = appconstant;
const instialState = {
  isLoading: false,
  data: [],
  TournamentDetails: {},
  paginateData: {},
  MatchpaginateData: {},
  MatchData: [],
  MatchType: {},
};

const LOAD_FAIL = (state, isLoading = true) => {
  return {
    ...state,
    isLoading,
  };
};

export const TournamentReducer = (state = instialState, action) => {
  switch (action.type) {
    //Tabels data
    case reduxConstant.TOURNAMENT_LOAD:
      return LOAD_FAIL(state);
    case reduxConstant.TOURNAMENT_SUCCESS:
      return {
        ...state,
        isLoading: false,
        data: action.payload.data,
        paginateData: action.payload.pagination,
      };
    case reduxConstant.TOURNAMENT_FAIL:
      return LOAD_FAIL(state, false);

    //BLOCK
    case reduxConstant.TOURNAMENT_BLOCK_LOAD:
      return LOAD_FAIL(state);
    case reduxConstant.TOURNAMENT_BLOCK_SUCCESS:
      return LOAD_FAIL(state, false);
    case reduxConstant.TOURNAMENT_BLOCK_FAIL:
      return LOAD_FAIL(state, false);

    //ADD
    case reduxConstant.TOURNAMENT_ADD_LOAD:
      return LOAD_FAIL(state);
    case reduxConstant.TOURNAMENT_ADD_SUCCESS:
      return LOAD_FAIL(state, false);
    case reduxConstant.TOURNAMENT_ADD_FAIL:
      return LOAD_FAIL(state, false);

    //EDIT
    case reduxConstant.TOURNAMENT_EDIT_LOAD:
      return LOAD_FAIL(state);
    case reduxConstant.TOURNAMENT_EDIT_SUCCESS:
      return LOAD_FAIL(state, false);
    case reduxConstant.TOURNAMENT_EDIT_FAIL:
      return LOAD_FAIL(state);

    //VIEW

    case reduxConstant.TOURNAMENT_VIEW_LOAD:
      return LOAD_FAIL(state, true);
    case reduxConstant.TOURNAMENT_VIEW_SUCCESS:
      return {
        ...state,
        isLoading: false,
        TournamentDetails: action.payload.data,
      };
    case reduxConstant.TOURNAMENT_VIEW_FAIL:
      return LOAD_FAIL(state, false);

    //Matches

    case reduxConstant.TOURNAMENT_MATCH_LOAD:
      return LOAD_FAIL(state);

    case reduxConstant.TOURNAMENT_MATCH_SUCCESS:
      const value = LOAD_FAIL(state, false);
      return {
        ...value,
        MatchData: action.payload,
      };

    case reduxConstant.TOURNAMENT_MATCH_FAIL:
      return LOAD_FAIL(state, false);

    //Match Dropdown load
    case reduxConstant.TOURNAMENT_MATCH_TYPE_LOAD:
      return LOAD_FAIL(state);

    case reduxConstant.TOURNAMENT_MATCH_TYPE_SUCCESS:
      return {
        ...state,
        isLoading: false,
        MatchType: action.payload.data,
      };

    case reduxConstant.TOURNAMENT_MATCH_TYPE_FAIL:
      return LOAD_FAIL(state, false);

    //Reset

    case reduxConstant.TOURNAMENT_RESET:
      return { ...state, paginateData: {} };
    default:
      return state;
  }
};
