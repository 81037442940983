import appconstant from "../../themes/appconstant";
const { reduxConstant } = appconstant;

const instialState = {
  isLoading: false,
  data: [],
  stateDetails: {},
  paginateData: {},
};



export const RestrictedStateReducer = (state = instialState, action) => {
  switch (action.type) {
    case reduxConstant.RESTRICTED_STATE_FAIL:
      return {...state};

    case reduxConstant.RESTRICTED_STATE_LIST_LOAD:
      
      return {...state};

    case reduxConstant.RESTRICTED_STATE_LIST_SUCCESS:
      return {
        ...state,
        isLoading: false,
        data: action.payload.data,
        paginateData: action.payload.pagination,
      };
    case reduxConstant.RESTRICTED_STATE_VIEW_LOAD:
      return {...state};

    case reduxConstant.RESTRICTED_STATE_VIEW_SUCCESS:
      return {
        ...state,
        isLoading: false,
        stateDetails: action.payload.data,
      };
    case reduxConstant.RESET_RESTRICTED_STATE:
      return { ...state, paginateData: {}, stateDetails: {} };
    default:
      return state;
  }
};
