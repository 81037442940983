import appconstant from "../../themes/appconstant";
const { reduxConstant } = appconstant;
export const RestrictedStateAction = (data) => ({
  type: reduxConstant.RESTRICTED_STATE_LIST_LOAD,
  data,
});
export const RestrictedStateAddAction = (data) => ({
  type: reduxConstant.RESTRICTED_STATE_ADD_LOAD,
  data,
});
export const RestrictedStateDeleteAction = (data) => ({
  type: reduxConstant.RESTRICTED_STATE_DELETE_LOAD,
  data,
});
export const RestrictedStateViewAction = (data) => ({
  type: reduxConstant.RESTRICTED_STATE_VIEW_LOAD,
  data,
});
export const RestrictedStateEditAction = (data) => ({
  type: reduxConstant.RESTRICTED_STATE_EDIT_LOAD,
  data,
});
export const ResetRestrictedStateAction = () => ({
  type: reduxConstant.RESET_RESTRICTED_STATE,
});
