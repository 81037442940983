import React, { useEffect, useState } from "react";

//** UI **/

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHome } from "@fortawesome/free-solid-svg-icons";
import { Table } from "antd";

//** STORE **/

import { ResetUserAction, userAction } from "../../Store/Action/userManagement";

//** Themes **/

import appconstant from "../../themes/appconstant";

//** COMMON **/

import { BreadcrumbFn } from "../../common/BreadCrumbFn";
import { userTabel } from "../../common/Tabels";
import {
  instialData,
  UserTabelButtonClick,
  UserTabelButtonName,
} from "../../themes/JsonFile";

import { useSelector } from "react-redux";
import {
  onPagenationHandelChange,
  SearchBarFn,
} from "../../themes/UTILS/commonFunc";
import { dispatch } from "../../themes/UTILS/dispatchNavigate";
import { LoaderAction } from "../../Store/Action/Auth";
import { UserFilter } from "../../common/Filter";
import Button from "../../common/Button";

const UserManagement = () => {
  const [searchInput, setSearchInput] = useState("");
  const [paginationData, setpaginationData] = useState(instialData);
  const { data, paginateData, isLoading } = useSelector(
    (state) => state.UsersReducer
  );
  const [isClick, setIsClick] = useState(false);
  const [FilterClick, SetFilterClick] = useState(false);
  const [FilterValue, setFilterValue] = useState({});
  useEffect(() => {
    !isLoading &&
      window.scroll({
        top: 0,
        behavior: "smooth",
      });
  }, [isLoading]);
  useEffect(() => {
    const newPaginationData = {
      ...paginationData,
      filter: FilterValue?.filter,
    };
    dispatch(LoaderAction());
    SearchBarFn(
      newPaginationData,
      userAction,
      setpaginationData,
      paginateData?.currentPage,
      searchInput
    );
    return () => {
      dispatch(ResetUserAction());
    };
  }, [searchInput]);

  const handleChange = (pagination, filters, sorter) => {
    let newFilterValue = { filter: FilterValue?.filter };
    const newPaginationData = { ...paginationData, ...newFilterValue };
    onPagenationHandelChange(
      pagination,
      sorter,
      newPaginationData,
      setpaginationData,
      userAction
    );
  };

  return (
    <div>
      <BreadcrumbFn
        path={["dashboard"]}
        pathName={[
          <FontAwesomeIcon icon={faHome} />,
          appconstant.userManagement,
        ]}
      />
      <div
        className="site-layout-background"
        style={{ padding: 24, minHeight: 360 }}
      >
        <div className="content-e">
          <div className="bg-gradient-primary shadow-primary border-radius-lg pt-4 pb-3 system-line">
            <h6 className="text-white text-capitalize ps-3">
              {appconstant.userManagement}
            </h6>
            <div style={{ display: "flex" }}>
              <Button onClick={() => SetFilterClick(true)} name={"Filter"} />
              <input
                type="text"
                className="iocn-search  search-bar"
                placeholder="Search"
                value={searchInput}
                onChange={(e) => {
                  setSearchInput(e.target.value.trimStart());
                }}
              />
            </div>
          </div>

          <Table
            rowKey="uid"
            dataSource={data}
            columns={userTabel(
              paginateData?.currentPage,
              UserTabelButtonName,
              UserTabelButtonClick,
              isClick,
              setIsClick,
              paginationData
            )}
            onChange={handleChange}
            showSorterTooltip={false}
            pagination={{
              current: paginateData?.currentPage ?? 1,
              total: paginateData?.totalCount,
            }}
          />
        </div>
      </div>
      {setIsClick && (
        <UserFilter
          Visible={FilterClick}
          onOk={(e) => {
            const obj = {
              ...paginationData,
              search: searchInput,
              filter: { ...e },
            };
            setFilterValue(obj);
            obj.offset = 0;
            dispatch(userAction(obj));
            SetFilterClick(false);
          }}
          onCancel={() => {
            let obj = {
              ...instialData,
              search: searchInput,
              filter: {},
            };
            setFilterValue(obj);
            dispatch(userAction(obj));
            SetFilterClick(false);
          }}
        />
      )}
    </div>
  );
};
export default UserManagement;
