import appconstant from "../../themes/appconstant";
const { reduxConstant } = appconstant;
export const userAction = (data) => ({ type: reduxConstant.USER_LOAD, data });

//Block
export const userBlockAction = (data) => ({
  type: reduxConstant.USER_BLOCK_LOAD,
  data,
});

// passReset
export const userPassResetAction = (data) => ({
  type: reduxConstant.USER_PASS_RESET_LOAD,
  data,
});

//Edit user
export const userEditAction = (data) => {
  return {
    type: reduxConstant.USER_EDIT_LOAD,
    data,
  };
};

//view user
export const userViewDetailAction = (data) => ({
  type: reduxConstant.USER_VIEW_LOAD,
  data,
});
//Reset Data
export const ResetUserAction = (data) => ({
  type: reduxConstant.RESET,
  data,
});
