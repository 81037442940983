import { all, fork } from "redux-saga/effects";
import rootAccountDeleteSaga from "./AccountDelete";
import rootAuthSaga from "./Auth";
import rootDashboardSaga from "./dashboardSaga";
import rootGameManagementSaga from "./GameManagement";
import rootNotificationManagementSaga from "./NotificationSaga";
import rootPaymentSaga from "./paymentSaga";
import rootTournamentManagementSaga from "./TournamentSaga";
import rootUserManagementSaga from "./UserManagement";
import rootRestrictedStateManagementSaga from "./RestrictedStateSaga";
  import rootMaintenanceStateSaga from "./MaintenanceSaga";

export default function* rootSaga() {
  yield all([fork(rootAuthSaga)]);
  yield all([fork(rootDashboardSaga)]);
  yield all([fork(rootUserManagementSaga)]);
  yield all([fork(rootGameManagementSaga)]);
  yield all([fork(rootAccountDeleteSaga)]);
  yield all([fork(rootTournamentManagementSaga)]);
  yield all([fork(rootPaymentSaga)]);
  yield all([fork(rootNotificationManagementSaga)]);
  yield all([fork(rootRestrictedStateManagementSaga)]);
  yield all([fork(rootMaintenanceStateSaga)]);

}