import appconstant from "../../themes/appconstant";
const { reduxConstant } = appconstant;

const instialState = {
  isLoading: false,
  data: [],
  NotificationDetails: {},
  paginateData: {},
};

const LOAD_FAIL = (state, isLoading = true) => {
  return {
    ...state,
    isLoading,
  };
};

export const NotificationReducer = (state = instialState, action) => {
  switch (action.type) {
    case reduxConstant.NOTIFICATION_FAIL:
      return LOAD_FAIL(state);

    case reduxConstant.NOTIFICATION_LIST_LOAD:
      return LOAD_FAIL(state);

    case reduxConstant.NOTIFICATION_LIST_SUCCESS:
      return {
        ...state,
        isLoading: false,
        data: action.payload.data,
        paginateData: action.payload.pagination,
      };
    case reduxConstant.NOTIFICATION_VIEW_LOAD:
      return LOAD_FAIL(state);

    case reduxConstant.NOTIFICATION_VIEW_SUCCESS:
      return {
        ...state,
        isLoading: false,
        NotificationDetails: action.payload.data,
      };
    case reduxConstant.RESET_NOTIFICATION:
      return { ...state, paginateData: {}, NotificationDetails: {} };
    default:
      return state;
  }
};
