import React from "react";
import appconstant from "../../themes/appconstant";
import { useFormik } from "formik";
import * as Yup from "yup";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHome } from "@fortawesome/free-solid-svg-icons";
import Textfiled from "../../common/Textfiled";
import { BreadcrumbFn } from "../../common/BreadCrumbFn";
import { ChangePasswordAction, LoaderAction } from "../../Store/Action/Auth";
import { dispatch } from "../../themes/UTILS/dispatchNavigate";
import Button from "../../common/Button";

const validationSchema = Yup.object().shape({
  oldPassword: Yup.string().required("Please enter old password."),
  newPassword: Yup.string()
    .min(6, "New password should be at least 6 characters long.")
    .required("Please enter new password."),
  confirmPassword: Yup.string()
    .required("Please enter confirm new password.")
    .oneOf(
      [Yup.ref("newPassword"), null],
      "New password and confirm new password should be same."
    ),
});

const Changepassword = () => {
  const initialValues = {
    oldPassword: "",
    newPassword: "",
    confirmPassword: "",
  };

  const onSubmit = (values) => {
    dispatch(LoaderAction());
    dispatch(ChangePasswordAction(values.oldPassword, values.newPassword));
  };

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit,
  });
  return (
    <div>
      <BreadcrumbFn
        path={["dashboard"]}
        pathName={[
          <FontAwesomeIcon icon={faHome} />,
          appconstant.changepassword,
        ]}
      />
      <div
        className="site-layout-background"
        style={{ padding: 24, minHeight: 360 }}
      >
        <div className="content-e">
          <div className="bg-gradient-primary shadow-primary border-radius-lg pt-4 pb-3 system-line">
            <h6 className="text-white text-capitalize ps-3">
              {appconstant.changepassword}
            </h6>
          </div>
          <form onSubmit={formik.handleSubmit} noValidate="noValidate">
            <div className="wrapper_line">
              <Textfiled
                label={appconstant.Oldpassword}
                type="password"
                name="oldPassword"
                onChange={(e) => {
                  let data = e.target.value.trim();
                  formik.setFieldValue("oldPassword", data);
                }}
                value={formik.values.oldPassword.trim()}
                onBlur={formik.handleBlur}
                error={Boolean(
                  formik.errors.oldPassword && formik.touched.oldPassword
                )}
                helperText={
                  formik.touched.oldPassword && formik.errors.oldPassword
                }
                maxLength={300}
              />
              <Textfiled
                label={appconstant.NewPassword}
                type="password"
                name="newPassword"
                onChange={(e) => {
                  let data = e.target.value.trim();
                  formik.setFieldValue("newPassword", data);
                }}
                value={formik.values.newPassword.trim()}
                onBlur={formik.handleBlur}
                error={Boolean(
                  formik.errors.newPassword && formik.touched.newPassword
                )}
                maxLength={300}
                helperText={
                  formik.touched.newPassword && formik.errors.newPassword
                }
              />
              <Textfiled
                label={appconstant.ConfirmNewPassword}
                type="password"
                name="confirmPassword"
                onChange={(e) => {
                  let data = e.target.value.trim();
                  formik.setFieldValue("confirmPassword", data);
                }}
                value={formik.values.confirmPassword.trim()}
                onBlur={formik.handleBlur}
                error={Boolean(
                  formik.errors.confirmPassword &&
                    formik.touched.confirmPassword
                )}
                helperText={
                  formik.touched.confirmPassword &&
                  formik.errors.confirmPassword
                }
                maxLength={300}
              />
              <div className="up-cent">
                <Button name={appconstant.buttonupate} />

              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};
export default Changepassword;
