import React, { useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleLeft } from "@fortawesome/free-solid-svg-icons";
import { useFormik } from "formik";
import * as Yup from "yup";
import { ForgotpasswordAction } from "../../Store/Action/Auth";
import images from "../../themes/appImage";
import Button from "../../common/Button";
import { dispatch, navigate } from "../../themes/UTILS/dispatchNavigate";

const validationSchema = Yup.object().shape({
  email: Yup.string()
    .matches(/^(\S+$)/, "Space is not allowed.")
    .email("Please enter valid email address.")
    .required("Please enter email address."),
});

const ForgotPassword = () => {

  useEffect(() => {
    document.title = "CHESS ARENA";
    window.scrollTo(0, 0);
  }, []);

  const initialValues = {
    email: "",
  };

  const onSubmit = (values) => {
    dispatch(ForgotpasswordAction(values.email, navigate));
  };

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit,
  });

  return (
    <div className="image-bg">
      <div className="page-header">
        <div className="main-div">
          <div className="login-form mt-top">
            <div className="back">
              <FontAwesomeIcon
                className="icon-angle-left  btn2"
                icon={faAngleLeft}
                onClick={() => navigate("/login")}
              />
            </div>
            <div>
              <img src={images.login1} alt="" />
            </div>
            <h4>Forgot Password </h4>
            <form
              className="user"
              onSubmit={formik.handleSubmit}
              noValidate="noValidate"
            >
              <div className="user">
                <input
                  id="email"
                  type="email"
                  name="email"
                  onChange={formik.handleChange}
                  value={formik.values.email}
                  placeholder="Email Address"
                />
                {formik.errors.email ? (
                  <div className="error">{formik.errors.email}</div>
                ) : null}
              </div>
              <div>
                <Button className="login-link" name="Send"/>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};
export default ForgotPassword;
