import React from "react";
import appconstant from "../themes/appconstant";

//**  This is Editabel field function **/

const Textfiled = (props) => {
  const {
    type = "text",
    className,
    disabled,
    defaultValue,
    label,
    id,
    name = "name",
    value,
    onChange,
    onKeyDown,
    error,
    helperText,
    maxLength = 40,
    readonly,
    onBlur,
    onInput,
    min = 0,
    onkeypress
  } = props;
  return (
    <div>
      <div className="form-group">
        <label>{label}</label>
        <div className="input_fields">
          <input
            type={type}
            placeholder={label && appconstant.Text + label.toLowerCase()}
            defaultValue={defaultValue}
            className={`form-control ${className ?? ""}`}
            disabled={disabled}
            id={id}
            name={name}
            value={value}
            onChange={onChange}
            onKeyDown={onKeyDown}
            maxLength={maxLength}
            onInput={onInput}
            readOnly={readonly}
            onBlur={onBlur}
            min={min}
            autoComplete="off"
            onWheel={(e) => e.target.blur()}
            autoCapitalize
          />
        </div>
      </div>
      {error ? (
        <span
          style={{
            color: "red",
            paddingLeft: "5px",
            position: "relative",
            top: "-9px",
          }}
        >
          {helperText}
        </span>
      ) : (
        ""
      )}
    </div>
  );
};

export default Textfiled;
