import { call, takeLatest } from "@redux-saga/core/effects";

import { GameDetailApi, GamesApi } from "../Api";

import appconstant from "../../themes/appconstant";
import {
  onFail,
  onError,
  onUnAuth,
  onSucces,
  Loader,
} from "../../themes/UTILS/UnAuthFailFn";

const { reduxConstant } = appconstant;

//** Games detail **/
function* GamesSaga(action) {
  try {
    const data = yield call(GamesApi, action.data);
    //** Success **/
    if (data.status === 0) {
      const obj = {
        type: reduxConstant.GAME_SUCCESS,
        data,
        show:false
      };
      yield call(onSucces, obj);
      yield call(Loader, appconstant.reduxConstant.ON_SUCCESS);
    }
    //** Un Authenticate **/
    else if (data.status === 2) {
      onUnAuth(data?.result?.message);
    }
    //** Fail **/
    else {
      let obj = {
        type: reduxConstant.GAME_FAIL,
        message: data?.result?.message,
      };

      yield call(onFail, obj);
      yield call(Loader, appconstant.reduxConstant.ON_SUCCESS);
    }
  } catch (e) {
    //** Un Catch error **/
    yield call(onError, reduxConstant?.GAME_FAIL);
    yield call(Loader, appconstant.reduxConstant.ON_FAIL);
  }
}
function* GameDetailSaga(action) {
  try {
    const data = yield call(GameDetailApi, action.data);
    //** Success **/
    if (data.status === 0) {
      const obj = {
        type: reduxConstant.GAME_VIEW_SUCCESS,
        data,
        show:false
      };
      yield call(onSucces, obj);
      yield call(Loader, appconstant.reduxConstant.ON_SUCCESS);
    }
    //** Un Authenticate **/
    else if (data.status === 2) {
      onUnAuth(data?.result?.message);
    }
    //** Fail **/
    else {
      const obj = {
        type: reduxConstant.GAME_VIEW_FAIL,
        message: data?.result?.message,
      };
      yield call(onFail, obj);
      yield call(Loader, appconstant.reduxConstant.ON_FAIL);
    }
  } catch (e) {
    //** Un Catch error **/
    yield call(Loader, appconstant.reduxConstant.ON_FAIL);
    yield call(onError, reduxConstant?.GAME_VIEW_FAIL);
  }
}
export default function* rootGameManagementSaga() {
  yield takeLatest(reduxConstant.GAME_LOAD, GamesSaga);
  yield takeLatest(reduxConstant.GAME_VIEW_LOAD, GameDetailSaga);
}
