import appconstant from "../../themes/appconstant";
const { reduxConstant } = appconstant;
const instialState = {
    isLoading: false,
    message: "",
    isSuccess: false,
    lastMonth: 0,
    lastSixMonth: 0,
    lastThreeMonth: 0,
    lastYear: 0,
    thisWeek: 0,
    totalAdminAmount1Month: 0,
    totalAdminAmount7Days: 0,
    totalAdminAmount24Hrs: 0,
    totalAdminAmountAllTime: 0,
    totalWinAmount1Month: 0,
    totalWinAmount7Days: 0,
    totalWinAmount24Hrs: 0,
    totalWinAmountAllTime: 0,
    last24HoursOnlineUsers: 0,
    onlineUsers: 0,
    onlineGames:0,
};
export const dashboardReducer = (state = instialState, action) => {
    switch (action.type) {
        //login
        case reduxConstant.DASHBOARD_LOAD:
            return {
                ...state,
                isLoading: true,
            };

        case reduxConstant.DASHBOARD_SUCCESS:
            return {
                ...state,
                isLoading: false,
                lastMonth: action.payload.data.lastMonth,
                lastSixMonth: action.payload.data.lastSixMonth,
                lastThreeMonth: action.payload.data.lastThreeMonth,
                lastYear: action.payload.data.lastYear,
                thisWeek: action.payload.data.thisWeek,
                totalAdminAmount1Month: action.payload.data.totalAdminAmount1Month,
                totalAdminAmount7Days: action.payload.data.totalAdminAmount7Days,
                totalAdminAmount24Hrs: action.payload.data.totalAdminAmount24Hrs,
                totalAdminAmountAllTime: action.payload.data.totalAdminAmountAllTime,
                totalWinAmount1Month: action.payload.data.totalWinAmount1Month,
                totalWinAmount7Days: action.payload.data.totalWinAmount7Days,
                totalWinAmount24Hrs: action.payload.data.totalWinAmount24Hrs,
                totalWinAmountAllTime: action.payload.data.totalWinAmountAllTime,
                last24HoursOnlineUsers: action.payload.data.last24HoursOnlineUsers,
                onlineUsers: action.payload.data.onlineUsers,
                onlineGames: action.payload.data.onlineGames
                
            };
        case reduxConstant.DASHBOARD_FAIL:
            return {
                ...state,
                isLoading: false,
            };
        case reduxConstant.UPDATE_ONLINE_USER:
            state[action.key] = action.payload
            return { ...state, }


            case reduxConstant.UPDATE_ONLINE_GAME:
                state[action.key] = action.payload
                return { ...state, }
        default:
            return state;
    }
};
