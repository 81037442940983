import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHome } from "@fortawesome/free-solid-svg-icons";
import { Table } from "antd";

import appconstant from "../../themes/appconstant";
import { BreadcrumbFn } from "../../common/BreadCrumbFn";
import { dispatch, navigate } from "../../themes/UTILS/dispatchNavigate";
import { LoaderAction } from "../../Store/Action/Auth";
import { RestrictedStateTable } from "../../common/Tabels";
import { useSelector } from "react-redux";
import {
  onPagenationHandelChange,
  SearchBarFn,
} from "../../themes/UTILS/commonFunc";
import {
  instialData,
  NotificationButtonClick,
  NotificationButtonName,
  RestrictedStateButtonClick,
  RestrictedStateButtonName,
} from "../../themes/JsonFile";
import {
  RestrictedStateAction,
  ResetRestrictedStateAction,
} from "../../Store/Action/RestrictedStateAction";
import Button from "../../common/Button";

const RestrictedStateManagement = () => {
  const [searchInput, setSearchInput] = useState("");
  const [paginationData, setpaginationData] = useState(instialData);
  const { data, paginateData, isLoading } = useSelector(
    (state) => state.RestrictedStateReducer
  );
  const [isClick, setIsClick] = useState(false);

  useEffect(() => {
    !isLoading &&
      window.scroll({
        top: 0,
        behavior: "smooth",
      });
  }, [isLoading]);

  useEffect(() => {
    dispatch(LoaderAction());
    SearchBarFn(
      paginationData,
      RestrictedStateAction,
      setpaginationData,
      paginateData?.currentPage,
      searchInput
    );
    return () => {
      dispatch(ResetRestrictedStateAction());
    };
  }, [searchInput]);

  const handleChange = (pagination, filters, sorter) => {
    onPagenationHandelChange(
      pagination,
      sorter,
      paginationData,
      setpaginationData,
      RestrictedStateAction
    );
  };
  return (
    <div>
      <BreadcrumbFn
        path={["dashboard"]}
        pathName={[
          <FontAwesomeIcon icon={faHome} />,
          appconstant.restrictedStateManagement,
        ]}
      />
      <div
        className="site-layout-background"
        style={{ padding: 24, minHeight: 360 }}
      >
        <div className="content-e">
          <div className="bg-gradient-primary shadow-primary border-radius-lg pt-4 pb-3 system-line">
            <h6 className="text-white text-capitalize ps-3">
              {appconstant.restrictedStateManagement}
            </h6>
            <input
              type="text"
              className="iocn-search  search-bar"
              placeholder="Search"
              value={searchInput}
              onChange={(e) => {
                setSearchInput(e.target.value.trimStart());
              }}
            />
          </div>
          <div>
            <Button
              name={appconstant.addState}
              onClick={() =>
                navigate(appconstant.RoutesPath.addRestrictedState, {
                  state: {
                    type: appconstant.addRestrictedState,
                  },
                })
              }
            />
          </div>
          <Table
            rowKey="uid"
            dataSource={data}
            columns={RestrictedStateTable(
              paginateData?.currentPage,
              RestrictedStateButtonName,
              RestrictedStateButtonClick,
              isClick,
              setIsClick,
              paginationData,
              paginateData?.totalCount
            )}
            onChange={handleChange}
            showSorterTooltip={false}
            pagination={{
              current: paginateData?.currentPage ?? 1,
              total: paginateData?.totalCount,
            }}
          />
        </div>
      </div>
    </div>
  );
};
export default RestrictedStateManagement;
