import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHome } from "@fortawesome/free-solid-svg-icons";

import appconstant from "../../themes/appconstant";

import Textfiled from "../../common/Textfiled";
import { BreadcrumbFn } from "../../common/BreadCrumbFn";
import { dispatch, navigate } from "../../themes/UTILS/dispatchNavigate";
import { LoaderAction } from "../../Store/Action/Auth";
import { Formik } from "formik";
import {
  ToUpperCase,
  validationEditSchema,
} from "../../themes/UTILS/commonFunc";
import { useLocation } from "react-router-dom";
import {
  NotificationAddAction,
  NotificationEditAction,
  NotificationViewAction,
} from "../../Store/Action/NotificationAction";
import { useSelector } from "react-redux";
import Button from "../../common/Button";
const AddNotification = () => {
  const { state } = useLocation();

  const { NotificationDetails } = useSelector(
    (state) => state.NotificationReducer
  );

  const [NotificationData, setNotificationData] = useState({
    title: "",
    description: "",
  });

  const InstialDispatch = () => {
    dispatch(LoaderAction());
    dispatch(NotificationViewAction(state?.id));
  };
  useEffect(() => {
    state?.type === appconstant.editNotification &&
      setNotificationData(NotificationDetails);
  }, [NotificationDetails]);

  useEffect(() => {
    !Boolean(state?.type) && navigate(appconstant.notifcationManagement);
    state?.type === appconstant.editNotification && InstialDispatch();
  }, []);

  const formSubmit = (e) => {
    if (state.type === appconstant.editNotification) {
      e.notificationId = e._id;
      delete e._id;
      dispatch(NotificationEditAction(e));
    } else {
      dispatch(NotificationAddAction(e));
    }
    dispatch(LoaderAction());
  };
  return (
    <div>
      <BreadcrumbFn
        path={["dashboard", "notificationmanagement"]}
        pathName={[
          <FontAwesomeIcon icon={faHome} />,
          appconstant.notifcationManagement,
          state?.type,
        ]}
      />
      <div
        className="site-layout-background"
        style={{ padding: 24, minHeight: 360 }}
      >
        <div className="content-e butoo-pa">
          <div className="bg-gradient-primary shadow-primary border-radius-lg pt-4 pb-3">
            <h6 className="text-white text-capitalize ps-3">{state?.type}</h6>
          </div>
          <Formik
            enableReinitialize
            initialValues={NotificationData}
            onSubmit={formSubmit}
            validationSchema={validationEditSchema}
          >
            {({
              handleBlur,
              handleSubmit,
              values,
              errors,
              touched,
              setFieldValue,
            }) => (
              <form onSubmit={handleSubmit}>
                <div className="key">
                  <Textfiled
                    label={appconstant.tittle}
                    name={"title"}
                    onChange={(e) => {
                      let data = e.target.value.trimStart();
                      setFieldValue("title", ToUpperCase(data));
                    }}
                    value={values?.title}
                    onBlur={handleBlur}
                    error={Boolean(errors.title && touched.title)}
                    helperText={touched.title && errors.title}
                  />
                </div>

                <div className="about-us">
                  <div className="form-group">
                    <label>{appconstant.description}</label>
                    <textarea
                      name="description"
                      rows="4"
                      cols="50"
                      placeholder="Please enter description"
                      onChange={(e) => {
                        let date = e.target.value.trimStart();
                        setFieldValue("description", ToUpperCase(date));
                      }}
                      value={values?.description}
                      onBlur={handleBlur}
                      maxLength={200}
                    ></textarea>
                    {Boolean(errors.description && touched.description) && (
                      <span style={{ color: "red" }}>{errors.description}</span>
                    )}
                  </div>
                </div>
                <div className="up-btn" style={{ paddingBottom: "30px" }}>
                  <Button
                    name={
                      state?.type === appconstant.editNotification
                        ? appconstant.update
                        : appconstant.add
                    }
                  />
                </div>
              </form>
            )}
          </Formik>
        </div>
      </div>
    </div>
  );
};
export default AddNotification;
