import appconstant from "../../themes/appconstant";
const { reduxConstant } = appconstant;

export const gameAction = (data) => {
  return ({ type: reduxConstant.GAME_LOAD, data })};

export const GameViewDetailAction = (data) => ({
  type: reduxConstant.GAME_VIEW_LOAD,
  data,
});
// Reset Data
export const ResetGamesAction = (data) => ({
  type: reduxConstant.RESET_GAMES,
  data,
});
