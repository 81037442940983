import { call, takeLatest,put } from "@redux-saga/core/effects";
import appconstant from "../../themes/appconstant";
import { DepositApi, acceptRejectApi } from "../Api";
import {
  onFail,
  onError,
  onUnAuth,
  onSucces,
  Loader,
} from "../../themes/UTILS/UnAuthFailFn";
const { reduxConstant } = appconstant;

function* AccountSaga(action) {
  try {
    const data = yield call(DepositApi, action.data);
    //** Success **/
    if (data.status === 0) {
      const obj = {
        type: reduxConstant.PAYMENT_INFO_SUCCESS,
        data,
        show: false,
      };
      yield call(onSucces, obj);
      yield call(Loader, appconstant.reduxConstant.ON_SUCCESS);
    }
    //** Un Authenticate **/
    else if (data.status === 2) {
      onUnAuth(data?.result?.message);
    }
    //** Fail **/
    else {
      let obj = {
        type: reduxConstant.PAYMENT_INFO_FAIL,
        message: data?.result?.message,
      };
      yield call(onFail, obj);
      yield call(Loader, appconstant.reduxConstant.ON_FAIL);
    }
  } catch (e) {
    //** Un Catch error **/
    yield call(onError, reduxConstant?.PAYMENT_INFO_FAIL);
    yield call(Loader, appconstant.reduxConstant.ON_FAIL);
  }
}

function* RequestSaga(action) {
  try {
    console.log(action);
    const data = yield call(acceptRejectApi, action.data);
    //** Success **/
    if (data.status === 0) {
      const obj = {
        type: reduxConstant.Payment_Request_Success,
        data,
        show: true,
      };
      yield call(onSucces, obj);
      yield put({
        type: reduxConstant.PAYMENT_INFO_LOAD,
        data: action.data.paginationData,
      });
      yield call(Loader, appconstant.reduxConstant.ON_SUCCESS);


    }
    //** Un Authenticate **/
    else if (data.status === 2) {
      onUnAuth(data?.result?.message);
    }
    //** Fail **/
    else {
      let obj = {
        type: reduxConstant.Payment_Request_Fail,
        message: data?.result?.message,
      };
      yield call(onFail, obj);
      yield call(Loader, appconstant.reduxConstant.ON_FAIL);
    }
  }
   catch (e) {
    //** Un Catch error **/
    yield call(onError, reduxConstant?.Payment_Request_Fail);
    yield call(Loader, appconstant.reduxConstant.ON_FAIL);
  }
}
export default function* rootPaymentSaga() {
  yield takeLatest(reduxConstant.PAYMENT_INFO_LOAD, AccountSaga);
  yield takeLatest(reduxConstant.Payment_Request_Load, RequestSaga);

}
