import React, { useState } from "react";
import { Layout, Spin } from "antd";
import { Outlet } from "react-router";

import HeaderAdmin from "../layout/header";
import Sidebar from "../layout/sidebar";
import { PrivateRoute } from "../Route/privateRoute";

import { useSelector } from "react-redux";

const { Content } = Layout;

const MainLayout = () => {
  const [isVisible, setVisible] = useState(false);

  const handlewClick = () => {
    setVisible(!isVisible);
  };
  const { MainLoader } = useSelector((state) => state.UsersReducer);
  return (
    <Spin
      spinning={MainLoader}
      delay={500}
      size={"large"}
    >
      <Layout>
        <Sidebar isVisible={isVisible} handlewClick={handlewClick} />
        <Layout className={isVisible ? "new_layout data" : "new_layout"}>
          <HeaderAdmin handlewClick={handlewClick} />
          <Content style={{ margin: "24px 16px 0" }}>
            <PrivateRoute>
              <Outlet />
            </PrivateRoute>
          </Content>
        </Layout>
      </Layout>
    </Spin>
  );
};
export default MainLayout;
