import React, { useEffect } from "react";
import images from "../../themes/appImage";
import * as Yup from "yup";
import { useFormik } from "formik";
import { useLocation } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { checkLinkAction, ResetPasswordAction } from "../../Store/Action/Auth";
import appconstant from "../../themes/appconstant";
import { navigate } from "../../themes/UTILS/dispatchNavigate";
import Button from "../../common/Button";

const validationSchema = Yup.object().shape({
  password: Yup.string()
    .min(6, "Password should be at least 6 characters long.")
    .required("Please enter password."),
  confirmPassword: Yup.string()
    .required("Please enter confirm password.")
    .oneOf(
      [Yup.ref("password"), null],
      "Confirm password should match with password."
    ),
});

const ResetPassword = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  const query = new URLSearchParams(location.search);
  const userId = query.get("userId");
  const authState = useSelector((state) => state.AuthReducer);
  const { linkValid } = authState;

  useEffect(() => {
    window.scrollTo(0, 0);
    dispatch(checkLinkAction(userId));
  }, []);
  const initialValues = {
    password: "",
    confirmPassword: "",
  };

  const onSubmit = (values) => {
    dispatch(ResetPasswordAction(values.confirmPassword, userId));
    window.history.replaceState("", "", appconstant.RoutesPath.Login);
  };

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit,
  });

  const handleKeyDown = (e) => {
    if (e.key === " ") {
      e.preventDefault();
    }
  };

  const GoBack = () => {
    window.history.replaceState("", "", appconstant.RoutesPath.Login);
    navigate(appconstant.RoutesPath.Login);
  };

  return (
    <div className="image-bg">
      <div className="page-header">
        <div className="main-div reset_password">
          {linkValid === null ? null : linkValid === true ? (
            <div className="login-form">
              <div>
                <img src={images.login1} alt="" />
              </div>
              <h4>Reset Password </h4>
              <form
                className="user"
                onSubmit={formik.handleSubmit}
              >
                <div className="user">
                  <input
                    id={"password"}
                    type="password"
                    name="password"
                    placeholder="Password"
                    onChange={formik.handleChange}
                    onKeyDown={handleKeyDown}
                    value={formik.values.password.trim()}
                  ></input>
                  {formik.errors.password ? (
                    <div className="error">{formik.errors.password}</div>
                  ) : null}
                </div>
                <div className="user">
                  <input
                    id={"confirmPassword"}
                    type="password"
                    onKeyDown={handleKeyDown}
                    name="confirmPassword"
                    placeholder="Confirm Password"
                    onChange={formik.handleChange}
                    value={formik.values.confirmPassword.trim()}
                  ></input>
                  {formik.errors.confirmPassword ? (
                    <div className="error">{formik.errors.confirmPassword}</div>
                  ) : null}
                </div>
                <Button className="login-link" name="Reset" />
              </form>
            </div>
          ) : (
            <div
              className="login-form"
              style={{ height: "300px", padding: "25px", maxHeight: "300px" }}
            >
              <div>
                {" "}
                <img src={images.login1} alt="" />
              </div>
              <h4>
                Your reset password link has been expired.{" "}
                <span className="BackToLogin" onClick={GoBack}>
                  Click to login
                </span>
              </h4>

              <h5 style={{ fontSize: "13px" }}>
                Copyright 2022 © Chess Arena. All rights reserved.
              </h5>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default ResetPassword;
